// TODO fix
/* eslint-disable @typescript-eslint/no-misused-promises */
import { Card, TERRA_GREEN, IGNIS_RED } from "@120wateraudit/waterworks";
import React, { useCallback, useMemo } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import styled from "styled-components";
import { ValidationErrors } from "final-form";
import { Form as FinalForm, Field } from "react-final-form";
import pluralize from "pluralize";

import { useInitialValues, Values } from "./hooks";
import Dropdown from "src/components/Form/Dropdown";
import {
  getOptionsForCurrentStatus,
  isTerminalStatus,
} from "./StatusChangeDropdown";
import { toastError } from "src/utils/toast";
import { isApiError } from "src/utils/apiError";
import Submission, { SubmissionStatus } from "src/types/Submission";
import { PwsSubmissionPeriod, useUpdateSubmissionStatusesMutation } from "src/services/state-submission";
import Error from "src/components/Error";
import { titleCase } from "src/utils/format";

export const validate = (
  values: Values | Record<string, any>
): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (!values.status) {
    errors.status = "Required";
  }

  return errors;
};

interface Props {
  closeModal: () => void;
  selected?: PwsSubmissionPeriod[] | Submission[];
  submissionId?: number;
}

const ChangeConsumerNoticeModal = ({
  closeModal,
  selected,
  submissionId,
}: Props): JSX.Element => {
  const required: (value: any) => undefined | "Required" = (value: any) =>
    value ? undefined : "Required";

  const submissionText = submissionId
    ? "this Submission"
    : pluralize("Submission", selected?.length, true);

  const [updateSubmissionStatuses, { data, error, isError, isLoading }] =
    useUpdateSubmissionStatusesMutation();
  const onSubmit = useCallback(
    async (values: Values) => {
      const { status } = values;
      const activeSubmissions: Submission[] | undefined = selected?.map((s) => {
        if (s.activeSubmission) {
          return { ...s.activeSubmission } as unknown as Submission;
        }

        return { ...s } as unknown as Submission;
      });
      const submissionsToUpdate = submissionId
        ? [{ id: submissionId, status }]
        : activeSubmissions?.map((s) => ({ id: s.id, status }));

      try {
        submissionsToUpdate &&
          (await updateSubmissionStatuses(submissionsToUpdate).unwrap());
      } catch {
        toastError("An error occurred, please try again.");
      }
    },
    [updateSubmissionStatuses]
  );

  const initialValues = useInitialValues();
  const statusOptions = getOptionsForCurrentStatus();
  const { data: responseData } = data ?? {};
  const { updatedSubmissions, submissionsNotUpdated } = responseData ?? {};
  const submissionsUpdatedCount = updatedSubmissions?.length ?? 0;
  const updatedCountText = pluralize(
    "Submission",
    submissionsUpdatedCount,
    true
  );

  const errors = useMemo(() => {
    if (!error || !isError) {
      return undefined;
    }

    if (isApiError(error)) {
      return [error.data?.error];
    }

    return ["Please try again or contact support@120water.com"];
  }, [error, isError]);

  return (
    <ChangeStatusContainer>
      <FinalForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, values }) => (
          <Form
            onSubmit={() => {
              void handleSubmit();
            }}
          >
            <>
              <h3>
                {`Change ${submissionId ? "Submission" : "Submissions"} Status`}
              </h3>
              <Modal.Content style={{ margin: "24px 0px" }}>
                <Modal.Description>
                  {!error && (
                    <>
                      <Field<string>
                        wrapperStyling={{ margin: "24px 0px" }}
                        component={Dropdown}
                        label="Status Choice"
                        name="status"
                        options={statusOptions}
                        validate={required}
                        disabled={!!data ?? submitting}
                      />
                      {!data && (
                        <P>
                          {`Are you sure you would like to change the status of
                        ${submissionText}?`}
                          {isTerminalStatus(values.status) &&
                            ` Once you change the status to ${titleCase(
                              SubmissionStatus[values.status]
                            )} the status cannot be changed again.`}
                        </P>
                      )}
                    </>
                  )}
                  {responseData && (
                    <>
                      {updatedSubmissions && (
                        <SuccessCard>
                          {submissionsUpdatedCount === 0
                            ? "No Submission statuses were updated."
                            : `Successfully changed ${updatedCountText} Status`}
                        </SuccessCard>
                      )}
                      {submissionsNotUpdated &&
                        submissionsNotUpdated.length > 0 && (
                          <FailureCard>
                            {submissionsNotUpdated.map(
                              (text: string, index: number) => (
                                <p key={`submission_not_updated${index}`}>
                                  {text}
                                </p>
                              )
                            )}
                          </FailureCard>
                        )}
                    </>
                  )}
                  {errors && (
                    <Error style={{ margin: "12px 24px" }} messages={errors} />
                  )}
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Modal.Actions>
                  <Button onClick={closeModal}>Cancel</Button>
                  <Button
                    disabled={!!data ?? submitting ?? isLoading}
                    loading={submitting ?? isLoading}
                    primary
                    type="submit"
                  >
                    Confirm
                  </Button>
                </Modal.Actions>
              </Modal.Actions>
            </>
          </Form>
        )}
        validate={(values) => validate(values)}
      />
    </ChangeStatusContainer>
  );
};

const P = styled.p`
  font-size: 16px;
`;

const ChangeStatusContainer = styled.div`
  margin: 2rem;
`;

const SuccessCard = styled(Card)`
  background-color: ${TERRA_GREEN};
  margin-bottom: 10px;
`;

const FailureCard = styled(Card)`
  background-color: ${IGNIS_RED};
`;

export default ChangeConsumerNoticeModal;
