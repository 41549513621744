import React, {
  PropsWithChildren,
  useState,
  useRef,
  useEffect,
} from "react";
import { Card, DARK_GRAY_BLUE } from "@120wateraudit/waterworks";

export const SubmissionPeriodMetricCard: React.FC<
  PropsWithChildren<{
    progress: number;
    title?: string | React.ReactNode;
    radius?: number;
    barWidth?: number;
    padding?: number;
    color?: string;
    style?: React.CSSProperties;
  }>
> = ({
  progress,
  children,
  style,
  title = "Submission Metric",
  radius = 40,
  barWidth = 10,
  padding = 5,
  color = DARK_GRAY_BLUE,
}) => {
  const [offset, setOffset] = useState(0);
  const [dimensions, setDimensions] = useState({
    height: radius * 2,
    width: radius * 2,
  });
  const mapRef = useRef<SVGElement | null>(null);

  useEffect(() => {
    const circumference = 2 * Math.PI * radius; // Radius of the circle is 40
    const offset = circumference - (progress / 100) * circumference;
    setOffset(offset);
  }, [progress]);

  useEffect(() => {
    setDimensions({
      height: radius * 2 + padding * 2,
      width: radius * 2 + padding * 2,
    });
  }, [radius]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.style.transform = "scale(10)";
    }
  }, [mapRef.current]);

  return (
    <Card
      style={{
        display: "flex",
        flex: 1,
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
        ...style
      }}
    >
      <h6
        style={{
          fontSize: 16,
          fontWeight: 600,
          margin: 0,
        }}
      >
        {title}
      </h6>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          width: "100%",
        }}
      >
        {children}
        <div></div>
        <svg
          viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
          width={dimensions.width}
          height={dimensions.height}
        >
          <circle
            className="progress-bar"
            style={{
              fill: "none",
              stroke: "#e0e0e0",
              strokeWidth: barWidth,
            }}
            cx={radius + padding}
            cy={radius + padding}
            r={radius}
          ></circle>
          <circle
            className="progress"
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: barWidth,
              strokeDasharray: 2 * Math.PI * radius,
              strokeDashoffset: offset,
              transform: "rotate(-90deg)",
              transformOrigin: "center",
              transition: "stroke-dashoffset 0.5s ease",
            }}
            cx={radius + padding}
            cy={radius + padding}
            r={radius}
          ></circle>
        </svg>
      </div>
    </Card>
  );
};
