import React from "react";
import styled from "styled-components";
import { Divider } from "semantic-ui-react";

import {
  DoubleInputGrid,
  GeneralContainer,
  FlexDiv,
  Value,
  QuestionTitle,
} from "../../shared";

interface Props {
  additionalInfo: { [key: string]: any };
}

const EpaMethodsPart2: React.FC<Props> = ({ additionalInfo }) => {
  const {
    inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices,
    inventoryMethodsPart2Q1ServiceLineMaterialIdentificationOtherExplanation,
    inventoryMethodsPart2Q2StandardProceduresYesNo,
    inventoryMethodsPart2Q2StandardProceduresOtherExplanation,
  } = additionalInfo;

  const hasWaterMeterReading =
    inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices?.includes(
      "Water meter reading"
    );
  const hasWaterMeterRepairOrReplacement =
    inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices?.includes(
      "Water meter repair or replacement"
    );
  const hasServiceLineRepairOrReplacement =
    inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices?.includes(
      "Service line repair or replacement"
    );
  const hasWaterMainRepairOrReplacement =
    inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices?.includes(
      "Water main repair or replacement"
    );
  const hasBackflowPreventionDevice =
    inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices?.includes(
      "Backflow prevention device inspection"
    );
  const hasOther =
    inventoryMethodsPart2Q1ServiceLineMaterialIdentificationChoices?.includes(
      "Other"
    );

  return (
    <GeneralContainer>
      <div>
        <p style={{ marginBottom: "0px" }}>
          During which normal operating activities are you collecting
          information on service line material? Check all that apply.
        </p>
        <DoubleInputGrid>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasWaterMeterReading} />
            <label>Water meter reading</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasWaterMeterRepairOrReplacement} />
            <label>Water meter repair or replacement</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input
              type="checkbox"
              checked={hasServiceLineRepairOrReplacement}
            />
            <label>Service line repair or replacement</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasWaterMainRepairOrReplacement} />
            <label>Water main repair or replacement</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasBackflowPreventionDevice} />
            <label>Backflow prevention device inspection</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasOther} />
            <label>Other</label>
          </StyledFlexDiv>
        </DoubleInputGrid>
      </div>
      <Divider />
      <div>
        <QuestionTitle>If other please explain</QuestionTitle>
        <Value>
          {inventoryMethodsPart2Q1ServiceLineMaterialIdentificationOtherExplanation ??
            "--"}
        </Value>
      </div>
      <Divider />
      <div>
        <QuestionTitle>
          Did you develop or revise standard operating procedures to collect
          service line material information during normal operation?
        </QuestionTitle>
        <Value>{inventoryMethodsPart2Q2StandardProceduresYesNo ?? "--"}</Value>
      </div>
      <div>
        <QuestionTitle>If Yes, please describe</QuestionTitle>
        <Value>
          {inventoryMethodsPart2Q2StandardProceduresOtherExplanation ?? "--"}
        </Value>
      </div>
      <Divider />
    </GeneralContainer>
  );
};

const StyledFlexDiv = styled(FlexDiv)`
  pointer-events: none;
`;

export default EpaMethodsPart2;
