import PublishToPtdModal from "./PublishToPtdModal";
import UnpublishFromPtdModal from "./UnpublishFromPtdModal";

export { DisplaySettingsFlagged } from "./DisplaySettings";
export { PublishSettingsFlagged } from "./PublishSettings";

export const MODALS = {
  publishToPtdModal: PublishToPtdModal,
  unpublishPtdModal: UnpublishFromPtdModal,
};
