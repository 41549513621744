import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome'
import React from 'react'

const Loader: React.FC<Omit<FontAwesomeIconProps, 'icon' | 'spin'>> = (
  props
) => {
  return <FontAwesomeIcon icon={solid('circle-notch')} spin {...props} />
}

export default Loader
