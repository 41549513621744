import { createApi } from '@reduxjs/toolkit/query/react'

import { Tag } from '.'
import baseQuery from './baseQuery'

interface GeoserverFeatureResponse {
  features: GeoserverFeature[]
}

interface GeoserverFeature {
    id: string
    properties: InventoryProperties
}

export interface InventoryProperties {
    submission_service_line_id: number
    primacy_code: string
    account_id: number
    account_name: string
    pws_account_id: number
    pws_account_name: string
    submission_id: number
    submission_published: boolean
    submission_period_id: number
    submission_period_name: string
    external_location_id: string
    address_line_1: string
    address_line_2: string
    city: string
    state: string
    zip: string
    location_notes: string
    year_built: number
    sensitive_population: string
    disadvantage_neighboorhood: boolean
    property_classification: string
    lcrr_tier: string
    current_lcr_sampling_site: string
    building_plumbing_lead_solder: string
    poe_or_pou_treatment: string
    asset_id: number
    external_asset_id: string
    asset_description: string
    service_line_status: string
    public_material: string
    public_lead_solder: string
    public_classification_basis: string
    public_line_diameter: string
    public_line_depth: string
    public_verified: boolean
    public_verified_by: string
    public_verified_date: any
    public_verification_method: string
    public_installation_date: string
    public_removal_date: any
    public_notes: string
    private_material: string
    private_lead_solder: string
    private_classification_basis: string
    private_line_diameter: string
    private_line_depth: string
    private_verified: boolean
    private_verified_by: string
    private_verified_date: any
    private_verification_method: string
    private_installation_date: string
    private_removal_date: any
    private_notes: string
    lead_fittings: string
    fittings_verified_by: string
    fittings_verified_date: any
    fittings_verification_method: string
    other_lead_equipment: string
    material_classification: string
    public_material_other: string
    public_verification_method_other: string
    private_material_other: string
    private_verification_method_other: string
    fittings_verification_method_other: string
    public_installed_after_lead_ban: string
    private_installed_after_lead_ban: string
    location_name: string
    ownership: string
    public_diameter_over_two_inches: boolean
    private_diameter_over_two_inches: boolean
    consumer_notice_completed: boolean
    consumer_notice_completion_date: any
    private_classification_basis_notes: string
    public_classification_basis_notes: string
    epa_category_unknown: number
    epa_category_non_lead: number
    epa_category_galvanized: number
    epa_category_lead: number
}

interface Params {
  query: string
}

export const geoserverApi = createApi({
  baseQuery: baseQuery({ baseUrl: '/platform/map' }),
  reducerPath: 'geoserver',
  tagTypes: [Tag.GeoserverInventory],
  endpoints: builder => ({
    getInventoryProperties: builder.query<InventoryProperties, Params>({
      providesTags: [Tag.GeoserverInventory],
      query: arg => ({
        url: `/wms${arg.query}`
      }),
      transformResponse: (response: GeoserverFeatureResponse) => {
        const properties = response?.features[0]?.properties ?? {}
        const id = response?.features[0]?.id?.split('.')[1] ?? undefined
        properties.submission_service_line_id = parseInt(id)
        return response?.features[0]?.properties
      }
    })
  })
})

export const { useGetInventoryPropertiesQuery } = geoserverApi
