import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import { Card } from "@120wateraudit/waterworks";
import styled from "styled-components";

import ServiceLine from "src/types/ServiceLine";
import Row from "src/components/Row";
import Column from "src/components/Column";
import StyledDetail from "src/components/StyledDetail";
import { formatDate } from "src/utils/format";

interface Props {
  serviceLine: ServiceLine;
}

const FittingsAccordion: React.FC<Props> = ({ serviceLine }) => {
  const [isFittingsOpen, setIsFittingsOpen] = useState<boolean>(true);
  const [isGeneralOpen, setIsGeneralOpen] = useState<boolean>(true);
  const [isVerificationOpen, setIsVerificationOpen] = useState<boolean>(true);

  const closeAll = (): void => {
    setIsGeneralOpen(false);
    setIsVerificationOpen(false);
  };
  return (
    <StyledCard>
      <Accordion>
        <Accordion.Title
          active={isFittingsOpen}
          onClick={() => {
            setIsFittingsOpen(!isFittingsOpen);
            closeAll();
          }}
          style={{ fontSize: "1.07rem", fontWeight: "700" }}
        >
          <Icon name="dropdown" />
          Fittings / Gooseneck / Other
        </Accordion.Title>
        <Accordion.Content active={isFittingsOpen}>
          <StyledCard style={{ padding: "0px", marginBottom: ".5rem" }}>
            <Accordion.Title
              active={isGeneralOpen}
              onClick={() => setIsGeneralOpen(!isGeneralOpen)}
              style={{ fontSize: "1.07rem", fontWeight: "700" }}
            >
              <Icon name="dropdown" />
              General
            </Accordion.Title>
            <Accordion.Content active={isGeneralOpen}>
              <StyledCard style={{ border: "none" }}>
                <Row style={{ gap: "1rem", alignItems: "flex-start" }}>
                  <Column>
                    <Detail label="Lead Fittings">
                      {serviceLine.leadFittings || "--"}
                    </Detail>
                  </Column>
                  <Column>
                    <Detail label="Other Lead Equipment">
                      {serviceLine.otherLeadEquipment || "--"}
                    </Detail>
                  </Column>
                  <Column />
                </Row>
              </StyledCard>
            </Accordion.Content>
          </StyledCard>

          <StyledCard style={{ padding: "0px", marginBottom: ".5rem" }}>
            <Accordion.Title
              active={isVerificationOpen}
              onClick={() => setIsVerificationOpen(!isVerificationOpen)}
              style={{ fontSize: "1.07rem", fontWeight: "700" }}
            >
              <Icon name="dropdown" />
              Verification
            </Accordion.Title>
            <Accordion.Content active={isVerificationOpen}>
              <StyledCard style={{ border: "none" }}>
                <Row style={{ gap: "1rem", alignItems: "flex-start" }}>
                  <Column>
                    <Detail label="Verification Method">
                      {serviceLine.fittingsVerificationMethod || "--"}
                    </Detail>
                    <Detail label="Verification Date">
                      {formatDate(serviceLine.fittingsVerifiedDate) || "--"}
                    </Detail>
                  </Column>
                  <Column>
                    {serviceLine.fittingsVerificationMethod === "Other" && (
                      <Detail label="Other Notes">
                        {serviceLine.fittingsVerificationMethodOther || "--"}
                      </Detail>
                    )}
                    <Detail label="Verified By">
                      {serviceLine.fittingsVerifiedBy || "--"}
                    </Detail>
                  </Column>
                  <Column />
                </Row>
              </StyledCard>
            </Accordion.Content>
          </StyledCard>
        </Accordion.Content>
      </Accordion>
    </StyledCard>
  );
};

export default FittingsAccordion;

const StyledCard = styled(Card)`
  box-shadow: none;
  border: 2px solid rgb(246, 247, 247);
  padding: 8px 8px;
`;

const Detail = styled(StyledDetail)`
  margin-bottom: 1.5rem;
`;
