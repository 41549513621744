import React from 'react'
import styled from 'styled-components'

import footerSrc from 'src/assets/footer.svg'

const Footer: React.FC = () => (
  <Container>
    <Icon src={footerSrc} />
    <Content>
      <span>
        © {new Date().getFullYear()} 120Water™ •{' '}
        <a
          href="https://120water.com/terms-of-use"
          rel="noreferrer noopener"
          target="_blank"
        >
          Terms and Conditions
        </a>{' '}
        •{' '}
        <a
          href="https://120water.com/privacy-policy"
          rel="noreferrer noopener"
          target="_blank"
        >
          Privacy Policy
        </a>
      </span>
    </Content>
  </Container>
)

const Container = styled.footer`
  grid-area: footer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(0, 36, 45, 0.25);
  padding: 2rem 0;
  font-size: 10px;
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  line-height: 1.333;

  & a {
    color: inherit;
    &:hover {
      color: rgba(0, 181, 225, 1);
    }
  }
`

const Icon = styled.img`
  opacity: 0.25;
  height: 1rem;
  margin-bottom: 1rem;
`

export default Footer
