import { User } from "./types";

export const getDefaultAccount = (
  user?: User
): undefined | { name: string, logoUrl: string | undefined, primacyCode: string | undefined, id: number | undefined } => {
  if (!user) {
    return undefined;
  }
  return user.defaultAccount
};
