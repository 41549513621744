import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import { Card } from "@120wateraudit/waterworks";
import styled from "styled-components";

import ServiceLine from "src/types/ServiceLine";
import Row from "src/components/Row";
import Column from "src/components/Column";
import StyledDetail from "src/components/StyledDetail";

interface Props {
  serviceLine: ServiceLine;
}

const ProfileAccordion: React.FC<Props> = ({ serviceLine }) => {
  const [isFittingsOpen, setIsFittingsOpen] = useState<boolean>(true);
  const [isGeneralOpen, setIsGeneralOpen] = useState<boolean>(true);

  const closeAll = (): void => {
    setIsGeneralOpen(false);
  };

  return (
    <StyledCard style={{ marginBottom: ".5rem" }}>
      <Accordion>
        <Accordion.Title
          active={isFittingsOpen}
          onClick={() => {
            setIsFittingsOpen(!isFittingsOpen);
            closeAll();
          }}
          style={{ fontSize: "1.07rem", fontWeight: "700" }}
        >
          <Icon name="dropdown" />
          Profile
        </Accordion.Title>
        <Accordion.Content active={isFittingsOpen}>
          <StyledCard style={{ padding: "0px", marginBottom: ".5rem" }}>
            <Accordion.Title
              active={isGeneralOpen}
              onClick={() => setIsGeneralOpen(!isGeneralOpen)}
              style={{ fontSize: "1.07rem", fontWeight: "700" }}
            >
              <Icon name="dropdown" />
              General
            </Accordion.Title>
            <Accordion.Content active={isGeneralOpen}>
              <StyledCard style={{ border: "none" }}>
                <Row style={{ gap: "1rem", alignItems: "flex-start" }}>
                  <Column>
                    <Detail label="LCRR Tier">
                      {serviceLine.lcrrTier || "--"}
                    </Detail>
                    <Detail label="LCRR Tier Sampling Site">
                      {serviceLine.currentLcrSamplingSite || "--"}
                    </Detail>
                    <Detail label="Year Built">
                      {serviceLine.yearBuilt || "--"}
                    </Detail>
                  </Column>
                  <Column>
                    <Detail label="Property Classification">
                      {serviceLine.propertyClassification || "--"}
                    </Detail>
                    <Detail label="Point of Entry or Point of Use Treatment">
                      {serviceLine.poeOrPouTreatment || "--"}
                    </Detail>
                  </Column>
                  <Column />
                </Row>
              </StyledCard>
            </Accordion.Content>
          </StyledCard>
        </Accordion.Content>
      </Accordion>
    </StyledCard>
  );
};

export default ProfileAccordion;

const StyledCard = styled(Card)`
  box-shadow: none;
  border: 2px solid rgb(246, 247, 247);
  padding: 8px 8px;
`;

const Detail = styled(StyledDetail)`
  margin-bottom: 1.5rem;
`;
