import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";

type TokenState = null | string;

const INITIAL_STATE = null as TokenState;

const tokenSlice = createSlice({
  initialState: INITIAL_STATE,
  name: "token",
  reducers: {
    clearToken(state) {
      return null;
    },
    setToken(state, action: PayloadAction<TokenState>) {
      return action.payload;
    }
  },
});

export const { clearToken, setToken } = tokenSlice.actions;

export const selectToken = (state: RootState): TokenState => state.token;

export default tokenSlice.reducer;
