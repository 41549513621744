// import { Button } from "@120wateraudit/waterworks";
import React, { useEffect } from "react";
import { Form, Icon, Loader } from "semantic-ui-react";
import styled from "styled-components";
import { RadioCard } from "./RadioCard";
import { Form as FinalForm, Field, FieldRenderProps } from "react-final-form";
import {
  useGetTenantConfigQuery,
  usePostSyncTenantDataMutation,
  usePutTenantConfigMutation,
} from "src/services/ptd";
import { Flags, useConfig } from "src/hooks/useConfig";
import { useTooltip } from "../../hooks/useTooltip";
import { toastSuccess, toastError } from "src/utils/toast";
import { Button } from "@120wateraudit/waterworks";
import { useCurrentUser } from "../User";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: calc(100% + 50px);
  margin: -25px;

  > * {
    padding: 25px;
  }

  > :not(:first-child) {
    border-top: 1px solid #e0e0e0;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const StyledSettingsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SettingsH2 = styled.h2`
  margin: 0;
  font-size: 24px;
`;

const SettingTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  margin-top: 0;
`;

const StyledFieldContainer = styled.div`
  width: 100%;
  max-width: 815px;
`;

const StyledSaveButton = styled(Button)`
  width: 145px;
  height: 40px;
`;

const DisabledHack = styled.div`
  ~ * {
    opacity: 0.25;
  }
`;

const StyledTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface SubmitSettings {
  active: boolean;
  title?: string;
  disclaimer?: string;
  infoPanel?: string;
}

interface SubmitRenameServiceLines {
  systemOwnedMaterial?: string;
  customerOwnedMaterial?: string;
}

interface SubmitUtilityLinks {
  allowUtilityLinks: boolean;
}

export const PublishSettingsFlagged: React.FC = () => {
  const [statePtdEnabled, loaded] = useConfig(Flags.StatePtdSettings);

  if (!loaded) {
    return (
      <PageContainer>
        <Loader />
      </PageContainer>
    );
  }

  if (statePtdEnabled) {
    return <PublishSettings />;
  }

  return null;
};

export const PublishSettings: React.FC = () => {
  const [publishOnAcceptance, setPublishOnAcceptance] = React.useState(false);
  const { setTooltipPosition, setTooltipContent } = useTooltip();
  const { user } = useCurrentUser();
  useEffect(() => {
    console.log(user);
  }, [user]);

  const {
    data: config,
    isLoading,
    error,
    refetch,
  } = useGetTenantConfigQuery(null);

  useEffect(() => {
    if (!config) {
      return;
    }
    setPublishOnAcceptance(config.publishOnAcceptance);
  }, [config]);

  const [trigger, { isLoading: isMutating }] = usePutTenantConfigMutation();
  const [triggerSync, { isLoading: isSyncing }] =
    usePostSyncTenantDataMutation();

  const handleSync: () => void = () => {
    triggerSync(null)
      .unwrap()
      .then(() => {
        toastSuccess("Successfully synced PWS data");
      })
      .catch(() => {
        toastError("An error occurred syncing tenant PWS data");
      });
  };

  const onSubmit: (
    data: SubmitSettings | SubmitRenameServiceLines | SubmitUtilityLinks
  ) => Promise<any> = async (data) => {
    if (!config) {
      return;
    }
    try {
      await trigger({
        ...config,
        ...data,
      }).unwrap();
      toastSuccess("Successfully updated settings");
      try {
        await refetch().unwrap();
      } catch {
        toastError("An error occured refreshing the data");
      }
    } catch {
      toastError("An error occurred during update");
    }
  };

  return (
    <PageContainer>
      {isLoading && <Loader />}
      {!isLoading && !error && config && (
        <>
          <FinalForm
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <StyledForm onSubmit={handleSubmit}>
                <StyledSettingsHeader
                  style={{
                    flexDirection: "column",
                    gap: 16,
                    alignItems: "flex-start",
                  }}
                >
                  <StyledSettingsHeader style={{ width: "100%" }}>
                    <SettingsH2>Publishing</SettingsH2>
                    <StyledSaveButton
                      variant="primary"
                      type="submit"
                      disabled={isMutating}
                    >
                      {isMutating ? "Saving..." : "Save"}
                    </StyledSaveButton>
                  </StyledSettingsHeader>
                  <p>
                    Choose the publishing method that works best for you. These
                    settings can be modified at any time
                  </p>
                </StyledSettingsHeader>
                <StyledFieldContainer>
                  <SettingTitle>Publishing Inventory Data to PTD</SettingTitle>
                  <p>
                    Select the method you will use to publish accepted
                    inventories to your State PTD
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: 40,
                    }}
                  >
                    <Field<boolean>
                      name="publishOnAcceptance"
                      initialValue={config.publishOnAcceptance}
                    >
                      {({ input }: FieldRenderProps<boolean, HTMLElement>) => (
                        <>
                          <RadioCard
                            title={
                              <StyledTitle>
                                Publish upon State inventory acceptance{" "}
                                <Icon
                                  name="info circle"
                                  color="grey"
                                  size="tiny"
                                  style={{ paddingTop: 1 }}
                                  onMouseEnter={(e: MouseEvent) => {
                                    setTooltipPosition(e.clientX, e.clientY);
                                    setTooltipContent(
                                      "Once PWSID inventory is accepted in State Dashboard, that inventory data will be made available on State PTD automatically"
                                    );
                                  }}
                                  onMouseLeave={() => setTooltipContent(null)}
                                />
                              </StyledTitle>
                            }
                            selected={input.value}
                            onClick={() => {
                              setPublishOnAcceptance(!input.value);
                              input.onChange(!input.value);
                            }}
                          />
                          <RadioCard
                            title={
                              <StyledTitle>
                                Publish with consent from the water system{" "}
                                <Icon
                                  name="info circle"
                                  color="grey"
                                  size="tiny"
                                  style={{ paddingTop: 1 }}
                                  onMouseEnter={(e: MouseEvent) => {
                                    setTooltipPosition(e.clientX, e.clientY);
                                    setTooltipContent(
                                      "Once PWS inventory is accepted, the water system utility must authorize consent in their account to publish the data to the State PTD"
                                    );
                                  }}
                                  onMouseLeave={() => setTooltipContent(null)}
                                />
                              </StyledTitle>
                            }
                            selected={!input.value}
                            onClick={() => {
                              setPublishOnAcceptance(!input.value);
                              input.onChange(!input.value);
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </StyledFieldContainer>
                <StyledFieldContainer>
                  {!publishOnAcceptance && <DisabledHack />}
                  <SettingTitle>Publishing Options</SettingTitle>
                  <p>
                    Select which method you will use to publish accepted
                    inventories to your State PTD
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: 40,
                    }}
                  >
                    <Field<boolean>
                      name="autoPublish"
                      initialValue={config.autoPublish}
                    >
                      {({ input }: FieldRenderProps<boolean, HTMLElement>) => (
                        <>
                          <RadioCard
                            title={"Auto-Publish"}
                            description={
                              "Upon submission acceptance, the data will automatically be populated into the State PTD"
                            }
                            selected={input.value}
                            onClick={() => {
                              input.onChange(!input.value);
                            }}
                            disabled={!publishOnAcceptance}
                          />
                          <RadioCard
                            title={"Manual Publish"}
                            description={
                              "Data will need to be manually published by a State Administrator to be shown in the State PTD"
                            }
                            selected={!input.value}
                            onClick={() => {
                              input.onChange(!input.value);
                            }}
                            disabled={!publishOnAcceptance}
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </StyledFieldContainer>
              </StyledForm>
            )}
          />
          {user?.permissions.includes("stateportal:admin") && (
            <div style={{ width: "100%" }}>
              <SettingTitle>Sync Options</SettingTitle>
              <Button type="button" onClick={handleSync} disabled={isSyncing}>
                {isSyncing ? "Syncing..." : "Sync Counties and PWSs"}
              </Button>
            </div>
          )}
        </>
      )}
    </PageContainer>
  );
};
