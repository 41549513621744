import { AQUA_BLUE, DARK_BLUE, DARK_GRAY_BLUE, LIGHT_GRAY, LIGHTEST_GRAY } from '@120wateraudit/waterworks';
import React, { useContext } from 'react';
import { Button, Divider } from 'semantic-ui-react';
import { ModalContext } from 'src/modules/Modal';
import { useEditSavedViewMutation } from 'src/services';
import { SavedView } from 'src/types/SavedView';
import { toastSuccess } from 'src/utils/toast';
import styled from "styled-components";

interface CreateOrEditModalProps {
  oldView: SavedView,
  newView: Record<string, unknown>,
  close: () => void,
  tableName: string,
  setViewId: (id: number) => void,
}

export const CreateOrEditModal: React.FC<CreateOrEditModalProps> = ({ oldView, newView, close, tableName, setViewId }) => {
  const [editView, { isLoading }] = useEditSavedViewMutation();

  const { openModal, closeModal } = useContext(ModalContext);

  const onEdit = async (): Promise<void> => {
    await editView({
      id: oldView.id,
      view: newView
    });

    close();

    toastSuccess(`Successfully saved "${oldView.name}"`);
  }

  const onNew = (): void => {
    openModal("createSavedViewModal", { view: newView, tableName, close: closeModal, setViewId })
  }

  return (
    <ModalContentContainer>
      <ModalHeader>Saved Views</ModalHeader>
      <DividerContainer>
        <Divider />
      </DividerContainer>
      <ContentHeader>
        Would you like to override <span>{oldView.name}</span> or create a new View?
      </ContentHeader>
      <ButtonsWrapper>
        <NewViewButton disabled={isLoading} onClick={onNew}>Create New View</NewViewButton>
        <OverrideButton disabled={isLoading} onClick={onEdit}>Override Existing View</OverrideButton>
      </ButtonsWrapper>
    </ModalContentContainer>
  )
}

const ModalContentContainer = styled.div`
  display: flex;
  margin: 0;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
`;

const DividerContainer = styled.div`
  width: 100%;
`
const ModalHeader = styled.h3`
  padding: 16px;
  width: 100%;
  text-align: left;
  padding-bottom: 0;
  margin-bottom: 0px;
`

const ContentHeader = styled.h4`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 0;
  span {
    color: ${DARK_BLUE};
    font-weight: 800;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  margin: 1.5rem 1rem;
  display: flex;
  width: 100%;
  justify-content: center;
  button {
    margin: 0px 8px !important;
  }
`;

const NewViewButton = styled(Button)`
  &&& {
    background-color: ${LIGHTEST_GRAY};
    color: ${DARK_GRAY_BLUE};
    border: 1px solid ${LIGHT_GRAY};
    border-radius: 8px;
  }
`

const OverrideButton = styled(Button)`
  &&& {
    background-color: ${AQUA_BLUE};
    color: white;
    border-radius: 8px;
  }
`
