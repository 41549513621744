import React from "react";
import { Link, useParams } from "react-router-dom";

import NotFoundCard from "src/components/NotFoundCard";
import Page from "src/components/Page";
import { useGetSubmissionServiceLineQuery } from "src/services";
import { LoadingIndicatorPage } from "src/components/LoadingIndicatorPage";
import { ServiceLineDetailTabs } from "src/modules/Submissions/ServiceLines/ServiceLineDetailTabs";
import PageHeader from "src/components/PageHeader";

export const SubmissionServiceLineDetails: React.FC = () => {
  const params = useParams<{ submissionId: string; serviceLineId: string }>();
  const submissionId = params.submissionId ?? "";
  const {
    data: response,
    isError,
    isFetching,
  } = useGetSubmissionServiceLineQuery({
    submissionId,
    serviceLineId: params.serviceLineId ?? "",
  });

  const serviceLine = response;

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  if (!serviceLine || isError) {
    return (
      <Page hasBackButton>
        <NotFoundCard>
          <p>
            We could not find the Submission Service Line you were looking for.
            It may have been moved or marked as inactive.
          </p>
          <p>
            We recommend returning to{" "}
            <Link to={`/submissions/${submissionId}`}>
              the list of Service Lines fr your Submission for your account.
            </Link>
          </p>
        </NotFoundCard>
      </Page>
    );
  }

  return (
    <Page
      hasBackButton
      title={
        <>
          <PageHeader>
            {serviceLine.addressLine1}
            {serviceLine.addressLine2
              ? ` ${serviceLine.addressLine2}`
              : ""}, {serviceLine.city}, {serviceLine.state} {serviceLine.zip}
          </PageHeader>
        </>
      }
    >
      <ServiceLineDetailTabs serviceLine={serviceLine} />
    </Page>
  );
};
