import { Card, IGNIS_RED } from "@120wateraudit/waterworks";
import React, { CSSProperties } from "react";
import { Popup } from "semantic-ui-react";
import styled from "styled-components";

import { formatPercent } from "src/utils/format";

const calculateWidth = (x: number, total: number): string => {
  const ratio = x / total;
  if (ratio > 0 && ratio < 0.01) {
    return "1%";
  }

  return formatPercent(ratio);
};

interface MaterialProps {
  backgroundColor: string;
  popupText: React.ReactNode;
  width: string;
}

const Material = ({
  backgroundColor,
  popupText,
  width,
}: MaterialProps): null | JSX.Element => {
  return (
    <StyledPopup
      trigger={
        <MaterialContent width={width} backgroundColor={backgroundColor} />
      }
    >
      <PopupText>{popupText}</PopupText>
    </StyledPopup>
  );
};

interface Props {
  className?: string;
  galvanized: number;
  lead: number;
  nonLead: number;
  totalLines: number;
  style?: CSSProperties;
  unknown: number;
  title?: string;
}

const MaterialsCard = ({
  className,
  galvanized,
  lead,
  nonLead,
  totalLines,
  style,
  unknown,
  title,
}: Props): null | JSX.Element => {
  const total = galvanized + lead + nonLead + unknown;
  const leadPercent = calculateWidth(lead, total);
  const galvanizedPercent = calculateWidth(galvanized, total);
  const nonLeadPercent = calculateWidth(nonLead, total);
  const unknownPercent = calculateWidth(unknown, total);

  return (
    <Card className={className} style={style}>
      <MetricHeader>
        <h2>{title ?? "Materials"}</h2>
      </MetricHeader>
      <h3 style={{ margin: "15px 0px" }}>
        Total Service Lines:
        <span style={{ marginLeft: "1rem" }}>{totalLines}</span>
      </h3>
      <MaterialContainer>
        <MaterialsBar>
          <Material
            backgroundColor={IGNIS_RED}
            popupText={`Lead Assets: ${lead}`}
            width={leadPercent}
          />
          <Material
            backgroundColor="#FFC720"
            popupText={`GRR Assets: ${galvanized}`}
            width={galvanizedPercent}
          />
          <Material
            backgroundColor="#00B5E2"
            popupText={`Non-Lead Assets: ${nonLead}`}
            width={nonLeadPercent}
          />
          <Material
            backgroundColor="#0033A0"
            popupText={`Unknown Assets: ${unknown}`}
            width={unknownPercent}
          />
        </MaterialsBar>
        <Legend>
          <LegendContent>
            <MaterialBadge backgroundColor={IGNIS_RED}>{lead}</MaterialBadge>
            <LegendText>Lead</LegendText>
          </LegendContent>
          <LegendContent>
            <MaterialBadge backgroundColor="#FFC720">
              {galvanized}
            </MaterialBadge>
            <LegendText>GRR</LegendText>
          </LegendContent>
          <LegendContent>
            <MaterialBadge backgroundColor="#00B5E2">{nonLead}</MaterialBadge>
            <LegendText>Non-Lead</LegendText>
          </LegendContent>
          <LegendContent>
            <MaterialBadge backgroundColor="#0033A0">{unknown}</MaterialBadge>
            <LegendText>Unknown</LegendText>
          </LegendContent>
        </Legend>
      </MaterialContainer>
    </Card>
  );
};

const MaterialContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Legend = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 15px;

  @media (max-width: 1160px) {
    gap: 1rem;
  }

  @media (max-width: 615px) {
    flex-wrap: wrap;
  }
`;

const LegendText = styled.span`
  margin-left: 5px;
  color: #707683;
  font-size: body;
  font-weight: 700;
`;

const MaterialsBar = styled.div`
  display: flex;
  width: 100%;
  min-height: 70px;
`;

const MaterialContent = styled.div<{ backgroundColor: string; width: string }>`
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  border-radius: 10px;
  margin: 0.25rem;
  cursor: pointer;
`;

const LegendContent = styled.div`
  display: flex;
  align-items: center;
`;

const MaterialBadge = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  text-align: center;
  color: white;
  font-weight: 700;
  border-radius: 10px;
  margin: 0.25rem;
  padding: 0.25rem 1rem;
  min-width: 50px;
`;

const MetricHeader = styled.div`
  line-height: 1.25;
  font-weight: 700;
  white-space: pre-line;
  text-align: left;
`;

const PopupText = styled.div`
  background-color: #192a3e;
  color: #ffffff;
  white-space: pre-line;
`;

export const StyledPopup = styled(Popup)`
  &&& {
    background-color: #192a3e;
    border-radius: 5px;

    :before {
      background-color: #192a3e;
    }
  }
`;
export default MaterialsCard;
