import React from "react";
import { Divider } from "semantic-ui-react";
import styled from "styled-components";

import {
  BoldSpan,
  DoubleInputGrid,
  FlexDiv,
  GeneralContainer,
  Value,
  QuestionTitle,
} from "../shared";

interface Props {
  additionalInfo: { [key: string]: any };
  publicAccessTitle?: string
}

const EpaPublicAccessibilityDoc: React.FC<Props> = ({ additionalInfo, publicAccessTitle }) => {
  const {
    locationIdentifierChoices,
    locationIdentifierServiceLineYesNo,
    locationIdentifierServiceLineNoExplanation,
    locationIdentifierOtherExplanation,
    inventoryPublicAccessChoices,
    inventoryPublicAccessOtherExplanation,
  } = additionalInfo;

  // Location Identifier Choice Values
  const hasAddressChoice = locationIdentifierChoices?.includes("Address");
  const hasStreetChoice = locationIdentifierChoices?.includes("Street");
  const hasBlockChoice = locationIdentifierChoices?.includes("Block");
  const hasIntersectionChoice =
    locationIdentifierChoices?.includes("Intersection");
  const hasLandmarkChoice = locationIdentifierChoices?.includes("Landmark");
  const hasGPSChoice = locationIdentifierChoices?.includes("GPS Coordinates");
  const hasLocationIdentifierOtherChoice =
    locationIdentifierChoices?.includes("Other");

  // Public Access Choice Values
  const hasInteractiveOnlineMapChoice = inventoryPublicAccessChoices?.includes(
    "Interactive online map"
  );
  const hasStaticOnlineMapChoice =
    inventoryPublicAccessChoices?.includes("Static online map");
  const hasOnlineSpreadsheet =
    inventoryPublicAccessChoices?.includes("Online spreadsheet");
  const hasPrintedMapChoice = inventoryPublicAccessChoices?.includes(
    "Printed service line map"
  );
  const hasPrintedTubularDataChoice = inventoryPublicAccessChoices?.includes(
    "Printed tabular Data"
  );
  const hasMailingOrNewsletterChoice = inventoryPublicAccessChoices?.includes(
    "Information on water utility mailings or newsletter"
  );
  const hasHardCopyChoice = inventoryPublicAccessChoices?.includes(
    "Hard copy information available in water system office"
  );
  const hasPublicAccessOtherChoice =
    inventoryPublicAccessChoices?.includes("Other");

  return (
    <GeneralContainer>
      <div>
        <span>
          Select the location identifiers that you use for your service line
          inventory. Check all that apply.
        </span>
        <DoubleInputGrid>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasAddressChoice} />
            <label>Address</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasStreetChoice} />
            <label>Street</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasBlockChoice} />
            <label>Block</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasIntersectionChoice} />
            <label>Intersection</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasLandmarkChoice} />
            <label>Landmark</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasGPSChoice} />
            <label>GPS Coordinates</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasLocationIdentifierOtherChoice} />
            <label>Other</label>
          </StyledFlexDiv>
        </DoubleInputGrid>
      </div>
      <div>
        <QuestionTitle>If Other, please describe</QuestionTitle>
        <Value>{locationIdentifierOtherExplanation ?? "--"}</Value>
      </div>
      <Divider />
      <div>
        <QuestionTitle>
          <p>
            Does every <BoldSpan>service line</BoldSpan> have a location
            identifier?
          </p>
        </QuestionTitle>
        <Value>{locationIdentifierServiceLineYesNo ?? "--"}</Value>
      </div>
      <div>
        <QuestionTitle>
          If No, explain. Remember that location identifiers are required for
          service lines that are lead and galvanized requiring replacement
        </QuestionTitle>
        <Value>{locationIdentifierServiceLineNoExplanation ?? "--"}</Value>
      </div>
      <Divider />

      <div>
        <QuestionTitle>
        {
            publicAccessTitle ??
            `How are you making your inventory publicly accessible? Check all that
            apply. Remember that if your system serves &gt; 50,000 people, you
            must provide the inventory online.`
        }
        </QuestionTitle>
        <DoubleInputGrid>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasInteractiveOnlineMapChoice} />
            <label>Interactive online map</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasStaticOnlineMapChoice} />
            <label>Static online map</label>
          </StyledFlexDiv>{" "}
          <StyledFlexDiv>
            <input type="checkbox" checked={hasOnlineSpreadsheet} />
            <label>Online Spreadsheet</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasPrintedMapChoice} />
            <label>Printed service line map</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasPrintedTubularDataChoice} />
            <label>Printed tabular Data</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasMailingOrNewsletterChoice} />
            <label>Information on water utility mailings or newsletter</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasHardCopyChoice} />
            <label>
              Hard copy information available in water system office
            </label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasPublicAccessOtherChoice} />
            <label>Other</label>
          </StyledFlexDiv>
        </DoubleInputGrid>
      </div>
      <Divider />
      <div>
        <QuestionTitle>If Other, please describe</QuestionTitle>
        <Value>{inventoryPublicAccessOtherExplanation ?? "--"}</Value>
      </div>
    </GeneralContainer>
  );
};

const StyledFlexDiv = styled(FlexDiv)`
  pointer-events: none;
`;

export default EpaPublicAccessibilityDoc;
