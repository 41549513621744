import React, { useContext, useState } from "react";
import { Dropdown } from "@120wateraudit/waterworks";
import styled from "styled-components";
import { ModalContext } from "src/modules/Modal";
import CommentSection from "../CommentSection";
import { useGetAllSubmissionPeriodCommentsQuery } from "src/services";
import CommentForm from "../CommentForm";
import Submission from "src/types/Submission";
import LoadingIndicatorPage from "src/components/LoadingIndicatorPage";
import NoComments from "../NoComments";
import { isWrite } from "src/modules/User";

interface SubmissionPeriodActivityProps {
  submission: Submission;
}

const SubmissionPeriodActivityModal = ({
  submission,
}: SubmissionPeriodActivityProps): JSX.Element => {
  const { id, pwsId, submissionPeriod, activeSubmissionId, primacyAgencyId } =
    submission;
  const { id: submissionPeriodId } = submissionPeriod;
  const { closeModal } = useContext(ModalContext);
  const [activityFilter, setActivityFilter] = useState("all");

  const { data: response, isFetching } = useGetAllSubmissionPeriodCommentsQuery(
    {
      submissionPeriodId,
      pwsId,
    }
  );

  const comments = response?.data ?? [];

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  return (
    <ModalContentContainer>
      {comments.length > 0 && (
        <Dropdown
          style={{ gap: "0px" }}
          onChange={(event) => setActivityFilter(event.target.value)}
          options={[
            { text: "Show All Activity", value: "all" },
            { text: "Show Comments Only", value: "comment" },
            { text: "Show Notifications Only", value: "notification" },
          ]}
          value={activityFilter}
        />
      )}
      {comments.length === 0 && <NoComments />}
      {comments.length > 0 && (
        <CommentSection
          activities={comments}
          activityFilter={activityFilter}
          onClose={closeModal}
        />
      )}
      { isWrite() && <CommentForm
        pwsId={pwsId}
        primacyAgencyId={primacyAgencyId}
        submissionPeriodId={submissionPeriodId}
        submissionId={activeSubmissionId ?? id}
      /> }
    </ModalContentContainer>
  );
};

const ModalContentContainer = styled.div`
  margin: 2rem;
`;

export default SubmissionPeriodActivityModal;
