import React from "react";
import styled from "styled-components";

import { Divider } from "semantic-ui-react";
import {
  DoubleInputGrid,
  FlexDiv,
  GeneralContainer,
  QuestionTitle,
  Value,
} from "../shared";

interface Props {
  additionalInfo: { [key: string]: any };
}

const IdentifyingServiceLines: React.FC<Props> = ({ additionalInfo }) => {
  const {
    waterMeterReading,
    waterMeterRepairOrReplacement,
    serviceLineRepairOrReplacement,
    waterMainRepairOrReplacement,
    backflowPreventionDeviceInspection,
    otherInspection,
    otherInspectionExplanation,
    operatingProcedures,
    operatingProceduresExplanation,
  } = additionalInfo;

  return (
    <GeneralContainer>
      <p style={{ marginBottom: "0px" }}>
        During which normal operating activities are you collecting information
        on service line material? Check all that apply.
      </p>
      <DoubleInputGrid>
        <StyledFlexDiv>
          <input type="checkbox" checked={!!waterMeterReading} />
          <label>Water meter reading</label>
        </StyledFlexDiv>
        <StyledFlexDiv>
          <input type="checkbox" checked={!!waterMeterRepairOrReplacement} />
          <label>Water meter repair or replacement</label>
        </StyledFlexDiv>
        <StyledFlexDiv>
          <input type="checkbox" checked={!!serviceLineRepairOrReplacement} />
          <label>Service line repair or replacement</label>
        </StyledFlexDiv>
        <StyledFlexDiv>
          <input type="checkbox" checked={!!waterMainRepairOrReplacement} />
          <label>Water main repair or replacement</label>
        </StyledFlexDiv>
        <StyledFlexDiv>
          <input
            type="checkbox"
            checked={!!backflowPreventionDeviceInspection}
          />
          <label>Backflow prevention device inspection</label>
        </StyledFlexDiv>
        <StyledFlexDiv>
          <input type="checkbox" checked={!!otherInspection} />
          <label>Other</label>
        </StyledFlexDiv>
      </DoubleInputGrid>
      <Divider />
      <div>
        <QuestionTitle>If other please explain</QuestionTitle>
        <Value>{otherInspectionExplanation ?? "--"}</Value>
      </div>
      <Divider />
      <div>
        <QuestionTitle>
          Did you develop or revise standard operating procedures to collect
          service line material information during normal operation?
        </QuestionTitle>
        <Value>{operatingProcedures ?? "--"}</Value>
      </div>
      <div>
        <QuestionTitle>If Yes, please describe</QuestionTitle>
        <Value>{operatingProceduresExplanation ?? "--"}</Value>
      </div>
      <Divider />
    </GeneralContainer>
  );
};

const StyledFlexDiv = styled(FlexDiv)`
  pointer-events: none;
`;

export default IdentifyingServiceLines;
