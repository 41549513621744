import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import EpaMethodsPart2 from "../EPA/InventoryMethods/EpaMethodsPart2";
import SignatureStep from "../EPA/SignatureStep";
import EpaMethodsPart1 from "./InventoryMethods/EpaMethodsPart1";
import EpaMethodsPart3 from "./InventoryMethods/EpaMethodsPart3";

interface Props {
  additionalInfo: { [key: string]: any };
}

const OregonAccordion: React.FC<Props> = ({ additionalInfo }) => {
  const [isEpaOneOpen, setIsEpaOneOpen] = useState<boolean>(false);
  const [isEpaTwoOpen, setIsEpaTwoOpen] = useState<boolean>(false);
  const [isEpaThreeOpen, setIsEpaThreeOpen] = useState<boolean>(false);
  const [isSignatureOpen, setIsSignatureOpen] = useState<boolean>(false);

  return (
    <Accordion>
      <Accordion.Title
        active={isEpaOneOpen}
        onClick={() => setIsEpaOneOpen(!isEpaOneOpen)}
      >
        <Icon name="dropdown" />
        Historical Records Reviews
      </Accordion.Title>
      <Accordion.Content active={isEpaOneOpen}>
        <EpaMethodsPart1 additionalInfo={additionalInfo} />
      </Accordion.Content>
      <Accordion.Title
        active={isEpaTwoOpen}
        onClick={() => setIsEpaTwoOpen(!isEpaTwoOpen)}
      >
        <Icon name="dropdown" />
        Identifying Service Line Material During Normal Operations
      </Accordion.Title>
      <Accordion.Content active={isEpaTwoOpen}>
        <EpaMethodsPart2 additionalInfo={additionalInfo} />{" "}
      </Accordion.Content>
      <Accordion.Title
        active={isEpaThreeOpen}
        onClick={() => setIsEpaThreeOpen(!isEpaThreeOpen)}
      >
        <Icon name="dropdown" />
        Service Line Investigations
      </Accordion.Title>
      <Accordion.Content active={isEpaThreeOpen}>
        <EpaMethodsPart3 additionalInfo={additionalInfo} />
      </Accordion.Content>
      <Accordion.Title
        active={isSignatureOpen}
        onClick={() => setIsSignatureOpen(!isSignatureOpen)}
      >
        <Icon name="dropdown" />
        Signature Info
      </Accordion.Title>
      <Accordion.Content active={isSignatureOpen}>
        <SignatureStep additionalInfo={additionalInfo} />
      </Accordion.Content>
    </Accordion>
  );
};

export default OregonAccordion;
