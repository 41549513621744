import { BaseQueryFn, fetchBaseQuery } from "@reduxjs/toolkit/query";

import { RootState } from "src/store";

const API_BASE = process.env.REACT_APP_AWS_ENDPOINT ?? "http://localhost:8000";

const baseQuery = ({ baseUrl }: { baseUrl: string }): BaseQueryFn =>
  fetchBaseQuery({
    baseUrl: `${API_BASE}${baseUrl}`,
    prepareHeaders: (headers, { getState }) => {
      const { token } = getState() as RootState;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });

export default baseQuery;
