import { Card, Detail } from "@120wateraudit/waterworks";
import React from "react";
import styled from "styled-components";

import System from "src/types/System";

interface Props {
  system: System;
}

export const SystemDetailsCard: React.FC<Props> = ({ system }) => {
  const { populationServed, pwsId, pwsOwner, pwsType, serviceLineConnections, counties } =
    system;

  return (
    <div
      style={{
        display: "flex",
        flex: 0.5,
        gap: "0.5rem",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Card>
        <StyledDetail label="PWS ID:">{pwsId ?? "--"}</StyledDetail>
        <StyledDetail label="Counties Served:">{counties?.length > 0 ? counties.map((c) => c.shortName).sort().join(', ') : "--"}</StyledDetail>
      </Card>
      <Card style={{ height: "100%" }}>
        <div style={{ marginBottom: "0.5rem" }}>
          <h5>SDWIS Information</h5>
        </div>
        <StyledDetail label="PWS Type:">{pwsType ?? "--"}</StyledDetail>
        <StyledDetail label="Owner:">{pwsOwner ?? "--"}</StyledDetail>
        <StyledDetail label="Population:">
          {populationServed ?? "--"}
        </StyledDetail>
        <StyledDetail label="Service Line Connections:">
          {serviceLineConnections ?? "--"}
        </StyledDetail>
      </Card>
    </div>
  );
};

const StyledDetail = styled(Detail)`
  display: flex;
  label {
    flex: 0.6;
  }

  span {
    flex: 1;
  }
  text-align: left;
  margin-bottom: 0.5rem;
`;

export default SystemDetailsCard;
