import React, { useEffect, useState } from "react";
import { DARK_BLUE } from "@120wateraudit/waterworks";
import styled from "styled-components";
import { useTooltip } from "src/hooks/useTooltip";
import { calculateOpaqueColor } from "src/utils/color";

interface BarGraphProps {
  style?: React.CSSProperties;
  data: Array<{
    bin: string;
    value: number;
    color?: string;
    tooltip?: React.ReactNode;
  }>;
  xLabel?: string;
  yLabel?: string;
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto min-content;
  gap: 10px;
  height: 100%;
`;

const YAxisContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-row: 1;
  grid-column: 1;
  gap: 2px;
  padding: 10px 0;
  width: min-content;
  height: 100%;
`;

const BoundsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const YLabel = styled.span`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
`;

const XAxisContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  grid-row: 2;
  grid-column: 1 / span 2;
`;

const BinsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px 10px 0;
  grid-row: 1;
  grid-column: 2;
  width: 100%;
`;

const BinContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 10px;
`;

const BarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;

const Bar = styled.div<{ value: number; color?: string }>`
  background-color: ${({ color }) => color ?? DARK_BLUE};
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: ${({ value }) => `${value}%`};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.5s;
  position: relative;
`;

export const BarGraph: React.FC<BarGraphProps> = ({
  style,
  data,
  xLabel,
  yLabel,
}) => {
  const [graphData, setGraphData] = useState<
    Array<{
      bin: string;
      value: number;
      valueRaw: number;
      color?: string;
      tooltip?: React.ReactNode;
    }>
  >([]);
  const [upperBound, setUpperBound] = useState<number>(0);
  const { setTooltipContent, setTooltipPosition } = useTooltip();
  useEffect(() => {
    const max = Math.max(...data.map(({ value }) => value));
    const significantDigits = Math.max(Math.floor(Math.log10(max)) - 1, 1);
    const roundedMax =
      Math.ceil(max / 10 ** significantDigits) * 10 ** significantDigits;
    setUpperBound(roundedMax);
    setGraphData(
      data.map(({ bin, value, color, tooltip }, i) => ({
        bin,
        tooltip,
        value: (value / roundedMax) * 100,
        valueRaw: value,
        color: calculateOpaqueColor(
          color ?? DARK_BLUE,
          (value / roundedMax) * 0.5 + 0.5
        ),
      }))
    );
  }, [data]);

  return (
    <div style={style}>
      <Grid>
        <YAxisContainer>
          {yLabel && <YLabel>{yLabel}</YLabel>}
          <BoundsContainer>
            <span>{upperBound || " "}</span>
            <span>{upperBound / 2 || " "}</span>
            <span>{upperBound ? 0 : " "}</span>
          </BoundsContainer>
        </YAxisContainer>
        <BinsContainer>
          {graphData.map(
            ({ bin, value, valueRaw, color, tooltip: tooltip_ }, i) => (
              <BinContainer
                key={`${bin}-${i}`}
                onMouseEnter={(e) => {
                  if (!tooltip_) return;
                  const rect = e.currentTarget.getBoundingClientRect();
                  const x = rect.left + rect.width / 1.5;
                  const y = rect.top + rect.height + 5;
                  setTooltipPosition(x, y);
                  setTooltipContent(tooltip_);
                }}
                onMouseLeave={() => setTooltipContent(false)}
              >
                <BarContainer>
                  <Bar value={value} color={color}>
                    {valueRaw > 0 && (
                      <span
                        style={{
                          color: "white",
                        }}
                      >
                        <strong>{valueRaw}</strong>
                      </span>
                    )}
                  </Bar>
                </BarContainer>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  {bin}
                </div>
              </BinContainer>
            )
          )}
        </BinsContainer>
        {xLabel && <XAxisContainer>{xLabel}</XAxisContainer>}
      </Grid>
    </div>
  );
};
