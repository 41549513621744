import React from "react";
import { SubmissionCommunication } from "src/types/SubmissionCommunication";
import { Attachment } from "src/types/Attachment";
import { SubmissionCommunicationEntity } from "src/types/enums/SubmissionCommunicationEntity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faDownload } from "@fortawesome/free-solid-svg-icons";
import { clickUrlDownload } from "src/hooks/useFileDownload";
import { useGetCommunicationAttachmentsQuery } from "src/services";
import Loader from "src/components/Loader";
import { LIGHT_GRAY } from "@120wateraudit/waterworks";
import { Link } from "react-router-dom";
import { toastError } from "src/utils/toast";
import styled from "styled-components";

interface Props {
  closeModal: () => void;
  submissionCommunication: SubmissionCommunication;
  accountId: number;
}

const methodsOfDistributionNotices = [
  { value: "Mailed with water bill", key: "mailedWithWaterBill" },
  { value: "Mailed with CCR", key: "mailedWithCCR" },
  { value: "Separate mail", key: "separateMail" },
  { value: "Hand delivery", key: "handDelivery" },
  { value: "Email", key: "email" },
];

const ViewConsumerNoticeModal: React.FC<Props> = ({
  submissionCommunication,
  accountId,
}) => {
  const { pwsSubmissionPeriodId, data } = submissionCommunication;
  const {
    submitterName,
    submitterEmail,
    submitterPhone,
    leadNoticeDistributions,
    leadNoticeDistributionsDate,
    grrNoticeDistributions,
    grrNoticeDistributionsDate,
    unknownNoticeDistributions,
    unknownNoticeDistributionsDate,
    mailedWithWaterBill,
    mailedWithCCR,
    separateMail,
    handDelivery,
    email,
    lettersAttachmentsRequired,
  } = data;

  const {
    data: useGetCommunicationsCertificate,
    isLoading: isLoadingCommunicationsCertificate,
  } = useGetCommunicationAttachmentsQuery({
    entity: SubmissionCommunicationEntity.submissionsCommunicationsCertificates,
    pwsSubmissionPeriodId,
    accountId,
  });

  const { data: useGetCommunicationsLetters, isLoading: isLoadingLetters } =
    useGetCommunicationAttachmentsQuery({
      entity: SubmissionCommunicationEntity.submissionsCommunicationsLetters,
      pwsSubmissionPeriodId,
      accountId,
    });
  return (
    <ChangeStatusContainer>
      <h3 style={{ paddingBottom: "30px" }}>Customer Notice Responses</h3>
      <InlineWrapper>
        <DetailField title="Submitter Name" value={submitterName} />
        <DetailField title="Submitter Email" value={submitterEmail} />
        <DetailField title="Submitter Phone" value={submitterPhone} />
      </InlineWrapper>
      <Header>
        <span>
          During this submission period, by what method did you distribute
          notices?
        </span>
        <br />
        <span>Select all that apply.</span>
      </Header>
      <MethodsOfDistributionNoticesContainer>
        {methodsOfDistributionNotices.map(({ value, key }) => (
          <FieldSelectBubble key={key}>
            <Bubble
              selected={
                {
                  mailedWithWaterBill,
                  mailedWithCCR,
                  separateMail,
                  handDelivery,
                  email,
                }[key]
              }
            />
            <span>{value}</span>
          </FieldSelectBubble>
        ))}
      </MethodsOfDistributionNoticesContainer>
      <DetailField
        title="During this submission period, how many 'Lead' notices have you distributed"
        value={leadNoticeDistributions}
      />
      <DetailField
        title="When did you complete sending your notices?"
        value={leadNoticeDistributionsDate}
        date
      />
      <DetailField
        title="During this submission period, how many 'GRR' notices have you distributed?"
        value={grrNoticeDistributions}
      />
      <DetailField
        title="When did you complete sending your notices?"
        value={grrNoticeDistributionsDate}
        date
      />
      <DetailField
        title="During this submission period, how many 'Unknown' notices have you distributed?"
        value={unknownNoticeDistributions}
      />
      <DetailField
        title="When did you complete sending your notices?"
        value={unknownNoticeDistributionsDate}
        date
      />
      <DownloadAttachments
        name="Communications Certificate"
        attachments={useGetCommunicationsCertificate}
        isLoading={isLoadingCommunicationsCertificate}
      />
      <DownloadAttachments
        name="Letters"
        attachments={useGetCommunicationsLetters}
        isLoading={isLoadingLetters}
        lettersAttachmentsRequired={lettersAttachmentsRequired}
      />
    </ChangeStatusContainer>
  );
};

interface FieldProps {
  title: string;
  value?: string;
  date?: boolean;
}

const DetailField: React.FC<FieldProps> = ({ title, value, date }) => (
  <FieldWrapper date={date}>
    <FieldTitle date={date}>{title}</FieldTitle>
    <FieldValue>
      {date && (
        <FontAwesomeIcon
          size="2x"
          icon={faCalendarAlt}
          style={{ color: LIGHT_GRAY, paddingRight: "5px", width: "18px" }}
        />
      )}
      <FieldValueSpan date={date}>{value}</FieldValueSpan>
    </FieldValue>
  </FieldWrapper>
);

interface DownloadAttachmentsProps {
  name: string;
  attachments: any;
  isLoading: boolean;
  lettersAttachmentsRequired?: boolean;
}

const DownloadAttachments: React.FC<DownloadAttachmentsProps> = ({
  name,
  attachments,
  isLoading,
  lettersAttachmentsRequired,
}) => (
  <DownloadContainer>
    <h3>Download {name}</h3>
    {isLoading
    ? (
      <Loader />
    )
    : (
      <AttachmentList>
        {!attachments?.data.length || lettersAttachmentsRequired === false
        ? (
          <div>No {name} to download</div>
        )
        : (
          attachments?.data.map((attachment: Attachment) => (
            <AttachmentItem key={attachment.id}>
              <AttachmentName>{attachment.originalFileName}</AttachmentName>
              <DownloadButton url={attachment.url} />
            </AttachmentItem>
          ))
        )}
      </AttachmentList>
    )}
  </DownloadContainer>
);

const DownloadButton: React.FC<{ url: string }> = ({ url }) => (
  <StyledLink
    to="#"
    onClick={() => {
      try {
        clickUrlDownload(url);
      } catch (error: unknown) {
        console.error(error);
        toastError("Error downloading file");
      }
    }}
  >
    <FontAwesomeIcon icon={faDownload} />
    Download
  </StyledLink>
);

const ChangeStatusContainer = styled.div`
  margin: 32px;
`;

const InlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Header = styled.div`
  line-height: normal;
  font-weight: bold;
  padding: 15px 0 5px 0;
`;

const FieldWrapper = styled.div<{ date?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ date }) => date && "padding-bottom: 15px"};
`;

const FieldTitle = styled.div<{ date?: boolean }>`
  font-weight: ${({ date }) => (date ? "300" : "bold")};
`;

const FieldValue = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 10px 0;
`;

const FieldValueSpan = styled.span<{ date?: boolean }>`
  ${({ date }) => date && "padding-left: 5px"};
`;

const MethodsOfDistributionNoticesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
`;

const Bubble = styled.div<{ selected: boolean }>`
  background-color: ${({ selected }) => (selected ? "black" : "white")};
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 1px solid black;
`;

const FieldSelectBubble = styled.div`
  margin: 8px 0;
  display: flex;
  align-self: start;

  span {
    padding-left: 5px;
  }
`;

const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
`;

const AttachmentList = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
`;

const AttachmentItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
`;

const AttachmentName = styled.span`
  flex: 1;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  & > svg {
    margin-right: 8px;
  }
`;

export default ViewConsumerNoticeModal;
