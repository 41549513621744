import { useMemo, useContext, useEffect, useCallback } from "react";

import { Values } from "src/modules/Contacts/validation";
import { toastError, toastSuccess } from "src/utils/toast";
import { isApiError } from "src/utils/apiError";
import { ModalContext } from "src/modules/Modal";
import { useEditContactMutation } from "src/services/state-submission";
import { Contact, ContactCommunication } from "src/types/Contact";

export const useInitialValues = (contact: Contact): Values => {
  const {
    name,
    title,
    salutation,
    pwsContactAddresses: addresses,
    pwsContactCommunications: communications,
  } = contact;

  const number = communications.find(
    (c) => c.communicationType === "phone"
  )?.communicationValue;
  const commFax = communications.find(
    (c) => c.communicationType === "fax"
  )?.communicationValue;
  const commEmail = communications.find(
    (c) => c.communicationType === "email"
  )?.communicationValue;
  const commWeb = communications.find(
    (c) => c.communicationType === "web"
  )?.communicationValue;

  return useMemo(() => {
    const values: Values = {
      addresses: addresses ?? [],
      email: commEmail ?? "",
      fax: commFax ?? "",
      name,
      phoneNumber: number ?? "",
      salutation: salutation ?? "",
      title: title ?? "",
      web: commWeb ?? "",
    };

    return values;
  }, []);
};

export const useUpdateContact: (
  systemId: number,
  contactId: number,
  onClose: () => void
) => {
  errors: string[] | undefined;
  onSubmit: (values: Values) => Promise<void>;
} = (systemId, contactId, onClose) => {
  const { openModal } = useContext(ModalContext);
  const [
    trigger,
    { data, error, isError, isLoading, isSuccess, isUninitialized },
  ] = useEditContactMutation();
  useEffect(() => {
    if (!isUninitialized && !isLoading && isSuccess) {
      toastSuccess("Contact has been updated");
    }
  }, [data, isError, isLoading, isSuccess, isUninitialized, openModal]);

  const onSubmit = useCallback(
    async (values: Values) => {
      const {
        email,
        fax,
        phoneNumber,
        web,
        name,
        salutation,
        title,
        addresses,
      } = values;

      const communicationArray: ContactCommunication[] = [];

      if (values.email) {
        communicationArray.push({
          communicationType: "email",
          communicationValue: email,
        });
      }
      if (values.fax) {
        communicationArray.push({
          communicationType: "fax",
          communicationValue: fax,
        });
      }
      if (values.phoneNumber) {
        communicationArray.push({
          communicationType: "phone",
          communicationValue: phoneNumber,
        });
      }
      if (values.web) {
        communicationArray.push({
          communicationType: "web",
          communicationValue: web,
        });
      }

      const updatedContact = {
        pwsId: systemId,
        pwsContactAddresses: addresses,
        pwsContactCommunications: communicationArray,
        name,
        salutation,
        title,
      };
      try {
        await trigger({
          systemId,
          contactId,
          contact: updatedContact,
        }).unwrap();
        onClose();
      } catch {
        toastError("An error occurred. Contact could not be updated.");
      }
    },
    [trigger]
  );

  const errors = useMemo(() => {
    if (!error || !isError) {
      return undefined;
    }

    if (isApiError(error)) {
      return [error.data?.error];
    }

    return ["Please try again or contact support@120water.com"];
  }, [error, isError]);

  return useMemo(() => ({ errors, onSubmit }), [errors, onSubmit]);
};
