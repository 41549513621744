import { Button } from "@120wateraudit/waterworks";
import React from "react";
import { Header, Modal } from "semantic-ui-react";

interface Props {
  onClose: () => void;
}

const ModalNotFound = ({ onClose }: Props): JSX.Element => {
  return (
    <>
      <Header content="Uh oh!" icon="dont" />
      <Modal.Content>
        <p>We could not find what you are looking for. Our apologies!</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} variant="error">
          Close
        </Button>
      </Modal.Actions>
    </>
  );
};

export default ModalNotFound;
