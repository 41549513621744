import React from "react";

import { BasicContainer, BoldSpan, QuestionTitle, Value } from "../../shared";

interface Props {
  additionalInfo: { [key: string]: any };
}

const SummaryPart2: React.FC<Props> = ({ additionalInfo }) => {
  const { inventorySummaryFormatDescription } = additionalInfo;

  return (
    <BasicContainer>
      <QuestionTitle>
        Describe your inventory format in the space provided below (e.g., the{" "}
        <BoldSpan>Detailed Inventory worksheet</BoldSpan>, custom spreadsheet,
        GIS map). Provide the filename and/or web address if applicable.{" "}
        <BoldSpan>
          Note that the state may require you to submit your detailed inventory
          of each service line in your distribution system.
        </BoldSpan>
      </QuestionTitle>
      <Value>{inventorySummaryFormatDescription ?? "--"}</Value>
    </BasicContainer>
  );
};

export default SummaryPart2;
