import { Card } from "@120wateraudit/waterworks";
import React from "react";

import Page from "src/components/Page";
import PageHeader from "src/components/PageHeader";
import SystemsTable from "src/modules/Systems/Table";

const UtilitiesPage: React.FC = () => {
  return (
    <Page title={<PageHeader>Utilities</PageHeader>}>
      <Card>
        <SystemsTable />
      </Card>
    </Page>
  );
};

export default UtilitiesPage;
