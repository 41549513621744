import React from "react";
import { Field } from "react-final-form";
import styled from "styled-components";

import { DatePicker, Dropdown, TextField } from "src/components/Form";

const PERIODS = [
  { text: "Quarterly", value: "quarterly" },
  { text: "Annually", value: "annually" },
  { text: "Semiannually", value: "semiannually" },
  { text: "Biennially", value: "biennially" },
  { text: "Triennially", value: "triennially" },
];

export const NewSubmissionPeriod: React.FC = () => {
  return (
    <Container>
      <Field
        component={TextField}
        help="Names should have the format of Q&#123;N&#125; - YYYY or YYYY"
        label="Name"
        name="submissionPeriod.name"
      />
      <Field
        component={Dropdown}
        label="Period"
        name="submissionPeriod.period"
        options={PERIODS}
      />
      <Field
        component={DatePicker}
        label="Start Date"
        name="submissionPeriod.startDate"
      />
      <Field
        component={DatePicker}
        label="Due Date"
        name="submissionPeriod.dueDate"
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    & > p {
      right: 74px;
    }
    & > div {
      flex-direction: row !important;
      & > label {
        flex: 1;
        text-align: start;
      }
      & > input,
      & > select {
        flex: 2;
      }
    }
  }
`;
