import { FlatButton, IGNIS_RED } from "@120wateraudit/waterworks";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { Popup } from "semantic-ui-react";

import { useCurrentUser } from ".";

interface Props {
  className?: string;
  style?: CSSProperties;
}

const ProfileBadge: React.FC<Props> = ({ className, style }) => {
  const { logout, user } = useCurrentUser();
  const { firstName, lastName } = user ?? {};

  return (
    <Popup
      style={{ padding: "0px" }}
      on="click"
      offset={[15]}
      position="bottom right"
      trigger={
        <AvatarButton className={className} style={style}>
          {firstName ?? ""} {lastName ?? ""}
        </AvatarButton>
      }
    >
      <PopupButton borderless onClick={logout}>
        <FontAwesomeIcon
          icon={solid("person-walking-dashed-line-arrow-right")}
        />
        Logout
      </PopupButton>
    </Popup>
  );
};

const AvatarButton = styled(FlatButton)`
  display: flex;
  border-radius: 8px;

  &:hover {
    background-color: transparent !important;
    box-shadow: 0 0 0 3px #0891b2;
  }

  & > img {
    width: 44px;
    height: 44px;
    border-radius: 8px;
  }
`;

const PopupButton = styled(FlatButton)`
  min-width: 150px;
  height: 44px;
  display: flex;
  & svg:first-child {
    margin-right: 12px;
  }

  &:hover {
    background-color: transparent !important;
    color: ${IGNIS_RED};
  }
`;

export default ProfileBadge;
