import React, { useState, useMemo } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import { Card } from "@120wateraudit/waterworks";
import styled from "styled-components";

import Row from "src/components/Row";
import Column from "src/components/Column";
import StyledDetail from "src/components/StyledDetail";
import { formatDate } from "src/utils/format";
import VerificationMethod from "src/types/VerificationMethod";
import MaterialBadge from "src/modules/Inventory/MaterialBadge";
import { YesNoUnknown } from "src/types/enums/YesNoUnknown";

type Method = keyof typeof VerificationMethod;

interface Props {
  classificationBasis: string;
  notes: string;
  depth: string;
  diameter: string;
  installDate: string;
  material: string;
  leadSolderLeadLined: string;
  removalDate: string;
  verificationDate: string;
  verificationMethod: Method;
  verifiedBy: string;
  previouslyLead?: string;
  classificationBasisNotes: string;
  installedAfterLeadBan: YesNoUnknown;
  materialOtherNotes: string;
  diameterOverTwoInches: boolean;
  verified: boolean;
  verificationOtherNotes: string;
  type: "private" | "public";
}

const MaterialAccordion: React.FC<Props> = ({
  classificationBasis,
  notes,
  depth,
  diameter,
  installDate,
  material,
  leadSolderLeadLined,
  removalDate,
  verificationDate,
  verificationMethod,
  verifiedBy,
  previouslyLead,
  classificationBasisNotes,
  installedAfterLeadBan,
  materialOtherNotes,
  diameterOverTwoInches,
  verified,
  verificationOtherNotes,
  type,
}) => {
  const [isSectionOpen, setIsSectionOpen] = useState<boolean>(true);
  const [isMaterialOpen, setIsMaterialOpen] = useState<boolean>(true);
  const [isInstallationOpen, setIsInstallationOpen] = useState<boolean>(true);
  const [isVerificationOpen, setIsVerificationOpen] = useState<boolean>(true);
  const [isOtherOpen, setIsOtherOpen] = useState<boolean>(true);

  const closeAll = (): void => {
    setIsMaterialOpen(false);
    setIsInstallationOpen(false);
    setIsVerificationOpen(false);
    setIsOtherOpen(false);
  };

  const diameterDisplay = useMemo(() => {
    if (diameter && diameter !== "Unknown") {
      return diameter;
    } else if (diameterOverTwoInches) {
      return "Greater Than 2 Inches";
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    } else if (diameterOverTwoInches === false) {
      return "Less Than 2 Inches";
    }

    return "Unknown";
  }, [diameter, diameterOverTwoInches]);

  return (
    <StyledCard>
      <Accordion>
        <Accordion.Title
          active={isSectionOpen}
          onClick={() => {
            setIsSectionOpen(!isSectionOpen);
            closeAll();
          }}
          style={{
            fontSize: "1.07rem",
            fontWeight: "700",
          }}
        >
          <Icon name="dropdown" />
          {type === "public" ? "System-Owned" : "Customer-Owned"}
        </Accordion.Title>
        <Accordion.Content active={isSectionOpen}>
          <StyledCard style={{ padding: "0px", marginBottom: ".5rem" }}>
            <Accordion.Title
              active={isMaterialOpen}
              onClick={() => setIsMaterialOpen(!isMaterialOpen)}
              style={{ fontSize: "1.07rem", fontWeight: "700" }}
            >
              <Icon name="dropdown" />
              Material
            </Accordion.Title>
            <Accordion.Content active={isMaterialOpen}>
              <StyledCard style={{ border: "none" }}>
                <Row style={{ gap: "1rem", alignItems: "flex-start" }}>
                  <Column>
                    <Detail label="Material">
                      <MaterialBadge
                        material={material || "--"}
                        inverse={true}
                      />
                    </Detail>
                    {type === "public" && (
                      <Detail label="Previously Lead">
                        {previouslyLead ?? "--"}
                      </Detail>
                    )}
                    <Detail label="Classification Basis">
                      {classificationBasis || "--"}
                    </Detail>
                  </Column>
                  <Column>
                    {(material === "Galvanized Iron" ||
                      material === "Copper") && (
                      <Detail label="Lead Solder / Lead Lined">
                        {leadSolderLeadLined || "--"}
                      </Detail>
                    )}
                    <Detail label="Classification Basis Notes">
                      {classificationBasisNotes || "--"}
                    </Detail>
                  </Column>
                  <Column>
                    {material === "Other" && (
                      <Detail label="Other - Notes">
                        {materialOtherNotes || "--"}
                      </Detail>
                    )}
                  </Column>
                </Row>
              </StyledCard>
            </Accordion.Content>
          </StyledCard>

          <StyledCard style={{ padding: "0px", marginBottom: ".5rem" }}>
            <Accordion.Title
              active={isInstallationOpen}
              onClick={() => setIsInstallationOpen(!isInstallationOpen)}
              style={{ fontSize: "1.07rem", fontWeight: "700" }}
            >
              <Icon name="dropdown" />
              Installation Details
            </Accordion.Title>
            <Accordion.Content active={isInstallationOpen}>
              <StyledCard style={{ border: "none" }}>
                <Row style={{ gap: "1rem", alignItems: "flex-start" }}>
                  <Column>
                    <Detail label="Installed After Lead Ban?">
                      {YesNoUnknown[installedAfterLeadBan] || "--"}
                    </Detail>
                    <Detail label="Diameter (in)">
                      <div style={{ display: "flex", gap: ".5rem" }}>
                        {diameterDisplay}
                      </div>
                    </Detail>
                  </Column>
                  <Column>
                    <Detail label="Installation Date">
                      {formatDate(installDate) || "--"}
                    </Detail>
                    <Detail label="Depth (in)">{depth || "--"}</Detail>
                  </Column>
                  <Column>
                    <Detail label="Removal Date">
                      {formatDate(removalDate) || "--"}
                    </Detail>
                  </Column>
                </Row>
              </StyledCard>
            </Accordion.Content>
          </StyledCard>

          {verified && (
            <>
              <StyledCard style={{ padding: "0px", marginBottom: ".5rem" }}>
                <Accordion.Title
                  active={isVerificationOpen}
                  onClick={() => setIsVerificationOpen(!isVerificationOpen)}
                  style={{ fontSize: "1.07rem", fontWeight: "700" }}
                >
                  <Icon name="dropdown" />
                  Verification
                </Accordion.Title>
                <Accordion.Content active={isVerificationOpen}>
                  <StyledCard style={{ border: "none" }}>
                    <Row style={{ gap: "1rem", alignItems: "flex-start" }}>
                      <Column>
                        <Detail label="Verification Method">
                          {verificationMethod || "--"}
                        </Detail>
                        <Detail label="Verification Date">
                          {formatDate(verificationDate) || "--"}
                        </Detail>
                      </Column>
                      <Column>
                        {verificationMethod === "Other" && (
                          <Detail label="Other Notes">
                            {verificationOtherNotes || "--"}
                          </Detail>
                        )}
                        <Detail label="Verified By">
                          {verifiedBy || "--"}
                        </Detail>
                      </Column>
                      <Column />
                    </Row>
                  </StyledCard>
                </Accordion.Content>
              </StyledCard>
            </>
          )}

          <StyledCard style={{ padding: "0px", marginBottom: ".5rem" }}>
            <Accordion.Title
              active={isOtherOpen}
              onClick={() => setIsOtherOpen(!isOtherOpen)}
              style={{ fontSize: "1.07rem", fontWeight: "700" }}
            >
              <Icon name="dropdown" />
              Other
            </Accordion.Title>
            <Accordion.Content active={isOtherOpen}>
              <StyledCard style={{ border: "none" }}>
                <Row>
                  <Column>
                    <Detail label="Notes">{notes || "--"}</Detail>
                  </Column>
                </Row>
              </StyledCard>
            </Accordion.Content>
          </StyledCard>
        </Accordion.Content>
      </Accordion>
    </StyledCard>
  );
};

export default MaterialAccordion;

const StyledCard = styled(Card)`
  box-shadow: none;
  border: 2px solid rgb(246, 247, 247);
  margin-bottom: 0.5rem;
  padding: 8px 8px;
`;

const Detail = styled(StyledDetail)`
  margin-bottom: 1.5rem;
`;
