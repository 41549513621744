import { AQUA_BLUE } from '@120wateraudit/waterworks';
import React, { useEffect } from 'react';
import { Button, Checkbox, Divider, Form } from 'semantic-ui-react';
import styled from "styled-components";
import { Field, FieldRenderProps, Form as FinalForm } from "react-final-form";
import { TextField } from '../Form';
import { SAVED_VIEW_OWNER_TYPE } from './constants';
import { useCurrentUser } from 'src/modules/User';
import { useCreateSavedViewMutation, useGetCurrentPrimacyAgencyQuery } from 'src/services';
import { toastError, toastSuccess } from 'src/utils/toast';

interface CreateModalProps {
  view: Record<string, unknown>,
  tableName: string,
  close: () => void,
  setViewId: (id: number) => void,
}

const ModalContentContainer = styled.div`
  display: flex;
  margin: 0;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 48px;
`;

const DividerContainer = styled.div`
  width: 100%;
`

const ModalHeader = styled.h3`
  padding: 16px;
  width: 100%;
  text-align: left;
  padding-bottom: 0;
  margin-bottom: 0px;
`

const ContentHeader = styled.h4`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 0;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const SaveButton = styled(Button)`
  &&& {
    background-color: ${AQUA_BLUE};
    color: white;
    font-size: 16px;
    border-radius: 8px;
  }
`

export const CreateModal: React.FC<CreateModalProps> = ({ view, tableName, close, setViewId }) => {
  const { user } = useCurrentUser();
  const [createView, { data }] = useCreateSavedViewMutation();
  const { data: primacyAgency, isLoading: isPrimacyAgencyLoading } = useGetCurrentPrimacyAgencyQuery(undefined);

  useEffect(() => {
    if (data?.id) {
      setViewId(data.id);
    }
  }, [data]);

  const onSubmit = async (data: { name: string, isTeamView: boolean }): Promise<void> => {
    const ownerType = data.isTeamView ? SAVED_VIEW_OWNER_TYPE.PrimacyAgency : SAVED_VIEW_OWNER_TYPE.User;
    const ownerId = data.isTeamView ? primacyAgency?.id : user?.id;

    try {
      await createView({
        name: data.name,
        tableName,
        view,
        ownerType,
        ownerId,
      }).unwrap();
      toastSuccess(`Successfully saved "${data.name}"`);
      close();
    } catch (e: any) {
      if (e.data.message) {
        toastError(e.data.message);
      } else {
        toastError(`Error saving "${data.name}"`);
      }
    }
  }

  return (
    <ModalContentContainer>
      <ModalHeader>Saved Views</ModalHeader>
      <DividerContainer>
        <Divider />
      </DividerContainer>
      <ContentHeader>Create a name for your new view</ContentHeader>
      <FinalForm
        initialValues={{ name: '', isTeamView: false }}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <Form
            onSubmit={() => {
              void handleSubmit();
            }}
          >
            <Field<string> component={TextField} name="name" placeholder="Saved View #1311" style={{ width: 364 }} />
            <Field<boolean> name="isTeamView">
            {({ input }: FieldRenderProps<boolean>) => {
                const val = input.value ? "true" : "false";
                return (
                  <div
                    style={{
                      width: '100%',
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 20,
                      marginBottom: 32,
                    }}
                  >
                    <Checkbox
                      defaultChecked={input.value}
                      value={val}
                      label="Save as a Team View"
                      onChange={() => {
                        input.onChange(!input.value);
                      }}
                    />
                  </div>
                );
              }}
            </Field>
            <ButtonContainer>
              <SaveButton disabled={submitting || isPrimacyAgencyLoading} type="submit">Create New View</SaveButton>
            </ButtonContainer>
          </Form>
        )}
      >

      </FinalForm>
    </ModalContentContainer>
  )
}
