import { useNavigate } from "react-router-dom";

export const useGoBack = (fallbackRoute: string): (() => void) => {
  const browserHistoryLength: number = history.length;
  const navigate = useNavigate();

  const goBack: () => void = () => {
    if (browserHistoryLength >= 3) {
      navigate(-1);
    } else {
      navigate(fallbackRoute, { replace: true });
    }
  };

  return goBack;
};

export default useGoBack;
