import { Card, FlatButton } from "@120wateraudit/waterworks";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";

import Loader from "src/components/Loader";
import { useDeleteContactMutation } from "src/services";
import { Contact } from "src/types/Contact";
import NoContact from "./NoContact";
import ContactData from "./ContactData";

interface Props {
  className?: string;
  contacts: Contact[];
  style?: React.CSSProperties;
}

const ContactCard: React.FC<Props> = ({ className, contacts, style }) => {
  const [deleteContact, { isLoading: isDeleting }] = useDeleteContactMutation();
  const onDelete = async (contact: Contact): Promise<void> =>
    await deleteContact({
      contactId: contact.id,
      systemId: contact.pwsId,
    }).unwrap();

  const noContacts = contacts.length === 0;

  if (noContacts || isDeleting) {
    return (
      <Card style={style}>
        <ContactHeader>Contact</ContactHeader>
        {noContacts && <NoContact />}
        {isDeleting && <Loader />}
      </Card>
    );
  }

  return (
    <Card style={style} className={className}>
      <CarouselHeader>Contact</CarouselHeader>
      <Carousel
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        renderArrowPrev={(onClickHandler, hasPrev) =>
          hasPrev && (
            <LeftChevron onClick={onClickHandler}>
              <FontAwesomeIcon icon={solid("chevron-left")} size="sm" />
            </LeftChevron>
          )
        }
        renderArrowNext={(onClickHandler, hasNext) =>
          hasNext && (
            <RightChevron onClick={onClickHandler}>
              <FontAwesomeIcon icon={solid("chevron-right")} size="sm" />
            </RightChevron>
          )
        }
      >
        {contacts.map((contact) => {
          return (
            <ContactData
              contact={contact}
              key={contact.id}
              onDelete={onDelete}
            />
          );
        })}
      </Carousel>
    </Card>
  );
};

const ContactHeader = styled.h1`
  margin-top: 0px;
  border-bottom: 2px solid black;
`;

const RightChevron = styled(FlatButton)`
  border-radius: 8px;
  position: absolute;
  top: -60px;
  right: 1%;
  z-index: 2;
  padding-left: 10px;
  padding-right: 10px;
`;

const LeftChevron = styled(FlatButton)`
  border-radius: 8px;
  position: absolute;
  top: -60px;
  right: 40px;
  z-index: 2;
  padding-left: 10px;
  padding-right: 10px;
`;

const CarouselHeader = styled.h1`
  margin-top: 0px;
  border-bottom: 2px solid black;

  &&& {
    text-align: left;
    padding-bottom: 10px;
  }
`;

export default ContactCard;
