import { Button } from "@120wateraudit/waterworks";
import { ValidationErrors } from "final-form";
import pluralize from "pluralize";
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

import Error from "src/components/Error";
import { isApiError } from "src/utils/apiError";
import Row from "src/components/Row";
import {
  useGetAllSubmissionPeriodsQuery,
  useRemoveSystemsFromSubmissionPeriodMutation,
} from "src/services";
import { toastError, toastSuccess } from "src/utils/toast";
import { FormContent } from "./FormContent";
import LoadingIndicatorPage from "src/components/LoadingIndicatorPage";

interface Values {
  submissionPeriodId?: number;
}

const validate = (values: Values): ValidationErrors => {
  const errors: ValidationErrors = {};
  if (!values.submissionPeriodId) {
    errors.submissionPeriodId = "A Submission Period is required";
  }

  return errors;
};

interface SubmissionModalProps {
  selectedIds: number[];
  warningCount: number;
  closeModal: () => void;
}

const RemoveSubmissionPeriodModal = ({
  closeModal,
  selectedIds,
  warningCount,
}: SubmissionModalProps): JSX.Element => {
  const { data: queryData, isFetching } = useGetAllSubmissionPeriodsQuery({
    filters: { active: true, locked: false },
    page: 1,
    pageSize: 100,
  });
  const submissionPeriods = queryData?.data ?? [];
  const firstSubmissionPeriodId = submissionPeriods[0]?.id;

  const initialValues: Values = useMemo(
    () => ({
      submissionPeriodId: firstSubmissionPeriodId ?? undefined,
    }),
    [firstSubmissionPeriodId]
  );

  const [removeSystems, { error, isError, isLoading }] =
    useRemoveSystemsFromSubmissionPeriodMutation();

  console.log(error, isError);
  const submit = async (values: Values): Promise<void> => {
    const { submissionPeriodId } = values ?? {};

    try {
      if (!submissionPeriodId) {
        return;
      }
      const { data } = await removeSystems({
        submissionPeriodId,
        systemIds: selectedIds,
      }).unwrap();
      if (data.systemsNotUpdated.length > 0) {
        data.systemsNotUpdated.forEach((system) => {
          toastError(system);
        });
      }
      if (data.updatedSystems.length > 0) {
        toastSuccess(
          `Your Submission Period was removed from ${pluralize(
            "Utilities",
            warningCount,
            true
          )}.`
        );
      }

      closeModal();
    } catch {
      // TODO better error message
      toastError("Submission Period could not be removed. Please try again.");
    }
  };

  const errors = useMemo(() => {
    if (!error || !isError) {
      return undefined;
    }

    if (isApiError(error)) {
      return [error.data?.error];
    }

    return ["Please try again or contact support@120water.com"];
  }, [error, isError]);

  if (isFetching || isLoading) {
    return <LoadingIndicatorPage />;
  }

  return (
    <ModalContentContainer>
      <Form
        initialValues={initialValues}
        onSubmit={submit}
        render={({ handleSubmit, submitting, valid }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                void handleSubmit();
              }}
            >
              <FormContentContainer>
                <h1 style={{ marginBottom: "20px" }}>
                  {`Remove Submission Period from ${pluralize(
                    "Utilities",
                    warningCount,
                    true
                  )}`}
                </h1>
                {submissionPeriods.length === 0 && (
                  <h3>No Submission Periods to remove from currently.</h3>
                )}
                {submissionPeriods.length > 0 && (
                  <FormContent
                    submissionPeriods={submissionPeriods}
                    warningCount={warningCount}
                  />
                )}
              </FormContentContainer>
              <Row
                style={{
                  gap: "8px",
                  justifyContent: "flex-end",
                  marginRight: "1.5em",
                }}
              >
                <Button onClick={closeModal}>Cancel</Button>
                <Button
                  disabled={submitting || isLoading || !valid}
                  type="submit"
                  variant="error"
                >
                  Remove
                </Button>
              </Row>
              {errors && (
                <Error style={{ margin: "12px 24px" }} messages={errors} />
              )}
            </form>
          );
        }}
        validate={validate}
      />
    </ModalContentContainer>
  );
};

const ModalContentContainer = styled.div`
  margin: 2rem;
`;

const FormContentContainer = styled.div`
  text-align: center;
  margin: 1.5rem;
`;

export default RemoveSubmissionPeriodModal;
