import numeral from "numeral";

export const titleCase = (str: string): string => {
  const spaced = str.replace(/([A-Z])/g, " $1");
  return spaced.charAt(0).toUpperCase() + spaced.slice(1);
};

interface Addressable {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  line1?: string;
  line2?: string;
  postalCode?: string;
  state?: string;
}

export const formatOneLineAddress = (address: Addressable): string => {
  if (!address.line1 && !address.addressLine1) {
    return "";
  }

  let addressString = (address.line1 ?? address.addressLine1) as string;
  if (address.line2) {
    addressString = `${addressString} ${address.line2}`;
  }

  if (address.addressLine2) {
    addressString = `${addressString} ${address.addressLine2}`;
  }

  if (!address.city) {
    // Bail
    return addressString;
  }

  addressString = `${addressString}, ${address.city}`;
  if (!address.state) {
    // Get out of here!
    return addressString;
  }

  addressString = `${addressString} ${address.state}`;
  if (!address.postalCode) {
    // Really, you didn't include this?
    return addressString;
  }

  return `${addressString} ${address.postalCode}`;
};

const TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit",
  timeZoneName: "short",
};

const DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};

const FULL_OPTIONS: Intl.DateTimeFormatOptions = {
  ...TIME_OPTIONS,
  ...DATE_OPTIONS,
};

const SHORT_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "short",
  year: "numeric",
};

// /**
//  * These formatters can be re-used to format any date or time (or both) into
//  * a format appropriate for our platform.
//  *
//  * datetimeFormatter.format(new Date())
//  * datetimeFormatter.format(moment().toDate())
//  */
export const datetimeFormatter = new Intl.DateTimeFormat("en-US", FULL_OPTIONS);
export const dateFormatter = new Intl.DateTimeFormat("en-US", DATE_OPTIONS);
export const shortDateFormatter = new Intl.DateTimeFormat(
  "en-US",
  SHORT_DATE_OPTIONS
);
export const timeFormatter = new Intl.DateTimeFormat("en-US", TIME_OPTIONS);

export const parseDateString = (dateStr?: null | string): Date =>
  dateStr ? new Date(dateStr) : new Date();

export const formatDateTime = (dateStr?: null | string): string =>
  dateStr ? datetimeFormatter.format(new Date(dateStr)) : "--";

export const formatDate = (dateStr?: null | string): string =>
  dateStr ? dateFormatter.format(new Date(dateStr)) : "--";

export const formatTime = (dateStr?: null | string): string =>
  dateStr ? timeFormatter.format(new Date(dateStr)) : "--";

export const formatShortDate = (dateStr?: null | string): string => {
  return dateStr ? shortDateFormatter.format(new Date(dateStr)) : "--";
};

export const formatPercent = (value: number): string =>
  numeral(value).format("0.00%");

export const formatNumber = (value?: number): string =>
  value ? numeral(value).format("0,0") : "--";

export const formatYesNo = (value?: null | boolean): string => {
  return value ? "Yes" : value === false ? "No" : "--";
};
