/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { SearchParams, Tag } from "src/services";
import baseQuery from "./baseQuery";
import buildParameters from "./utils";
import saveAs from "file-saver";

interface GetAllExportsParams {
  searchParams: SearchParams;
}

interface ExportRequest {
  params: SearchParams;
  submissionPeriodId: number;
}

interface ExportDownloadRequest {
  id: number,
  name: string
}

export enum ExportStatus {
  Failed = "Failed",
  GatheringData = "GatheringData",
  CreatingFile = "CreatingFile",
  Completed = "Completed",
  Canceled = "Cancelled",
}

export enum ExportType {
  OneSchema = "oneschema",
  CommunicationsInstance = "communications-instance",
  submission = "submission",
}

export interface NewExport extends Record<string, unknown> {
  id: number;
  accountId: number;
  legacyId: number;
  filters: Record<string, unknown>;
  metaData: Record<string, string>;
  status: ExportStatus;
  errors: string[];
  type: ExportType;
  createdBy: string;
  createdOn: string;
  createdByUsername: string;
  modifiedBy: string;
  modifiedOn: Date;
  fileName: string;
  rowCount: number;
}

export const exportServiceApi = createApi({
  baseQuery: baseQuery({
    baseUrl: `/platform/exports`,
  }),
  reducerPath: "exportServiceApi",
  tagTypes: [Tag.Exports],
  endpoints: (builder) => ({
    getAllExports: builder.query<NewExport[], GetAllExportsParams>({
      providesTags: [Tag.Exports],
      query: ({ searchParams }) => {
        const params = buildParameters(searchParams).toString();

        return {
          method: "GET",
          url: `/?${params}`,
        };
      },
    }),
    downloadExport: builder.query<
    void,
    ExportDownloadRequest
  >({
    queryFn: async (data, _queryApi, _extraOptions, fetchWithBQ) => {
      const { id, name } = data;
      const url = `/${id}`;

      try {
        const response = await fetchWithBQ({
          url,
          method: "GET",
          responseHandler: async (response: { blob: () => any }) => {
            const blob = await response.blob();
            saveAs(blob, name);
            return { data: undefined };
          },
        });

        if (response.error) {
          return { error: response.error };
        }

        return { data: undefined };
      } catch (error: any) {
        return { error: { status: "FETCH_ERROR", error: error.message } };
      }
    },
  }),
    createPWSSubmissionPeriodExport: builder.mutation<NewExport, ExportRequest>(
      {
        invalidatesTags: [Tag.Exports],
        query: ({ params, submissionPeriodId }) => {
          return {
            // TODO: Search Terms, etc
            body: { filters: params.filters, searchTerm: params.searchTerm },
            method: "POST",
            url: `/pws-submission-period/${submissionPeriodId}`,
          };
        },
      }
    ),
  }),
});

export const {
  useGetAllExportsQuery,
  useLazyDownloadExportQuery,
  useCreatePWSSubmissionPeriodExportMutation,
} = exportServiceApi;
