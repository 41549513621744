import { Button } from "@120wateraudit/waterworks";
import { ValidationErrors } from "final-form";
import React, { useCallback, useMemo } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { Form } from "semantic-ui-react";

import styled from "styled-components";

import Error from "src/components/Error";
import { isApiError } from "src/utils/apiError";
import { toastError, toastSuccess } from "src/utils/toast";
import LoadingIndicatorPage from "src/components/LoadingIndicatorPage";
import {
  useAddUpdateTerritoryMutation,
  useGetAllTerritoriesQuery,
} from "src/services";
import TextField from "src/components/Form/TextField";

const validate = (): ValidationErrors => {
  const errors: ValidationErrors = {};

  return errors;
};

interface SubmissionModalProps {
  selectedIds: number[];
  warningCount: number;
  onClose: () => void;
  updateId?: number;
  updateName?: string;
}

interface onSubmitProps {
  name: string;
}

const AddTerritoryModal = ({
  onClose,
  updateId,
  updateName,
}: SubmissionModalProps): JSX.Element => {
  const [addTerritory, { error, isError, isLoading }] =
    useAddUpdateTerritoryMutation();

  const { refetch } = useGetAllTerritoriesQuery({});

  const onSubmit = useCallback(
    async ({ name }: onSubmitProps) => {
      try {
        if (!name) {
          return;
        }
        await addTerritory({
          name,
          updateId,
        }).unwrap();
        toastSuccess("Your changes have been saved");

        await onClose();
        await refetch();
      } catch {
        toastError("Could not create territory. Please try again.");
      }
    },
    [addTerritory]
  );

  const errors = useMemo(() => {
    if (!error || !isError) {
      return undefined;
    }

    if (isApiError(error)) {
      return [error.data?.error];
    }

    if (error !== null && typeof error === 'object' && 'data' in error) {
      const data = error.data
      if (data !== null && typeof data === 'object' && 'consumerErrorMessage' in data) {
        const errorMessage = data.consumerErrorMessage as string
        if (errorMessage.includes('BadRequestException')) {
          return [errorMessage.replace('BadRequestException: ', '')]
        }
      }
    }

    return ["Please try again or contact support@120water.com"];
  }, [error, isError]);

  if (isLoading) {
    return <LoadingIndicatorPage />;
  }

  return (
    <ModalContentContainer>
      <FinalForm
        onSubmit={onSubmit}
        render={({ valid, handleSubmit, submitting }) => {
          return (
            <div style={{ width: "95%" }}>
              <FormContentContainer
                onSubmit={() => {
                  void handleSubmit();
                }}
              >
                <h2>Add New Territory</h2>

                <Field<string>
                  error={[]}
                  component={TextField}
                  name="name"
                  label={"Territory Name"}
                  placeholder="enter territory name"
                />
                <ButtonsWrapper>
                  <Button
                    type="submit"
                    disabled={submitting || isLoading || !valid}
                    variant="primary"
                  >
                    Add
                  </Button>
                  <Button onClick={onClose}>Cancel</Button>
                </ButtonsWrapper>
              </FormContentContainer>

              {errors && (
                <Error style={{ margin: "12px 24px" }} messages={errors} />
              )}
            </div>
          );
        }}
        validate={validate}
      />
    </ModalContentContainer>
  );
};

const ModalContentContainer = styled.div`
  display: flex;
  margin: 0 2rem;
`;

const FormContentContainer = styled(Form)`
  margin: 1.5rem 1rem;

  label {
    font-weight: bold;
    padding-bottom: 10px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin: 1.5rem 1rem;
  display: flex;
  width: 75%;
  justify-content: space-between;
  button {
    width: 70%;
    margin-right: 15px;
  }
`;

export default AddTerritoryModal;
