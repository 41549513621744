import { FlatButton } from "@120wateraudit/waterworks";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import useGoBack from "src/hooks/useGoBack";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  fallbackRoute?: string;
}

const BackButton: React.FC<Props> = ({
  className,
  fallbackRoute = "/",
  style,
}) => {
  const goBack = useGoBack(fallbackRoute);

  return (
    <FlatButton borderless className={className} onClick={goBack} style={style}>
      <FontAwesomeIcon icon={solid("chevron-left")} size="2x" />
    </FlatButton>
  );
};

export default BackButton;
