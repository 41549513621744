import React from "react";
import { Comment } from "semantic-ui-react";
import { Badge, AQUA_BLUE, TERRA_GREEN } from "@120wateraudit/waterworks";
import { Link } from "react-router-dom";
import { Activity } from "src/types/Activity";
import { formatDateTime } from "src/utils/format";
import { StatusBadge } from "src/modules/Submissions/StatusBadge";

interface Props {
  activities: Activity[];
  activityFilter: string;
  onClose: () => void;
}

const CommentSection: React.FC<Props> = ({
  activities,
  activityFilter,
  onClose,
}) => {
  return (
    <Comment.Group
      style={{ maxWidth: "100%", overflowY: "auto", height: "50vh" }}
    >
      {activities
        .filter((a) =>
          activityFilter === "all" ? true : a.type === activityFilter
        )
        .reverse()
        .map((activity) => {
          const capitalizedFirstLetter: string = activity.type
            .charAt(0)
            .toUpperCase();
          const remainingWord: string = activity.type.slice(1);
          const activityName: string = `${capitalizedFirstLetter}${remainingWord}`;

          return (
            <Comment key={activity.id}>
              <Comment.Content>
                <Comment.Author>{activity.submittedByName}</Comment.Author>
                <Comment.Metadata style={{ marginLeft: "0px" }}>
                  <div style={{ color: "black" }}>
                    {formatDateTime(activity.timestamp)}
                  </div>
                  <div>
                    <Badge
                      color={"white"}
                      backgroundColor={
                        activity.type === "notification"
                          ? AQUA_BLUE
                          : TERRA_GREEN
                      }
                    >
                      {activityName}
                    </Badge>
                  </div>
                  <div>
                    <Badge
                      color={"white"}
                      backgroundColor={
                        activity.originType === "pws" ? AQUA_BLUE : TERRA_GREEN
                      }
                    >
                      {activity.originName}
                    </Badge>
                  </div>
                  <div>
                    <StatusBadge
                      style={{
                        color:
                          activity.submissionStatus === "voided"
                            ? "white"
                            : "black",
                      }}
                      status={activity.submissionStatus}
                    />
                  </div>
                </Comment.Metadata>
                <Comment.Text>
                  <div style={{ fontWeight: "bold" }}>{activity.comment}</div>
                </Comment.Text>
                <Comment.Actions>
                  <Link
                    onClick={onClose}
                    to={`/submissions/${activity.submissionId}`}
                  >
                    Go to Submission
                  </Link>
                </Comment.Actions>
              </Comment.Content>
            </Comment>
          );
        })}
    </Comment.Group>
  );
};

export default CommentSection;
