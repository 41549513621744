import { Dropdown as WWDropdown } from "@120wateraudit/waterworks";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAddBulkSystemsTerritoriesMutation } from "src/services";
import Territory from "src/types/Territory";

type DropdownOptions = React.ComponentProps<typeof WWDropdown>["options"];

const mapOptionsForTerritories = (
  territories: Territory[]
): DropdownOptions => {
  const optionItems: DropdownOptions = [
    ...territories.map(({ name }) => ({
      text: name,
      value: name,
    })),
  ];

  return optionItems;
};

interface Props {
  territories: Territory[];
  currentTerritory: Territory | undefined;
  systemId: string;
  refetchSystemDetails: () => any;
}

export const TerritoryChangeDropdown: React.FC<Props> = ({
  territories,
  currentTerritory: initialTerritory,
  systemId,
  refetchSystemDetails,
}) => {
  const [updateTerritory] = useAddBulkSystemsTerritoriesMutation();
  const [currentTerritory, setCurrentTerritory] = useState<
    Territory | undefined
  >(initialTerritory);
  const [territoriesOptions, setTerritoriesOptions] = useState(
    mapOptionsForTerritories(territories)
  );

  const handleChange = async (
    event: React.ChangeEvent<HTMLSelectElement>,
    territories: Territory[],
    systemId: number,
    updateTerritory: any,
    setCurrentTerritory: React.Dispatch<
      React.SetStateAction<Territory | undefined>
    >
  ): Promise<void> => {
    const selectedTerritory = territories.find(
      (territory) => territory.name === event.target.value
    );

    if (selectedTerritory) {
      await updateTerritory({
        systemIds: [systemId],
        territoryId: selectedTerritory.id,
      }).unwrap();
      setCurrentTerritory(selectedTerritory);
      await refetchSystemDetails();
    }
  };

  useEffect(() => {
    if (!currentTerritory) {
      setTerritoriesOptions([
        { text: "Select a territory", value: "none" },
        ...mapOptionsForTerritories(territories),
      ]);
    } else {
      setTerritoriesOptions(mapOptionsForTerritories(territories));
    }
  }, [currentTerritory, territories]);

  useEffect(() => {
    if (!currentTerritory) {
      setTerritoriesOptions([
        { text: "Select a territory", value: "none" },
        ...mapOptionsForTerritories(territories),
      ]);
    } else {
      setTerritoriesOptions(mapOptionsForTerritories(territories));
    }
  }, [currentTerritory, territories]);

  return (
    <Dropdown
      onChange={(event) => {
        void handleChange(
          event,
          territories,
          Number(systemId),
          updateTerritory,
          setCurrentTerritory
        );
      }}
      options={territoriesOptions}
      value={currentTerritory?.name ?? "none"}
      style={{ alignItems: "baseline", paddingTop: "5px" }}
    />
  );
};

const Dropdown = styled(WWDropdown)`
  select {
    border-radius: 0.4rem;
    border: none;
    height: 2.25rem;
  }
`;
