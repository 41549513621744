import React from "react";
import {
  BasicContainer,
  ParagraphInputGrid,
  QuestionTitle,
  Value,
} from "../shared";
import styled from "styled-components";

interface Props {
  additionalInfo: { [key: string]: string };
}
const InventoryInformation: React.FC<Props> = ({ additionalInfo }) => {
  const {
    amountSpentOnInventorySubmission,
    initialInventoryOrInventoryUpdate,
    whoOwnsTheServiceLines,
    isThereDocumentationThatDefinesOwnership,
  } = additionalInfo;

  return (
    <BasicContainer>
      <StyledInputGrid>
        <QuestionTitle>
          1. Is this the Initial Inventory or an Inventory Update?
        </QuestionTitle>
        <Value>{initialInventoryOrInventoryUpdate ?? "--"}</Value>
      </StyledInputGrid>
      <StyledInputGrid>
        <QuestionTitle>
          2a. Who owns the service lines in your system? If other, please
          explain below.
        </QuestionTitle>
        <Value>{whoOwnsTheServiceLines ?? "--"}</Value>
      </StyledInputGrid>
      <StyledInputGrid>
        <QuestionTitle>
          2b. Is there documentation that defines service line ownership in your
          system, such as a local ordinance? If yes, please describe below and
          explain where ownership is split (e.g., property line, curb stop).
        </QuestionTitle>
        <Value>{isThereDocumentationThatDefinesOwnership ?? "--"}</Value>
      </StyledInputGrid>
      <ParagraphInputGrid>
        <QuestionTitle>
          3. How much was spent on this year’s inventory submission?
        </QuestionTitle>
        <Value>{amountSpentOnInventorySubmission ?? "--"}</Value>
      </ParagraphInputGrid>
    </BasicContainer>
  );
};

const StyledInputGrid = styled(ParagraphInputGrid)`
  margin-bottom: 16px;
`;

export default InventoryInformation;
