import React, { useMemo } from "react";
import { DARK_BLUE } from "@120wateraudit/waterworks";
import { COLOR_MAP, LABEL_MAP, MaterialType } from "./dashboardConstants";
import { dataToPercentages } from "src/utils/dashboardUtils";
import { MaterialCircle } from "src/components/Icons/MaterialCircle";
import { useGetMaterialTypeBreakdownMetricQuery } from "src/services/reporting";
import SubmissionPeriod from "src/types/SubmissionPeriod";
import styled from "styled-components";
import Loader from "src/components/Loader";
import { useMinLoadTime } from "src/hooks/useMinLoadTime";
import { useTooltip } from "src/hooks/useTooltip";

const BAR_HEIGHT = 36;

const GreyBorderContainer = styled.div`
  border: 1px solid lightgray;
  border-radius: 5px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  gap: 10px;
  padding: 20px;
`;

const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  grid-row: 1;
  grid-column: 2;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  grid-column: 1;
`;

const BarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  gap: 5px;
  width: 100%;
  grid-column: 2;
`;

const Bar = styled.div<{ value: number; color?: string }>`
  width: ${({ value }) => `${value}%`};
  height: ${BAR_HEIGHT}px;
  border-radius: 5px;
  transition: width 0.5s;
  background-color: ${({ color }) => color ?? DARK_BLUE};
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${BAR_HEIGHT}px;
  grid-column: 1 / span2;
`;

export const MaterialTypesContainer: React.FC<{
  submissionPeriods: SubmissionPeriod[];
}> = ({ submissionPeriods }) => {
  return (
    <GreyBorderContainer>
      <div style={{ gridColumn: 1, gridRow: 1 }}></div>
      <LegendContainer>
        {[
          MaterialType.LEAD,
          MaterialType.GRR,
          MaterialType.NONLEAD,
          MaterialType.UNKNOWN,
        ].map((type) => (
          <div
            key={type}
            style={{
              display: "flex",
              alignItems: "center",
              gap: 5,
            }}
          >
            <MaterialCircle color={COLOR_MAP.get(type) ?? DARK_BLUE} />
            <span>{LABEL_MAP.get(type)}</span>
          </div>
        ))}
      </LegendContainer>
      {submissionPeriods.map((submissionPeriod) => (
        <MaterialTypesChart
          key={`mt-chart-${submissionPeriod.id}`}
          submissionPeriod={submissionPeriod}
        />
      ))}
    </GreyBorderContainer>
  );
};

const MaterialTypesBar: React.FC<{
  galvanized: number;
  lead: number;
  nonLead: number;
  unknown: number;
  label?: string;
}> = ({ galvanized, lead, nonLead, unknown, label }) => {
  const data = useMemo(
    () =>
      dataToPercentages([
        { bin: MaterialType.LEAD, value: lead },
        { bin: MaterialType.GRR, value: galvanized },
        { bin: MaterialType.NONLEAD, value: nonLead },
        { bin: MaterialType.UNKNOWN, value: unknown },
      ]) as Array<{ bin: MaterialType; value: number; valueRaw: number }>,
    [galvanized, lead, nonLead, unknown]
  );

  const { setTooltipContent, setTooltipPosition } = useTooltip();

  return (
    <>
      {label && <LabelContainer>{label}</LabelContainer>}
      <BarContainer>
        {data.map(({ bin, value, valueRaw }) => (
          <Bar
            onMouseEnter={(e) => {
              const rect = e.currentTarget.getBoundingClientRect();
              const newX = rect.x + rect.width / 2;
              const newY = rect.y + rect.height / 2;
              setTooltipPosition(newX, newY);
              setTooltipContent(
                <span>
                  <strong>{valueRaw}</strong>{" "}
                  {LABEL_MAP.get(bin)?.toLowerCase()} service lines
                </span>
              );
            }}
            onMouseLeave={() => setTooltipContent(false)}
            key={`bin-${bin}`}
            color={COLOR_MAP.get(bin)}
            value={value}
          />
        ))}
      </BarContainer>
    </>
  );
};

const MaterialTypesChart: React.FC<{
  submissionPeriod: SubmissionPeriod;
}> = ({ submissionPeriod }) => {
  const { data, isLoading } = useGetMaterialTypeBreakdownMetricQuery({
    submissionPeriodId: submissionPeriod.id,
  });
  const minLoadTimeElapsed = useMinLoadTime(500, isLoading); // otherwise the page is jerky

  if (!minLoadTimeElapsed || isLoading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  if (data) {
    return (
      <MaterialTypesBar
        label={submissionPeriod.name}
        galvanized={data.grr}
        lead={data.lead}
        nonLead={data.nonlead}
        unknown={data.unknown}
      />
    );
  }

  return null;
};
