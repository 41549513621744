import React from "react";
import { Field } from "react-final-form";
import TextField from "src/components/Form/TextField";

const ContactInfo = (): JSX.Element => {
  return (
    <div>
      <Field<string> component={TextField} label="Name" name="name" />
      <Field<string> component={TextField} label="Title" name="title" />
      <Field<string>
        component={TextField}
        label="Salutation"
        name="salutation"
      />
      <Field<string>
        component={TextField}
        label="Phone Number"
        name="phoneNumber"
      />
      <Field<string> component={TextField} label="Email" name="email" />
      <Field<string> component={TextField} label="Fax" name="fax" />
      <Field<string> component={TextField} label="Web" name="web" />
    </div>
  );
};

export default ContactInfo;
