import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";

import ArizonaMethodsPart1 from "./InventoryMethods/ArizonaMethodsPart1";
import ArizonaMethodsPart2 from "./InventoryMethods/ArizonaMethodsPart2";
import ArizonaMethodsPart3 from "./InventoryMethods/ArizonaMethodsPart3";
import SignatureStep from "./SignatureStep";

interface Props {
  additionalInfo: { [key: string]: any };
}

const ArizonaAccordion: React.FC<Props> = ({ additionalInfo }) => {
  const [isEpaOneOpen, setIsEpaOneOpen] = useState<boolean>(false);
  const [isEpaTwoOpen, setIsEpaTwoOpen] = useState<boolean>(false);
  const [isEpaThreeOpen, setIsEpaThreeOpen] = useState<boolean>(false);
  useState<boolean>(false);
  const [isSignatureOpen, setIsSignatureOpen] = useState<boolean>(false);

  return (
    <Accordion>
      <Accordion.Title
        active={isEpaOneOpen}
        onClick={() => setIsEpaOneOpen(!isEpaOneOpen)}
      >
        <Icon name="dropdown" />
        Historical Records Review
      </Accordion.Title>
      <Accordion.Content active={isEpaOneOpen}>
        <ArizonaMethodsPart1 additionalInfo={additionalInfo} />
      </Accordion.Content>
      <Accordion.Title
        active={isEpaTwoOpen}
        onClick={() => setIsEpaTwoOpen(!isEpaTwoOpen)}
      >
        <Icon name="dropdown" />
        Identifying Service Line Material During Normal Operations
      </Accordion.Title>
      <Accordion.Content active={isEpaTwoOpen}>
        <ArizonaMethodsPart2 additionalInfo={additionalInfo} />{" "}
      </Accordion.Content>
      <Accordion.Title
        active={isEpaThreeOpen}
        onClick={() => setIsEpaThreeOpen(!isEpaThreeOpen)}
      >
        <Icon name="dropdown" />
        Service Line Investigations
      </Accordion.Title>
      <Accordion.Content active={isEpaThreeOpen}>
        <ArizonaMethodsPart3 additionalInfo={additionalInfo} />
      </Accordion.Content>

      <Accordion.Title
        active={isSignatureOpen}
        onClick={() => setIsSignatureOpen(!isSignatureOpen)}
      >
        <Icon name="dropdown" />
        Signature Info
      </Accordion.Title>
      <Accordion.Content active={isSignatureOpen}>
        <SignatureStep additionalInfo={additionalInfo} />
      </Accordion.Content>
    </Accordion>
  );
};

export default ArizonaAccordion;
