import React from "react";
import { Divider } from "semantic-ui-react";
import styled from "styled-components";

import {
  BoldSpan,
  DoubleInputGrid,
  GeneralContainer,
  Value,
  QuestionTitle,
  FlexDiv,
} from "../../shared";

interface Props {
  additionalInfo: { [key: string]: any };
}

const EpaMethodsPart3: React.FC<Props> = ({ additionalInfo }) => {
  const {
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices,
    inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation,
    inventoryMethodsPart3Q2PredictiveModelingExplanation,
    inventoryMethodsPart3Q3PrioritizingLocationsExplanation,
  } = additionalInfo;

  const hasVisualInspectionAtPit =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "Visual Inspection at the Meter Pit"
    );
  const hasCustomerSelfIdentification =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "Customer Self-Identification"
    );
  const hasCurbBoxExternal =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "CCTV Inspection at Curb Box - External"
    );
  const hasCurbBoxInternal =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "CCTV Inspection at Curb Box - Internal"
    );
  const hasWaterSamplingOther =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "Water Quality Sampling - Other"
    );
  const hasMechanicalExcavation =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "Mechanical Excavation"
    );
  const hasVacuumExcavation =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "Vacuum Excavation"
    );
  const hasPredictiveModeling =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "Predictive Modeling"
    );
  const hasOther =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes("Other");

  return (
    <GeneralContainer>
      <div>
        <p style={{ marginBottom: "0px" }}>
          {`Identify the service line investigation methods your system used to
        prepare the inventory (check all that apply). If a water system chooses
        an investigation method not specified by the state under 40 CFR
        §141.84(a)(3)(iv), state approval is required. `}
          <BoldSpan>
            Note that investigations are not required by the LCRR but can be
            used by systems to assess accuracy of historical records and gather
            information when service line material is unknown.
          </BoldSpan>
        </p>
        <DoubleInputGrid>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasVisualInspectionAtPit} />
            <label>Visual Inspection at the Meter Pit</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasCustomerSelfIdentification} />
            <label>Customer Self-Identification</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasCurbBoxExternal} />
            <label>CCTV Inspection at Curb Box - External</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasCurbBoxInternal} />
            <label>CCTV Inspection at Curb Box - Internal</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasWaterSamplingOther} />
            <label>Water Quality Sampling - Other</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasMechanicalExcavation} />
            <label>Mechanical Excavation</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasVacuumExcavation} />
            <label>Vacuum Excavation</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasPredictiveModeling} />
            <label>Sampling/Predictive Modeling</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasOther} />
            <label>Other</label>
          </StyledFlexDiv>
        </DoubleInputGrid>
      </div>
      <Divider />
      <DoubleInputGrid>
        <div>
          <QuestionTitle>
            If Predictive Modeling, please briefly describe the model and inputs
            used
          </QuestionTitle>
          <Value>
            {inventoryMethodsPart3Q2PredictiveModelingExplanation ?? "--"}
          </Value>
        </div>
        <div>
          <QuestionTitle>If other please explain</QuestionTitle>
          <Value>
            {inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation ??
              "--"}
          </Value>
        </div>
      </DoubleInputGrid>
      <Divider />
      <div>
        <QuestionTitle>
          How did you prioritize locations for service line materials
          investigations? For example, did you consider environmental justice
          and/or sensitive populations, did you use predictive modeling, and/or
          did you target areas with high number of unknowns?
        </QuestionTitle>
        <Value>
          {inventoryMethodsPart3Q3PrioritizingLocationsExplanation ?? "--"}
        </Value>
      </div>
      <Divider />
    </GeneralContainer>
  );
};

const StyledFlexDiv = styled(FlexDiv)`
  pointer-events: none;
`;

export default EpaMethodsPart3;
