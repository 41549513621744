import React from "react";
import styled from "styled-components";

import NotFoundCard from "src/components/NotFoundCard";
import Page from "src/components/Page";

const NotFound: React.FC = () => {
  return (
    <Page>
      <Container>
        <NotFoundCard />
      </Container>
    </Page>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export default NotFound;
