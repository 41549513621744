import React from "react";
import { Field } from "react-final-form";
import styled from "styled-components";
import Dropdown from "src/components/Form/Dropdown";
import Territory from "src/types/Territory";

interface Props {
  territories: Territory[];
  isAddText: string;
  disabled: boolean;
}

export const FormContent: React.FC<Props> = ({ territories, disabled }) => {
  const emptySelection = [
    {
      text: "Select a territory",
    },
  ];

  const territoriesOptions = territories.map((t, index) => {
    const { name, id } = t;
    return {
      key: index,
      text: name,
      value: id,
    };
  });

  return (
    <div>
      <Field<number>
        wrapperStyling={{ margin: "0px" }}
        component={StyledDropdown}
        name="territoryId"
        options={[...emptySelection, ...territoriesOptions]}
        disabled={disabled}
      />
    </div>
  );
};

const StyledDropdown = styled(Dropdown)`
  select {
    width: 100%;
  }
`;
