import { Auth0Provider } from "@auth0/auth0-react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";

import ModalSystem from "src/modules/Modal";
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "./constants";
import App from "./modules/App";
import UserProvider from "./modules/User";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-toastify/dist/ReactToastify.css";
import "semantic-ui-css/semantic.min.css";
import "./index.css";
import { TooltipProvider } from "./modules/Tooltip";

dayjs.extend(advancedFormat);
window.addEventListener("pageshow", (event) => {
  // refresh web page if the page is restored from bfcache.
  if (event.persisted) {
    window.location.reload();
  }
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <Auth0Provider
          cacheLocation={"memory"}
          clientId={AUTH0_CLIENT_ID}
          domain={AUTH0_DOMAIN}
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
          useRefreshTokens={true}
          useRefreshTokensFallback={true}
        >
          <UserProvider>
            <ToastContainer key="toast-notifications" transition={Slide} />
            <TooltipProvider>
              <ModalSystem>
                <App />
              </ModalSystem>
            </TooltipProvider>
          </UserProvider>
        </Auth0Provider>
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
