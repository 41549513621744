import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as DownloadIcon } from 'src/assets/Download.svg'

import './DownloadStarted.css'
import { BLACK, DARK_GRAY } from '@120wateraudit/waterworks'

type DownloadType = 'export';
export interface DownloadTypeProps { type: DownloadType }

interface DownloadTypeInfo {
  title: string;
  message: string;
  link: string;
  linkText: string;
}

const DownloadTypeMap: Record<DownloadType, DownloadTypeInfo> = {
  export: {
    title: 'Export Started',
    message: 'Your export has started processing. When it is ready, you can download it from your',
    link: '/accountSettings/exportLog',
    linkText: 'Export Log.'
  }
};

const getDownloadInfo = (type: DownloadType): DownloadTypeInfo => {
  return DownloadTypeMap[type]
};

export const DownloadStartedToast = ({ type }: DownloadTypeProps): JSX.Element => {
  const { title } = getDownloadInfo(type)
  return (
    <Wrapper>
      <Header>
        <Image />
        {title}
      </Header>
      <br />
      <Information>
        <Details type={type} />
      </Information>
    </Wrapper>
  )
}

const Details = ({ type }: DownloadTypeProps): JSX.Element => {
  const { message, link, linkText } = getDownloadInfo(type)
    return (
      <>
        {message}{' '}
        <AuditLogLink to={link}>
          {linkText}
        </AuditLogLink>
      </>
    )
}

const Wrapper = styled.div`
  margin-right: 3rem;
`
const Header = styled.div`
  color: ${BLACK};
  padding-top: 1rem;
  padding-left: 1rem;
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Image = styled(DownloadIcon)`
  padding-right: 0.5rem;
`
const Information = styled.div`
  color: ${DARK_GRAY};
  padding: 1rem, 3rem, 1rem;
  font-size: 1.2rem;
`

const AuditLogLink = styled(Link)`
  color: ${BLACK};
`
