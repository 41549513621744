import React from "react";
import styled from "styled-components";

import System from "src/types/System";
import { formatDate } from "src/utils/format";
import { PwsSubmissionPeriod } from "src/services";

interface Props {
  system: System;
}

const SubmissionPeriodCell: React.FC<Props> = ({ system }: Props) => {
  const { pwsSubmissionPeriods } = system;
  const activeSubmissionPsp: PwsSubmissionPeriod = pwsSubmissionPeriods.filter(psp => psp.active)[0];
  const { name } = activeSubmissionPsp ? activeSubmissionPsp.submissionPeriod : { name: "--" };
  const formattedDueDate = activeSubmissionPsp ? formatDate(activeSubmissionPsp.submissionPeriod.dueDate) : "";

  return (
    <Container>
      <p>{name || "--"}</p>
      {<p>{activeSubmissionPsp ? `Due Date ${formattedDueDate}` : "--"}</p>}
    </Container>
  );
};

const Container = styled.div`
  font-size: 14px;
  line-height: 1.5;

  p {
    margin 0px 0px;
  }
`;

export default SubmissionPeriodCell;
