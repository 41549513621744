import React from "react";
import { Tab } from "semantic-ui-react";
import ServiceLine from "src/types/ServiceLine";
import ServiceLineDetail from "src/modules/Submissions/ServiceLines/ServiceLineDetail";

interface Props {
  serviceLine: ServiceLine;
}

export const ServiceLineDetailTabs: React.FC<Props> = ({ serviceLine }) => {
  const panes = [
    {
      menuItem: "Details",
      render: () => <ServiceLineDetail serviceLine={serviceLine} />,
    },
  ];
  return <Tab menu={{ secondary: true, pointing: true }} panes={panes} />;
};
