import { Button } from "@120wateraudit/waterworks";
import pluralize from "pluralize";
import React, { useState } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";
import {
  useAddBulkSystemsTerritoriesMutation,
  useGetAllTerritoriesQuery,
  useRemoveBulkSystemsTerritoriesMutation,
} from "src/services";
import { toastError, toastSuccess } from "src/utils/toast";
import { FormContent } from "./FormContent";
import LoadingIndicatorPage from "src/components/LoadingIndicatorPage";
import { Radio } from "semantic-ui-react";

interface Values {
  territoryId?: number;
}

interface SubmissionModalProps {
  selectedIds: number[];
  warningCount: number;
  closeModal: () => void;
}

const ChangeTerritoryModal = ({
  closeModal,
  selectedIds,
  warningCount,
}: SubmissionModalProps): JSX.Element => {
  const { data: territories = [], isFetching } = useGetAllTerritoriesQuery({});

  const [isAdd, setIsAdd] = useState(true);

  const isAddText = isAdd ? "Add" : "Remove";
  const isAddTextLowercase = isAddText.toLowerCase();

  const [addSystemsTerritories] = useAddBulkSystemsTerritoriesMutation();
  const [removeSystemsTerritories] = useRemoveBulkSystemsTerritoriesMutation();

  const submit = async (values: Values): Promise<void> => {
    const { territoryId } = values;
    try {
      if (isAdd) {
        if (!territoryId) {
          toastError("No territory selected");
          return;
        }
        await addSystemsTerritories({
          systemIds: selectedIds,
          territoryId,
        }).unwrap();
      } else {
        await removeSystemsTerritories({
          systemIds: selectedIds,
        }).unwrap();
      }
      toastSuccess("Territories updated successfully");
      closeModal();
    } catch (error) {
      toastError(
        `${pluralize("Territory", warningCount, true)} could not be ${
          isAdd ? "added" : "removed"
        }. Please try again.`
      );
    }
  };

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  return (
    <ModalContentContainer>
      <Form
        initialValues={territories}
        onSubmit={submit}
        render={({ handleSubmit, submitting, valid, values }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                void handleSubmit();
              }}
            >
              <FormContentContainer>
                <h1 style={{ marginBottom: "20px" }}>
                  {`Change Territories for ${pluralize(
                    "Utilities",
                    warningCount,
                    true
                  )}`}
                </h1>

                <FormContent
                  territories={territories}
                  isAddText={isAddTextLowercase}
                  disabled={!isAdd}
                />
                <Row>
                  <MarginContainer>
                    {`Unassign ${pluralize(
                      "territory",
                      warningCount,
                      false
                    )} from ${warningCount > 1 ? "All" : ""} ${pluralize(
                      "Utilities",
                      warningCount,
                      false
                    )}`}
                    <Radio
                      style={{ paddingLeft: "5px" }}
                      toggle
                      onChange={() => setIsAdd(!isAdd)}
                    ></Radio>
                  </MarginContainer>
                  <ButtonWrapper>
                    <Button onClick={closeModal}>Cancel</Button>
                    <Button
                      disabled={
                        submitting ||
                        !valid ||
                        (isNaN(Number(values.territoryId)) && isAdd)
                      }
                      type="submit"
                      variant="error"
                      style={{ width: "85px" }}
                    >
                      {isAddText}
                    </Button>
                  </ButtonWrapper>
                </Row>
              </FormContentContainer>
            </form>
          );
        }}
      />
    </ModalContentContainer>
  );
};

const ModalContentContainer = styled.div`
  margin: 2rem;
`;

const FormContentContainer = styled.div`
  text-align: center;
  margin: 1.5rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const MarginContainer = styled.div`
  font-weight: bold;
  margin: 1rem;
  display: flex;
  padding-top: 5px;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 12rem;
`;

export default ChangeTerritoryModal;
