import { ValidationErrors } from "final-form";

import { ContactAddress, ContactCommunication } from "src/types/Contact";

const ZIP_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const isValidZip = (zip: string): boolean => ZIP_REGEX.test(zip);

const PHONE_REGEX = /^\d{10}$/;

const isValidPhone = (phoneNumber: string): boolean =>
  PHONE_REGEX.test(phoneNumber);

export interface Values extends Record<string, unknown> {
  name: string;
  title: string;
  salutation: string;
  communications?: ContactCommunication[];
  addresses?: ContactAddress[];
  email: string;
  phoneNumber: string;
  fax: string;
  web: string;
}

export const validate = (
  values: Values | Record<string, any>,
  type: "create" | "edit"
): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (values.phoneNumber && !isValidPhone(values.phoneNumber)) {
    errors.phoneNumber = "Invalid";
  }

  if (!values.email) {
    errors.email = "Required";
  }

  if (!values.name) {
    errors.name = "Required";
  }

  return errors;
};
