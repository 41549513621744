import { Button, Detail } from "@120wateraudit/waterworks";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useCallback, useContext } from "react";
import styled from "styled-components";

import Row from "src/components/Row";
import { ModalContext } from "src/modules/Modal";
import { Contact } from "src/types/Contact";
import { formatOneLineAddress } from "src/utils/format";
import { toastError, toastSuccess } from "src/utils/toast";
import { isWrite } from "src/modules/User";

interface Props {
  contact: Contact;
  onDelete: (contact: Contact) => Promise<void>;
}

const ContactData = ({ contact, onDelete }: Props): JSX.Element => {
  const {
    name,
    pwsContactAddresses: addresses,
    pwsContactCommunications: communications,
    salutation,
    title,
  } = contact;
  const number = communications?.find(
    (c) => c.communicationType === "phone"
  )?.communicationValue;
  const fax = communications?.find(
    (c) => c.communicationType === "fax"
  )?.communicationValue;
  const email = communications?.find(
    (c) => c.communicationType === "email"
  )?.communicationValue;
  const web = communications?.find(
    (c) => c.communicationType === "web"
  )?.communicationValue;

  const fullName = salutation ? `${salutation} ${name}` : name;
  return (
    <div>
      <Row style={{ marginBottom: "16px" }}>
        <div style={{ textAlign: "left" }}>
          <Name>{fullName}</Name>
          <Title>{title}</Title>
        </div>
        <Controls
          contact={contact}
          onDelete={async () => await onDelete(contact)}
        />
      </Row>
      <StyledDetail label="Telephone #">
        {number ? <a href={`tel:${number}`}>{number}</a> : "--"}
      </StyledDetail>
      <StyledDetail label="Email Address">
        {email ? <a href={`mailto:${email}`}>{email}</a> : "--"}
      </StyledDetail>
      <StyledDetail label="Fax">{fax ?? "--"}</StyledDetail>
      <StyledDetail label="Web">{web ?? "--"}</StyledDetail>
      {addresses?.map((address, index) => (
        <StyledDetail key={index} label={`Address ${index + 1}`}>
          {formatOneLineAddress(address)}
        </StyledDetail>
      )) ?? <StyledDetail label="Address">--</StyledDetail>}
    </div>
  );
};

interface ControlsProps {
  contact: Contact;
  onDelete: () => Promise<void>;
}

const Controls: React.FC<ControlsProps> = ({ contact, onDelete }) => {
  const { closeModal, openModal } = useContext(ModalContext);
  const confirmDelete = useCallback(async () => {
    try {
      await onDelete();
      toastSuccess("Successfully deleted contact");
    } catch {
      toastError("An error occurred during deletion");
    }
  }, [onDelete]);

  const openDeleteContact = useCallback(
    () =>
      openModal("confirm", {
        body: "This will delete this contact.",
        confirmButtonText: "Delete",
        onConfirm: async () => {
          await confirmDelete();
          closeModal();
        },
      }),
    [openModal, closeModal]
  );

  const openEditContactDrawer = (): void => {
    openModal("editContact", { contact, systemId: contact?.pwsId }, "drawer");
  };

  return (
    <div style={{ display: "flex", marginLeft: "auto", gap: ".5rem" }}>
      { isWrite() &&
          <Fragment>
            <Button onClick={openEditContactDrawer} variant="primary">
              <FontAwesomeIcon icon={solid("pencil")} />
            </Button>
            <Button onClick={() => openDeleteContact()} variant="error">
              <FontAwesomeIcon icon={solid("trash")} />
            </Button>
          </Fragment> }
    </div>
  );
};

const StyledDetail = styled(Detail)`
  text-align: left;
`;

const Name = styled.p`
  margin: 0px;
  font-size: 1.1em;
  font-weight: 500;
`;

const Title = styled.p`
  margin: 0px;
  font-weight: 500;
`;

export default ContactData;
