export function dataToPercentages(
  data: Array<{ bin: string; value: number }>
): Array<{
  bin: string;
  value: number;
  valueRaw: number;
}> {
  const total = data.reduce((acc, { value }) => acc + value, 0) || 1;
  return data.map(({ bin, value }) => ({
    bin,
    value: (value / total) * 100,
    valueRaw: value,
  }));
}
