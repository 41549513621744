import { MODALS as CONTACT_MODALS } from "src/modules/Contacts";
import { MODALS as SUBMISSION_PERIOD_MODALS } from "src/modules/Submissions";
import { MODALS as ACTIVITY_MODALS } from "src/modules/Activity";
import { MODALS as TERRITORY_MODALS } from "src/modules/Territories";
import { MODALS as PTD_MODALS } from "src/modules/Ptd";
import { MODALS as SAVED_VIEWS_MODALS } from "src/components/SavedViews";

import ConfirmationModal from "./ConfirmationModal";

export const MODALS = {
  ...ACTIVITY_MODALS,
  ...CONTACT_MODALS,
  ...SUBMISSION_PERIOD_MODALS,
  ...TERRITORY_MODALS,
  ...PTD_MODALS,
  ...SAVED_VIEWS_MODALS,

  confirm: ConfirmationModal,
};
