import React from 'react'

export const useToggle = (
  defaultValue = false
): [boolean, () => void, (enabled: boolean) => void] => {
  const [enabled, setEnabled] = React.useState<boolean>(defaultValue)

  const toggle = React.useCallback(() => {
    setEnabled(p => !p)
  }, [])

  return [enabled, toggle, setEnabled]
}
