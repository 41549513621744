export const STATE_LIST = [
  { key: 0, text: "Alabama", value: "AL" },
  { key: 1, text: "Alaska", value: "AK" },
  { key: 2, text: "Arizona", value: "AZ" },
  { key: 3, text: "Arkansas", value: "AR" },
  { key: 4, text: "California", value: "CA" },
  { key: 5, text: "Colorado", value: "CO" },
  { key: 6, text: "Connecticut", value: "CT" },
  { key: 7, text: "Delaware", value: "DE" },
  { key: 8, text: "District Of Columbia", value: "DC" },
  { key: 9, text: "Florida", value: "FL" },
  { key: 10, text: "Georgia", value: "GA" },
  { key: 11, text: "Hawaii", value: "HI" },
  { key: 12, text: "Idaho", value: "ID" },
  { key: 13, text: "Illinois", value: "IL" },
  { key: 14, text: "Indiana", value: "IN" },
  { key: 15, text: "Iowa", value: "IA" },
  { key: 16, text: "Kansas", value: "KS" },
  { key: 17, text: "Kentucky", value: "KY" },
  { key: 18, text: "Louisiana", value: "LA" },
  { key: 19, text: "Maine", value: "ME" },
  { key: 20, text: "Maryland", value: "MD" },
  { key: 21, text: "Massachusetts", value: "MA" },
  { key: 22, text: "Michigan", value: "MI" },
  { key: 23, text: "Minnesota", value: "MN" },
  { key: 24, text: "Mississippi", value: "MS" },
  { key: 25, text: "Missouri", value: "MO" },
  { key: 26, text: "Montana", value: "MT" },
  { key: 27, text: "Nebraska", value: "NE" },
  { key: 28, text: "Nevada", value: "NV" },
  { key: 29, text: "New Hampshire", value: "NH" },
  { key: 30, text: "New Jersey", value: "NJ" },
  { key: 31, text: "New Mexico", value: "NM" },
  { key: 32, text: "New York", value: "NY" },
  { key: 33, text: "North Carolina", value: "NC" },
  { key: 34, text: "North Dakota", value: "ND" },
  { key: 35, text: "Ohio", value: "OH" },
  { key: 36, text: "Oklahoma", value: "OK" },
  { key: 37, text: "Oregon", value: "OR" },
  { key: 38, text: "Pennsylvania", value: "PA" },
  { key: 39, text: "Rhode Island", value: "RI" },
  { key: 40, text: "South Carolina", value: "SC" },
  { key: 41, text: "South Dakota", value: "SD" },
  { key: 42, text: "Tennessee", value: "TN" },
  { key: 43, text: "Texas", value: "TX" },
  { key: 44, text: "Utah", value: "UT" },
  { key: 45, text: "Vermont", value: "VT" },
  { key: 46, text: "Virginia", value: "VA" },
  { key: 47, text: "Washington", value: "WA" },
  { key: 48, text: "West Virginia", value: "WV" },
  { key: 49, text: "Wisconsin", value: "WI" },
  { key: 50, text: "Wyoming", value: "WY" },
];

export default STATE_LIST;
