import { Button, DARK_GRAY } from "@120wateraudit/waterworks";
import React from "react";
import { Field, useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

import Dropdown from "src/components/Form/Dropdown";
import TextField from "src/components/Form/TextField";
import STATE_LIST from "src/constants/stateList";
import { isValidZip } from "./validation";

const ADDRESSES_NAME = "addresses";
const NEW_ADDRESS = {
  addressType: "primary",
  city: "",
  line1: "",
  line2: "",
  postalCode: "",
  state: "AL",
};

const ADDRESS_TYPES = [
  { text: "Primary", value: "primary" },
  { text: "Mailing", value: "mailing" },
  { text: "Billing", value: "billing" },
];

interface Props {
  fieldName: string;
  index: number;
  onDelete: () => void;
}

const Address = ({ fieldName, index, onDelete }: Props): JSX.Element => {
  const required: (value: any) => undefined | "Required" = (value: any) =>
    value ? undefined : "Required";

  const validateZip: (
    value: any
  ) => undefined | "Invalid Format" | "Required" = (value: any) => {
    if (!value) {
      return "Required";
    } else if (!isValidZip(value)) {
      return "Invalid Format";
    }
    return undefined;
  };

  return (
    <div>
      <div style={{ margin: "0 24px" }}>
        <label
          style={{
            fontWeight: "500",
            fontSize: "16px",
            textDecoration: "underline",
            color: DARK_GRAY,
          }}
        >{`Address ${index + 1}`}</label>
      </div>
      <Field<string>
        component={TextField}
        label="Address Line 1"
        name={`${fieldName}line1`}
        validate={required}
      />
      <Field<string>
        component={TextField}
        label="Address Line 2"
        name={`${fieldName}line2`}
      />
      <Field<string>
        component={TextField}
        label="City"
        name={`${fieldName}city`}
        validate={required}
      />
      <Field<string>
        component={Dropdown}
        label="State"
        name={`${fieldName}state`}
        options={STATE_LIST}
        search
        validate={required}
      />
      <Field<string>
        component={TextField}
        label="Postal Code"
        name={`${fieldName}postalCode`}
        validate={validateZip}
      />
      <Field<string>
        component={Dropdown}
        label="Address Type"
        name={`${fieldName}addressType`}
        options={ADDRESS_TYPES}
        validate={required}
      />
      {index !== 0 && (
        <DeleteButton
          onClick={onDelete}
          style={{ marginLeft: "24px", marginTop: "0px" }}
          type="button"
        >
          <Icon color="red" name="trash alternate" />
        </DeleteButton>
      )}
    </div>
  );
};

const AddressForm = (): JSX.Element => {
  const { mutators } = useForm();
  const addAddress: () => any = () =>
    mutators.push(ADDRESSES_NAME, NEW_ADDRESS);

  return (
    <FieldArray name={ADDRESSES_NAME}>
      {({ fields, meta }) => (
        <>
          {fields.map((name, index) => (
            <Address
              fieldName={name}
              index={index}
              key={name}
              onDelete={() => fields.remove(index)}
            />
          ))}
          <AddButton
            disabled={fields.length === 5}
            onClick={addAddress}
            size="small"
            style={{ margin: "24px" }}
          >
            <Icon color="blue" name="plus" />
            <span>Add Address</span>
          </AddButton>
        </>
      )}
    </FieldArray>
  );
};

const AddButton = styled(Button)`
  &&& {
    box-shadow: none;
    & > span {
      margin-left: 7px;
      color: #2563eb;
    }
  }
`;

const DeleteButton = styled(Button)`
  &&& {
    box-shadow: none;
    align-self: normal;
    flex: 0;
    margin-top: 15px;
  }
`;

export default AddressForm;
