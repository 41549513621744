import React from "react";
import EpaAccordion from "./EPA/EpaAccordion";
import GeorgiaAccordion from "./Georgia/GeorgiaAccordion";
import ArizonaAccordion from "./Arizona/ArizonaAccordion";
import IdemAccordion from "./Indiana/IdemAccordion";
import DelawareAccordion from "./Delaware/DelawareAccordion";
import RhodeIslandAccordion from "./RhodeIsland/RhodeIslandAccordion";
import OregonAccordion from "./Oregon/OregonAccordion";

export interface AccordionProps {
  additionalInfo: { [key: string]: any };
  pwsId?: string;
}
export const StateAccordion = ({
  additionalInfo,
  pwsId,
}: AccordionProps): JSX.Element => {
  let pws;
  if (pwsId?.startsWith("UTAH")) {
    pws = "UTAH";
  } else {
    pws = pwsId?.substring(0, 2)?.toUpperCase();
  }

  switch (pws) {
    case "IN":
      return <IdemAccordion additionalInfo={additionalInfo} />;
    case "GA":
      return <GeorgiaAccordion additionalInfo={additionalInfo} />;
    case "AZ":
      return <ArizonaAccordion additionalInfo={additionalInfo} />;
    case "DE":
      return <DelawareAccordion additionalInfo={additionalInfo} />;
    case "RI":
      return <RhodeIslandAccordion additionalInfo={additionalInfo} />;
    case "OR":
      return <OregonAccordion additionalInfo={additionalInfo} />;
    default:
      return <EpaAccordion additionalInfo={additionalInfo} />;
  }
};
