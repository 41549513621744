import SubmissionPeriodModal from "./AssignSubmissionPeriodModal";
import ChangeSubmissionStatusModal from "./ChangeConsumerNoticeModal";
import AddSubmissionPeriodModal from "./AddSubmissionPeriodModal";
import RemoveSubmissionPeriodModal from "./RemoveSubmissionPeriodModal";
import ChangeTerritoryModal from "./ChangeTerritoryModal";
import ChangeConsumerNoticeModal from "./ChangeSubmissionStatusModal";
import ViewConsumerNoticeModal from "./ViewConsumerNoticeModal";
import ExportModal from "./ExportModal";

export { default as Card } from "./Card";
export { default as Table } from "./Table";

export const MODALS = {
  addSubmissionPeriod: AddSubmissionPeriodModal,
  removeSubmissionPeriod: RemoveSubmissionPeriodModal,
  submissionPeriodModal: SubmissionPeriodModal,
  changeSubmissionStatusModal: ChangeSubmissionStatusModal,
  changeConsumerNoticeModal: ChangeConsumerNoticeModal,
  viewConsumerNoticeModal: ViewConsumerNoticeModal,
  changeTerritoryModal: ChangeTerritoryModal,
  exportModal: ExportModal,
};
