import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties } from "react";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  to: LinkProps["to"];
}

const ExternalLink: React.FC<Props> = ({ children, className, style, to }) => {
  return (
    <Anchor
      className={className}
      rel="noopener noreferrer"
      style={style}
      target="_blank"
      to={to}
    >
      {children}
      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
    </Anchor>
  );
};

const Anchor = styled(Link)`
  & > svg {
    margin-left: 4px;
  }
` as typeof Link;

export default ExternalLink;
