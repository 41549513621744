import { IGNIS_RED } from "@120wateraudit/waterworks";

enum MaterialType {
  GRR = "grr",
  LEAD = "lead",
  NONLEAD = "nonlead",
  UNKNOWN = "unknown",
}

const COLOR_MAP = new Map<MaterialType, string>([
  [MaterialType.GRR, "#FFC720"],
  [MaterialType.LEAD, IGNIS_RED],
  [MaterialType.NONLEAD, "#00B5E2"],
  [MaterialType.UNKNOWN, "#0033A0"],
]);

const LABEL_MAP = new Map<MaterialType, string>([
  [MaterialType.GRR, "Galvanized"],
  [MaterialType.LEAD, "Lead"],
  [MaterialType.NONLEAD, "Non-Lead"],
  [MaterialType.UNKNOWN, "Unknown"],
]);

export { COLOR_MAP, LABEL_MAP, MaterialType };
