import styled from "styled-components";

export const FlexDiv = styled.div`
  display: flex;
  gap: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
  align-items: center;
  max-width: 400px;
`;

export const GeneralContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  p,
  span,
  label,
  div {
    font-size: 14px;
    color: #333;
    font-weight: normal;
    opacity: inherit;
  }
`;

export const BasicContainer = styled.div`
  p,
  span,
  label,
  div {
    font-size: 14px;
    color: #333;
    font-weight: normal;
    opacity: inherit;
  }
`;

export const QuestionTitle = styled.p`
  text-decoration: underline;
`;

export const DoubleInputGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  & > h3 {
    grid-column-start: span 2;
  }
  & > div.field {
    margin: 0 !important;
  }
`;

export const ParagraphInputGrid = styled.section`
  display: grid;
  grid-template-columns: 0.7fr 1.3fr;
  gap: 16px;
  & > h3 {
    grid-column-start: span 2;
  }
  & > div.field {
    margin: 0 !important;
  }
`;

export const BoldSpan = styled.span`
  &&& {
    font-weight: bold;
  }
`;

export const Value = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
`;
