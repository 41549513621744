import { createApi } from '@reduxjs/toolkit/dist/query/react'
import baseQuery from './baseQuery'

interface AssetStatistics {
    galvanizedAssets: number
    leadAssets: number
    nonLeadAssets: number
    totalAssets: number
    unknownAssets: number
}
export const pwsApi = createApi({
    baseQuery: baseQuery({ baseUrl: `/pws/accounts` }),
    reducerPath: "pwsApiV2",
    endpoints: builder => ({
        getAssetStats: builder.query<AssetStatistics, number>({
            query: (id) => {
                return {
                    method: "GET",
                    url: `/${id}/assets/statistics`,
                }
            }
        }),
    }),
})

export const { useGetAssetStatsQuery } = pwsApi;
