import { Page as WWPage } from "@120wateraudit/waterworks";
import React, { useEffect } from "react";
import styled from "styled-components";

import BackButton from "src/components/BackButton";
import { useAuth0 } from "@auth0/auth0-react";
import { isTokenExpired } from "src/modules/User";
import { selectToken } from "src/modules/User/slice";
import { useAppSelector } from "src/store";
import LoadingIndicatorPage from "src/components/LoadingIndicatorPage";

interface Props {
  actions?: React.ReactNode | React.ReactNode[];
  children: React.ReactNode;
  fallbackRoute?: string;
  hasBackButton?: boolean;
  title?: React.ReactNode;
}

const TitleBackButton = styled(BackButton)`
  align-self: start;
  margin-left: -4vw;
  margin-right: 1vw;
  font-size: 10px;
  padding: 10px 12px 8px;
`;

const Title: React.FC<
  Pick<Props, "children" | "hasBackButton" | "fallbackRoute">
> = ({ children, fallbackRoute, hasBackButton }) => {
  return (
    <>
      {hasBackButton && <TitleBackButton fallbackRoute={fallbackRoute} />}
      {children}
    </>
  );
};

export const Page: React.FC<Props> = ({
  actions,
  children,
  fallbackRoute,
  hasBackButton,
  title,
}) => {
  const { isLoading, loginWithRedirect } = useAuth0();
  const token = useAppSelector(selectToken);
  const isTokenExp = isTokenExpired(token);

  useEffect(() => {
    if (!isLoading && isTokenExp) {
      void loginWithRedirect();
    }
  }, [isTokenExp]);

  let wrappedTitle = null;
  if (title) {
    wrappedTitle = (
      <Title hasBackButton={hasBackButton} fallbackRoute={fallbackRoute}>
        {title}
      </Title>
    );
  }

  return !isTokenExp
? (
    <WWPage actions={actions} title={wrappedTitle}>
      {children}
    </WWPage>
  )
: (
    <LoadingIndicatorPage />
  );
};

export default Page;
