/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";
import styled from "styled-components";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { Attachment } from "src/types/Attachment";
import { DARK_BLUE } from "@120wateraudit/waterworks";
import { useFileDownload } from "src/hooks/useFileDownload";

interface Props {
  attachment: Attachment;
  submissionId: number,
  assetId?: number,
  className?: string;
}

const AttachmentRow = ({ attachment, submissionId, assetId, className }: Props): JSX.Element => {
  const { download } = useFileDownload(attachment.id, submissionId, assetId);

  return (
    <DocumentRowWrapper className={className}>
      <DocumentIconWrapper>
        <FontAwesomeIcon
          icon={solid("file-lines")}
          size="lg"
          color="rgba(0, 36, 45, 0.25)"
        />
      </DocumentIconWrapper>
      <DocumentName onClick={download}>
        <StyledLink to="#" title={attachment.originalFileName}>
          {attachment.originalFileName}
        </StyledLink>
      </DocumentName>
    </DocumentRowWrapper>
  );
};

const DocumentName = styled.h5`
  font-weight: 500;
  margin: 0;
  cursor: pointer;
  max-width: 32ch;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 20px;
`;
const DocumentIconWrapper = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: rgba(0, 36, 45, 0.05);
  padding: 2px 3px;
  margin-right: 0.6667rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const DocumentRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 300;
  margin-bottom: 0.78rem;
`;

const StyledLink = styled(Link)`
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
  color: ${DARK_BLUE};
  &:hover {
    color: #0891b2;
  }
`;
export default AttachmentRow;
