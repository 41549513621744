import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";

import EpaMethodsPart1 from "../EPA/InventoryMethods/EpaMethodsPart1";
import EpaMethodsPart2 from "../EPA/InventoryMethods/EpaMethodsPart2";
import EpaMethodsPart3 from "../EPA/InventoryMethods/EpaMethodsPart3";
import SummaryPart1 from "../EPA/InventorySummarySteps/SummaryPart1";
import EpaPublicAccessibilityDoc from "../EPA/EpaPublicAccessibilityDoc";
import SignatureStep from "../EPA/SignatureStep";

interface Props {
  additionalInfo: { [key: string]: any };
}

const DelawareAccordion: React.FC<Props> = ({ additionalInfo }) => {
  const [isEpaOneOpen, setIsEpaOneOpen] = useState<boolean>(false);
  const [isEpaTwoOpen, setIsEpaTwoOpen] = useState<boolean>(false);
  const [isEpaThreeOpen, setIsEpaThreeOpen] = useState<boolean>(false);
  const [isSummaryOneOpen, setIsSummaryOneOpen] = useState<boolean>(false);
  const [isAccessibilityDocOpen, setIsAccessibilityDocOpen] =
    useState<boolean>(false);
  const [isSignatureOpen, setIsSignatureOpen] = useState<boolean>(false);

  return (
    <Accordion>
      <Accordion.Title
        active={isEpaOneOpen}
        onClick={() => setIsEpaOneOpen(!isEpaOneOpen)}
      >
        <Icon name="dropdown" />
        Historical Records Review
      </Accordion.Title>
      <Accordion.Content active={isEpaOneOpen}>
        <EpaMethodsPart1 additionalInfo={additionalInfo} />
      </Accordion.Content>
      <Accordion.Title
        active={isEpaTwoOpen}
        onClick={() => setIsEpaTwoOpen(!isEpaTwoOpen)}
      >
        <Icon name="dropdown" />
        Identifying Service Line Material During Normal Operations
      </Accordion.Title>
      <Accordion.Content active={isEpaTwoOpen}>
        <EpaMethodsPart2 additionalInfo={additionalInfo} />{" "}
      </Accordion.Content>
      <Accordion.Title
        active={isEpaThreeOpen}
        onClick={() => setIsEpaThreeOpen(!isEpaThreeOpen)}
      >
        <Icon name="dropdown" />
        Service Line Investigations
      </Accordion.Title>
      <Accordion.Content active={isEpaThreeOpen}>
        <EpaMethodsPart3 additionalInfo={additionalInfo} />
      </Accordion.Content>
      <Accordion.Title
        active={isSummaryOneOpen}
        onClick={() => setIsSummaryOneOpen(!isSummaryOneOpen)}
      >
        <Icon name="dropdown" />
        General Information
      </Accordion.Title>
      <Accordion.Content active={isSummaryOneOpen}>
        <SummaryPart1 additionalInfo={additionalInfo} />
      </Accordion.Content>
      <Accordion.Title
        active={isAccessibilityDocOpen}
        onClick={() => setIsAccessibilityDocOpen(!isAccessibilityDocOpen)}
      >
        <Icon name="dropdown" />
        Public Accessibility Doc
      </Accordion.Title>
      <Accordion.Content active={isAccessibilityDocOpen}>
        <EpaPublicAccessibilityDoc additionalInfo={additionalInfo} />
      </Accordion.Content>
      <Accordion.Title
        active={isSignatureOpen}
        onClick={() => setIsSignatureOpen(!isSignatureOpen)}
      >
        <Icon name="dropdown" />
        Signature Info
      </Accordion.Title>
      <Accordion.Content active={isSignatureOpen}>
        <SignatureStep additionalInfo={additionalInfo} />
      </Accordion.Content>
    </Accordion>
  );
};

export default DelawareAccordion;
