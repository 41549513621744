import React, { useState, useContext } from "react";
import { Button, DropdownProps, Icon } from "semantic-ui-react";
import { ModalContext } from "src/modules/Modal";
import { SearchParams } from "src/services";
import { useCreatePWSSubmissionPeriodExportMutation } from "src/services/export-service-api";
import { ExportType } from "src/types/enums/ExportRequest";
import { displayDownloadStarted, toastError } from "src/utils/toast";
import styled from "styled-components";
import { Flags, useConfig } from "src/hooks/useConfig";

interface ExportOptionDropdownProps {
  submissionPeriodId: number;
  params: SearchParams;
}

const ExportOptionDropdown: React.FC<ExportOptionDropdownProps> = ({
  submissionPeriodId,
  params,
}) => {
  const { openModal, closeModal } = useContext(ModalContext);
  const [isSelectionOpen, setIsSelectionOpen] = useState(false);
  const [exportSubmissions] = useCreatePWSSubmissionPeriodExportMutation();
  const [statePortalSubmissionsExportEnabled] = useConfig(
    Flags.statePortalSubmissionsExport
  );

  const options = [
    { key: "exportAll", text: "SDWIS - All", value: ExportType.FullSDWIS },
    {
      key: "onlyMostRecent",
      text: "SDWIS - New",
      value: ExportType.MostRecentSDWIS,
    },
    ...(statePortalSubmissionsExportEnabled
      ? [
          {
            key: "exportSubmissionPeriod",
            text: "Submissions",
            value: ExportType.PWSSubmissionPeriod,
          },
        ]
      : []),
  ];

  const handleClick = (): void => {
    setIsSelectionOpen(!isSelectionOpen);
  };

  const handleOpen = (exportType: ExportType): void => {
    if (exportType === ExportType.PWSSubmissionPeriod) {
      exportSubmissions({ params, submissionPeriodId }).catch(() => toastError('Unable to generate export.'))
      displayDownloadStarted({ type: 'export' })
    } else {
      openModal("exportModal", {
        exportType,
        submissionPeriodId,
        closeModal,
        params
      });
    }
  };

  const handleChange = (
    data: DropdownProps
  ): void => {
      handleOpen(data.value as ExportType)
  };

  return (
    <>
      <StyledButton
        options={options}
        onClick={handleClick}
        style={{
          backgroundColor: "#00B5E1",
          color: "white",
          textAlign: "center",
        }}
      >
        Export
        <Icon name="dropdown" style={{ textAlign: "right" }} />
      </StyledButton>
      {isSelectionOpen && (
        <DropdownContainer>
          {options.map((option) => (
            <DropdownOption
              key={option.key}
              onClick={() => handleChange({ value: option.value })}
            >
              {option.text}
            </DropdownOption>
          ))}
        </DropdownContainer>
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  background-color: rgb(0, 181, 225);
  color: white;
  font-weight: 600;
`;

const DropdownContainer = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  position: absolute;
  z-index: 1000;
`;

const DropdownOption = styled.div`
  cursor: pointer;
  padding: 8px;
  &:hover {
    background-color: #f1f1f1;
  }
`;

export default ExportOptionDropdown;
