import { useMemo, useEffect, useCallback } from "react";

import { Values } from "src/modules/Contacts/validation";
import { toastError, toastSuccess } from "src/utils/toast";
import { isApiError } from "src/utils/apiError";
import { useCreateContactMutation } from "src/services/state-submission";
import { ContactCommunication } from "src/types/Contact";

export const useInitialValues = (): Values => {
  return useMemo(() => {
    const values: Values = {
      addresses: [
        {
          line1: "",
          line2: "",
          city: "",
          state: "AL",
          postalCode: "",
          addressType: "primary",
        },
      ],
      email: "",
      fax: "",
      name: "",
      phoneNumber: "",
      salutation: "",
      title: "",
      web: "",
    };

    return values;
  }, []);
};

export const useCreateContact: (
  systemId: number,
  onClose: () => void
) => {
  errors: string[] | undefined;
  onSubmit: (values: Values) => Promise<void>;
} = (systemId, onClose) => {
  const [
    trigger,
    { data, error, isError, isLoading, isSuccess, isUninitialized },
  ] = useCreateContactMutation();
  useEffect(() => {
    if (!isUninitialized && !isLoading && isSuccess) {
      toastSuccess("Contact has been created");
    }
  }, [data, isError, isLoading, isSuccess, isUninitialized]);

  const onSubmit = useCallback(
    async (values: Values) => {
      const {
        email,
        fax,
        phoneNumber,
        web,
        name,
        salutation,
        title,
        addresses,
      } = values;

      const communicationArray: ContactCommunication[] = [];

      if (values.email) {
        communicationArray.push({
          communicationType: "email",
          communicationValue: email,
        });
      }
      if (values.fax) {
        communicationArray.push({
          communicationType: "fax",
          communicationValue: fax,
        });
      }
      if (values.phoneNumber) {
        communicationArray.push({
          communicationType: "phone",
          communicationValue: phoneNumber,
        });
      }
      if (values.web) {
        communicationArray.push({
          communicationType: "web",
          communicationValue: web,
        });
      }

      const contact = {
        pwsId: systemId,
        pwsContactAddresses: addresses,
        pwsContactCommunications: communicationArray,
        name,
        salutation,
        title,
      };

      try {
        await trigger({ systemId, contact }).unwrap();
        onClose();
      } catch {
        toastError("An error occurred. Contact could not be created.");
      }
    },
    [trigger]
  );

  const errors = useMemo(() => {
    if (!error || !isError) {
      return undefined;
    }

    if (isApiError(error)) {
      return [error.data?.error];
    }

    return ["Please try again or contact support@120water.com"];
  }, [error, isError]);

  return useMemo(() => ({ errors, onSubmit }), [errors, onSubmit]);
};
