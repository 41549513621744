import React from "react";
import { Divider } from "semantic-ui-react";

import {
  BasicContainer,
  ParagraphInputGrid,
  QuestionTitle,
  Value,
} from "../shared";

interface Props {
  additionalInfo: { [key: string]: any };
}

const HistoricalRecordsReview: React.FC<Props> = ({ additionalInfo }) => {
  const {
    previousMaterialsEvaluation,
    constructionRecordsAndPlumbingCodes,
    waterSystemRecords,
    distributionSystemInspectionsAndRecords,
    historicalReviewOther,
  } = additionalInfo;
  return (
    <BasicContainer>
      <ParagraphInputGrid>
        <p>Type of Record</p>
        <p>
          Describe the Records Reviewed for Your Inventory and Indicate Your
          Level of Confidence (e.g., Low, Medium, or High)
        </p>
        <QuestionTitle>1. Previous Materials Evaluation</QuestionTitle>
        <Value>{previousMaterialsEvaluation ?? "--"}</Value>
        <QuestionTitle>
          2. Construction Records and Plumbing Codes
        </QuestionTitle>
        <Value>{constructionRecordsAndPlumbingCodes ?? "--"}</Value>
        <QuestionTitle>3. Water System Records</QuestionTitle>
        <Value>{waterSystemRecords ?? "--"}</Value>
        <QuestionTitle>
          4. Distribution System Inspections and Records
        </QuestionTitle>
        <Value>{distributionSystemInspectionsAndRecords ?? "--"}</Value>
        <QuestionTitle>5. Other Records</QuestionTitle>
        <Value>{historicalReviewOther ?? "--"}</Value>
      </ParagraphInputGrid>
      <Divider />
    </BasicContainer>
  );
};

export default HistoricalRecordsReview;
