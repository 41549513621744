import React from "react";
import { Modal } from "semantic-ui-react";
import {
  AQUA_BLUE,
  FlatButton,
  IGNIS_RED,
  LIGHTEST_GRAY,
  TERRA_GREEN,
  VENTUS_YELLOW,
} from "@120wateraudit/waterworks";
import styled from "styled-components";
import { toastSuccess, toastError } from "src/utils/toast";
import {
  useCreateSubmissionCommunicationMutation,
  useUpdateSubmissionCommunicationMutation,
} from "src/services/state-submission";
import { SubmissionCommunicationStatus } from "src/types/enums/SubmissionCommunicationStatus";
import Loader from "src/components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

interface Props {
  value: string;
  pwsId: number;
  submissionPeriodId: number;
  pwsName: string;
  submissionCommunicationId: number;
  refetch: () => void;
  closeModal: () => void;
}

const getConsumerNoticeVerificationText = (
  status: SubmissionCommunicationStatus
): JSX.Element => {
  switch (status) {
    case SubmissionCommunicationStatus.accepted:
      return (
        <h4>
          By making this change, you are verifying that you
          <br /> have received a Customer Notice Certificate from this PWS.
        </h4>
      );
    case SubmissionCommunicationStatus.rejected:
      return (
        <h4>
          By making this change, this user will have to re-submit
          <br /> their Customer Notice Certification on the platform.
        </h4>
      );
    default:
      return <h4>Are you sure you want to change the status?</h4>;
  }
};

export const getColorFromCommunicationStatusModal = (
  status: SubmissionCommunicationStatus
): string => {
  switch (status) {
    case SubmissionCommunicationStatus.accepted:
      return TERRA_GREEN;
    case SubmissionCommunicationStatus.rejected:
      return IGNIS_RED;
    default:
      return LIGHTEST_GRAY;
  }
};

const ChangeSubmissionStatusModal = ({
  value,
  pwsId,
  submissionPeriodId,
  pwsName,
  submissionCommunicationId,
  refetch,
  closeModal,
}: Props): JSX.Element => {
  const [updateSubmissionCommunicationStatus, { isLoading: isLoadingUpdate }] =
    useUpdateSubmissionCommunicationMutation();
  const [createPWSSubmissionCommunication, { isLoading: isLoadingCreate }] =
    useCreateSubmissionCommunicationMutation();

  const onUpdateStatus = async (): Promise<void> => {
    try {
      if (submissionCommunicationId) {
        await updateSubmissionCommunicationStatus({
          submissionCommunicationId,
          value,
        }).unwrap();
      } else {
        await createPWSSubmissionCommunication({
          pwsId,
          submissionPeriodId,
          value,
        }).unwrap();
      }

      refetch();
      closeModal();
      toastSuccess("Consumer Notice Status updated successfully.");
    } catch (error: unknown) {
      console.error(error);
      toastError("An error occurred, please try again.");
    }
  };

  const handleUpdateStatus = (): void => {
    onUpdateStatus().catch((error) => {
      console.error("Failed to update status:", error);
    });
  };

  return (
    <ChangeStatusContainer>
      <StyledModalContent>
        <h3>
          {`You are about to change PWS ${pwsName || ""} Customer Notice `}
          Submission Status to{" "}
          <ValueContainer
            color={getColorFromCommunicationStatusModal(
              value as SubmissionCommunicationStatus
            )}
          >
            <FontAwesomeIcon
              icon={
                value === SubmissionCommunicationStatus.accepted
                  ? regular("circle-check")
                  : regular("circle-xmark")
              }
              style={{ paddingRight: "2px" }}
            />
            <span
              style={{
                color:
                  value === SubmissionCommunicationStatus.rejected
                    ? "black"
                    : getColorFromCommunicationStatusModal(
                        value as SubmissionCommunicationStatus
                      ),
              }}
            >
              {value}
            </span>
          </ValueContainer>
        </h3>

        <Modal.Description>
          {getConsumerNoticeVerificationText(
            value as SubmissionCommunicationStatus
          )}
        </Modal.Description>
      </StyledModalContent>
      <StyledModalActions>
        {isLoadingUpdate || isLoadingCreate
        ? (
          <Loader />
        )
        : (
          <FlatButton
            onClick={handleUpdateStatus}
            variant="primary"
            style={{
              backgroundColor:
                value === SubmissionCommunicationStatus.accepted
                  ? AQUA_BLUE
                  : VENTUS_YELLOW,
              color:
                value === SubmissionCommunicationStatus.accepted
                  ? "white"
                  : "black",
            }}
          >
            {value === SubmissionCommunicationStatus.accepted
              ? "Accept Customer Notice Submission"
              : "Reject Customer Notice Submission"}
          </FlatButton>
        )}
      </StyledModalActions>
    </ChangeStatusContainer>
  );
};

const ChangeStatusContainer = styled.div`
  margin: 40px 0;
`;

const StyledModalContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h3 {
    max-width: 555px;
  }
`;

const StyledModalActions = styled(Modal.Actions)`
  margin: 20px 0;
  text-align: center;
`;

const ValueContainer = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

export default ChangeSubmissionStatusModal;
