import { Bounce, ToastOptions, toast, Id } from "react-toastify";
import { DownloadTypeProps, DownloadStartedToast } from "src/components/Toasts/DownloadStarted";

export const DEFAULT_TOAST: ToastOptions = {
  autoClose: 2500,
  hideProgressBar: true,
  position: "top-center",
  theme: "colored",
  transition: Bounce,
  type: "info",
};

export const toastSuccess = (message: string): Id =>
  toast(message, {
    ...DEFAULT_TOAST,
    type: "success",
  });

export const toastWarning = (message: string): Id =>
  toast(message, {
    ...DEFAULT_TOAST,
    type: "warning",
  });

export const toastError = (message: string): Id =>
  toast(message, {
    ...DEFAULT_TOAST,
    type: "error",
  });

const DOWNLOAD_TOAST: ToastOptions = {
  ...DEFAULT_TOAST,
  position: 'bottom-right',
  autoClose: 5000,
  style: {
    width: 450
  },
  theme: 'light',
  icon: false,
  closeOnClick: true,
  pauseOnHover: true
}

export type { DownloadTypeProps };

export const displayDownloadStarted = ({ type }: DownloadTypeProps): unknown => {
  return toast(DownloadStartedToast({ type }), DOWNLOAD_TOAST)
}
