import React from "react";
import styled from "styled-components";
import AdminMenu from "./Menu";
import { useParams } from "react-router-dom";
import AdminSections from "./Sections";
import Page from "src/components/Page";
import PageHeader from "src/components/PageHeader";

const AccountSettingsPage: React.FC = () => {
  const menuItems = ["Manage Territories", "Export Log", "State PTD Display", "State PTD Publishing"];

  const param = useParams<{
    activeItem: string;
  }>();

  return (
    <Page title={<PageHeader>Account Settings</PageHeader>}>
      <PageLayout>
        <MenuWrapper>
          <AdminMenu
            menuItems={menuItems}
            routeParam={Object.values(param)[0]}
          />
        </MenuWrapper>
        <ActionsWrapper>
          <AdminSections />
        </ActionsWrapper>
      </PageLayout>
    </Page>
  );
};

const PageLayout = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 23% 77%;
  grid-template-areas: "PageMenu PageActions";
  grid-column-gap: 2rem;
`;

const MenuWrapper = styled.div`
  grid-area: PageMenu;
`;

const ActionsWrapper = styled.div`
  background-color: white;
  grid-area: PageActions;
`;

export default AccountSettingsPage;
