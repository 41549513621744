import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import { Card, Detail } from "@120wateraudit/waterworks";
import styled from "styled-components";
import { SimpleCustomField } from "src/types/SimpleCustomField";

interface Props {
  customFields: SimpleCustomField[];
}

const CustomField: React.FC<{ customField: SimpleCustomField }> = ({ customField }) => {
  return (
    <div style={{ width: '25%', maxWidth: 200, padding: '0px 5px', marginBottom: 10 }}>
      <StyledDetail label={customField.fieldName}>
        {customField.fieldValue && customField.fieldName.length ? customField.fieldValue : '--'}
      </StyledDetail>
    </div>
  )
}
const CustomFieldsAccordion: React.FC<Props> = ({ customFields }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <StyledCard>
      <Accordion>
        <Accordion.Title
          active={isOpen}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          style={{ fontSize: "1.07rem", fontWeight: "700" }}
        >
          <Icon name="dropdown" />
          Custom Fields
        </Accordion.Title>
        <Accordion.Content active={isOpen}>
          <StyledCard style={{ border: "none" }}>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {
                customFields.map(cf => <CustomField key={cf.fieldName} customField={cf} />)
              }
            </div>
          </StyledCard>
        </Accordion.Content>
      </Accordion>
    </StyledCard>
  );
};

export default CustomFieldsAccordion;

const StyledCard = styled(Card)`
  margin-top: 5px;
  box-shadow: none;
  border: 2px solid rgb(246, 247, 247);
  padding: 8px 8px;
`;

const StyledDetail = styled(Detail)`
  display: block;
  label {
    overflow-wrap: break-word;
    flex: 0.6;
    opacity: 0.5;
    font-size: 0.9rem;
  }

  span {
    overflow-wrap: break-word;
    flex: 1;
    font-weight: bold;
    font-size: 0.9rem;
  }
  text-align: left;
`;
