import React from "react";

import { Divider } from "semantic-ui-react";
import {
  BoldSpan,
  DoubleInputGrid,
  FlexDiv,
  GeneralContainer,
  Value,
  QuestionTitle,
} from "../shared";

interface Props {
  additionalInfo: { [key: string]: any };
}

const ServiceLineInvestigations: React.FC<Props> = ({ additionalInfo }) => {
  const {
    visualInspectionAtTheMeterPit,
    customerSelfIdentification,
    cctvInspectionAtCurbBoxExternal,
    cctvInspectionAtCurbBoxInternal,
    waterQualitySamplingTargeted,
    waterQualitySamplingFlushed,
    waterQualitySamplingSequential,
    waterQualitySamplingOther,
    mechanicalExcavation,
    vacuumExcavation,
    predictiveModeling,
    otherInvestigation,
    otherInvestigationExplanation,
    predictiveModelingExplanation,
    locationPriorityExplanation,
  } = additionalInfo;

  return (
    <GeneralContainer>
      <p style={{ marginBottom: "0px" }}>
        {`Identify the service line investigation methods your system used to
        prepare the inventory (check all that apply). If a water system chooses
        an investigation method not specified by the state under 40 CFR
        141.84(a)(3)(iv), state approval is required. `}
        <BoldSpan>
          Note that investigations are not required by the LCRR but can be used
          by systems to assess accuracy of historical records and gather
          information when service line material is unknown.
        </BoldSpan>
      </p>
      <DoubleInputGrid>
        <FlexDiv>
          <input type="checkbox" checked={!!visualInspectionAtTheMeterPit} />
          <label>Visual Inspection at the Meter Pit</label>
        </FlexDiv>
        <FlexDiv>
          <input type="checkbox" checked={!!customerSelfIdentification} />
          <label>Customer Self-Identification</label>
        </FlexDiv>
        <FlexDiv>
          <input type="checkbox" checked={!!cctvInspectionAtCurbBoxExternal} />
          <label>CCTV Inspection at Curb Box - External</label>
        </FlexDiv>
        <FlexDiv>
          <input type="checkbox" checked={!!cctvInspectionAtCurbBoxInternal} />
          <label>CCTV Inspection at Curb Box - Internal</label>
        </FlexDiv>
        <FlexDiv>
          <input type="checkbox" checked={!!waterQualitySamplingTargeted} />
          <label>Water Quality Sampling - Targeted</label>
        </FlexDiv>
        <FlexDiv>
          <input type="checkbox" checked={!!waterQualitySamplingFlushed} />
          <label>Water Quality Sampling - Flushed</label>
        </FlexDiv>
        <FlexDiv>
          <input type="checkbox" checked={!!waterQualitySamplingSequential} />
          <label>Water Quality sampling - Sequential</label>
        </FlexDiv>
        <FlexDiv>
          <input type="checkbox" checked={!!waterQualitySamplingOther} />
          <label>Water Quality Sampling - Other</label>
        </FlexDiv>
        <FlexDiv>
          <input type="checkbox" checked={!!mechanicalExcavation} />
          <label>Mechanical Excavation</label>
        </FlexDiv>
        <FlexDiv>
          <input type="checkbox" checked={!!vacuumExcavation} />
          <label>Vacuum Excavation</label>
        </FlexDiv>
        <FlexDiv>
          <input type="checkbox" checked={!!predictiveModeling} />
          <label>Predictive Modeling</label>
        </FlexDiv>
        <FlexDiv>
          <input type="checkbox" checked={!!otherInvestigation} />
          <label>Other</label>
        </FlexDiv>
      </DoubleInputGrid>
      <Divider />
      <DoubleInputGrid>
        <div>
          <QuestionTitle>
            If Predictive Modeling, please briefly describe the model and inputs
            used
          </QuestionTitle>
          <Value>{predictiveModelingExplanation ?? "--"}</Value>
        </div>
        <div>
          <QuestionTitle>If other please explain</QuestionTitle>
          <Value>{otherInvestigationExplanation ?? "--"}</Value>
        </div>
      </DoubleInputGrid>
      <Divider />
      <div>
        <QuestionTitle>
          How did you prioritize locations for service line materials
          investigations? For example, did you consider environmental justice
          and/or sensitive populations, did you use predictive modeling, and/or
          did you target areas with high number of unknowns?
        </QuestionTitle>
        <Value>{locationPriorityExplanation ?? "--"}</Value>
      </div>
      <Divider />
    </GeneralContainer>
  );
};

export default ServiceLineInvestigations;
