import { Dropdown as WWDropdown } from "@120wateraudit/waterworks";
import React, { useContext, Fragment } from "react";
import styled from "styled-components";
import { isWrite } from "src/modules/User";

import {
  StatusBadge,
  getColorFromStatus,
} from "src/modules/Submissions/StatusBadge";
import { useUpdateSubmissionMutation } from "src/services";
import Submission, { SubmissionStatus } from "src/types/Submission";
import { titleCase } from "src/utils/format";
import { toastError, toastSuccess } from "src/utils/toast";
import { ModalContext } from "../Modal";

type DropdownOptions = React.ComponentProps<typeof WWDropdown>["options"];

type Status = keyof typeof SubmissionStatus;

export function getOptionsForCurrentStatus(): DropdownOptions {
  const optionItems: DropdownOptions = [
    { text: SubmissionStatus.submitted, value: 'submitted' },
    { text: SubmissionStatus.inReview, value: 'inReview' },
    { text: SubmissionStatus.approved, value: 'approved' },
    { text: SubmissionStatus.rejected, value: 'rejected' },
    { text: SubmissionStatus.voided, value: 'voided' },
  ];

  return optionItems;
}

export function isTerminalStatus(status: Status): boolean {
  return status !== "submitted" && status !== "inReview";
}

interface StatusChangeContext {
  changeStatus: (status: Status) => Promise<void>;
  confirmStatusChange: (status: Status) => void;
}

const useStatusChangeMutation = (
  submission: Submission
): StatusChangeContext => {
  const [updateSubmission] = useUpdateSubmissionMutation();
  const changeStatus: StatusChangeContext["changeStatus"] = async (status) => {
    await updateSubmission({
      submissionId: `${submission.id}`,
      submissionStatus: status,
    }).unwrap();
  };

  const { closeModal, openModal } = useContext(ModalContext);
  const confirmStatusChange: StatusChangeContext["confirmStatusChange"] = (
    status
  ) =>
    openModal("confirm", {
      body: `Once you change the status to ${titleCase(
        SubmissionStatus[status]
      )} the status cannot be changed again.`,
      confirmButtonText: "Change",
      onConfirm: async () => {
        try {
          await changeStatus(status);
          closeModal();
          toastSuccess(
            `Successfully updated status to ${titleCase(
              SubmissionStatus[status]
            )}`
          );
        } catch {
          toastError("An error occurred during update");
        }
      },
    });

  return { changeStatus, confirmStatusChange };
};

interface Props {
  submission: Submission;
}

export const SubmissionChangeDropdown: React.FC<Props> = ({ submission }) => {
  if (!isWrite() || isTerminalStatus(submission.status)) {
    return (
      <StatusBadge
        status={submission.status}
        style={{
          borderRadius: "4px",
          marginLeft: "1rem",
          height: "33px",
          alignItems: "center",
        }}
      />
    );
  }

  const { changeStatus, confirmStatusChange } =
    useStatusChangeMutation(submission);
  const statusOptions = getOptionsForCurrentStatus();
  return (
      <Fragment>
        <Dropdown
        onChange={(event) => {
          const { value } = event.target;
          if (isTerminalStatus(value as Status)) {
            confirmStatusChange(value as Status);
          } else {
            void changeStatus(value as Status);
          }
        }}
        options={statusOptions}
        value={submission.status}
      />
    </Fragment>
  );
};

const Dropdown = styled(WWDropdown)`
  select {
    border-radius: 0.4rem;
    border: none;
    height: 2.25rem;
    background-color: ${(props: { value: any }) =>
    getColorFromStatus(props.value)};
  }
`;
