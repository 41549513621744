import {
  Badge,
  IGNIS_RED,
  DARK_BLUE,
  AQUA_BLUE,
} from "@120wateraudit/waterworks";
import React from "react";

const getColorForMaterialClassification = (
  materialClassification: string
): string => {
  if (materialClassification === "Non-lead") {
    return AQUA_BLUE;
  }

  if (materialClassification === "Lead Status Unknown") {
    return DARK_BLUE;
  }

  if (materialClassification === "Galvanized Requiring Replacement") {
    return "#FFC720";
  }

  if (materialClassification === "Lead") {
    return IGNIS_RED;
  }

  return AQUA_BLUE;
};

interface MaterialBadgeProps {
  materialClassification: string;
  inverse?: boolean;
}

export const MaterialClassificationBadge: React.FC<MaterialBadgeProps> = ({
  materialClassification,
  inverse = false,
}) => {
  return (
    <Badge
      style={{
        justifyContent: inverse ? "left" : "center",
        padding: "6px",
        textAlign: "center",
        minWidth: "150px",
      }}
      color={
        inverse
          ? getColorForMaterialClassification(materialClassification)
          : "white"
      }
      backgroundColor={
        !inverse
          ? getColorForMaterialClassification(materialClassification)
          : "transparent"
      }
    >
      {materialClassification}
    </Badge>
  );
};

export default MaterialClassificationBadge;
