import React, { useState } from "react";
import { Button } from "@120wateraudit/waterworks";
import pluralize from "pluralize";
import styled from "styled-components";
import { toastError, toastSuccess } from "src/utils/toast";
import {
  useGenerateSIDWISExportMutation,
  useGetGroupedStatusCountsQuery,
} from "src/services";
import { ExportType } from "src/types/enums/ExportRequest";
import LoadingIndicatorPage from "src/components/LoadingIndicatorPage";
import { Link } from "react-router-dom";
import { useGetSIDWISCountQuery } from "src/services/state-submission";

interface SubmissionModalProps {
  exportType: ExportType;
  submissionPeriodId: any;
  closeModal: () => void;
}

const ExportModal = ({
  exportType,
  submissionPeriodId,
  closeModal,
}: SubmissionModalProps): JSX.Element => {
  const [generateSIDWISExport] = useGenerateSIDWISExportMutation();

  const { data: allSubmissionsCount, isLoading: isLoadingSubmissionCount } =
    useGetGroupedStatusCountsQuery({
      submissionPeriodId,
    });

  const {
    data: recentSubmissionsCount,
    isLoading: isLoadingRecentSubmissionsCount,
  } = useGetSIDWISCountQuery({ submissionPeriodId });

  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const allApprovedSubmissionsCount =
    allSubmissionsCount?.data
      ?.filter((s) => s.status === "approved")
      ?.reduce((acc, curr) => acc + curr.count, 0) ?? 0;

  const submit = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await generateSIDWISExport({
        submissionPeriodId,
        exportType,
      }).unwrap();

      toastSuccess(
        `${
          exportType === ExportType.FullSDWIS ? "All" : "Recent"
        } submissions exported successfully`
      );
    } catch (error) {
      toastError("Error Exporting Submissions.");
      closeModal();
    } finally {
      setIsLoading(false);
      setIsComplete(true);
    }
  };

  if (isLoading) {
    return <LoadingIndicatorPage />;
  }

  if (isComplete) {
    return (
      <ModalContentContainer>
        <b style={{ paddingTop: "20px" }}>Export Submissions</b>
        <FormContentContainer>
          <MarginContainer>
            <DownloadingWrapper>
              <h1>Your export is processing</h1>
            </DownloadingWrapper>
            <Link onClick={closeModal} to={`/accountSettings/exportLog`}>
              Go to exports page
            </Link>
          </MarginContainer>
        </FormContentContainer>
      </ModalContentContainer>
    );
  }

  return (
    <ModalContentContainer>
      <b style={{ paddingTop: "20px" }}>
        {exportType === ExportType.FullSDWIS
          ? "Export All Accepted Submissions"
          : "Export Recently Accepted Submissions"}
      </b>
      <FormContentContainer>
        <MarginContainer>
          {exportType === ExportType.FullSDWIS
          ? (
            <>
              {isLoadingSubmissionCount
              ? (
                <LoadingIndicatorPage />
              )
              : (
                <>
                  <h2>
                    {`Export all ${pluralize(
                      "accepted submissions",
                      allApprovedSubmissionsCount,
                      true
                    )}?`}
                  </h2>
                  <p>
                    This data will be compiled into a .csv file and will also be
                    viewable via the Export Log in Settings
                  </p>
                </>
              )}
            </>
          )
          : (
            <>
              {isLoadingRecentSubmissionsCount
              ? (
                <LoadingIndicatorPage />
              )
              : (
                <>
                  <h2>
                    {`Export your most recent ${pluralize(
                      "accepted submissions",
                      recentSubmissionsCount,
                      true
                    )}?`}
                  </h2>
                  <small>
                    This includes all submissions since your last export
                  </small>
                  <p>
                    This data will be compiled into a .csv file and will also be
                    viewable via the Export Log in Settings
                  </p>
                </>
              )}
            </>
          )}

          <StlyedButton
            onClick={() => {
              void submit();
            }}
          >
            Confirm and Export
          </StlyedButton>
        </MarginContainer>
      </FormContentContainer>
    </ModalContentContainer>
  );
};

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormContentContainer = styled.div`
  text-align: center;
  margin: 4rem;
`;

const MarginContainer = styled.div`
  font-weight: bold;
  margin: 1rem;
  padding-top: 5px;
  align-items: center;
  justify-items: center;

  p {
    width: 475px;
  }
`;

const StlyedButton = styled(Button)`
  background-color: rgb(0, 181, 225);
  color: white;
  width: 300px;
`;

const DownloadingWrapper = styled.div`
  svg {
    height: 35px;
    margin: 20px;
  }
`;

export default ExportModal;
