export const trueFalseListOptions = [
        {
                id: 'true',
                text: 'Yes',
                value: "true"
        },
        {
                id: 'false',
                text: 'No',
                value: "false"
        }
]

export const trueFalseNullListOptions = [
        {
                id: '--',
                text: '--',
                value: 'null'
        },
        {
                id: 'true',
                text: 'Yes',
                value: "true"
        },
        {
                id: 'false',
                text: 'No',
                value: "false"
        }
]

export const publishedUnpublishedListOptions = [
        {
                id: 'true',
                text: 'Published',
                value: "true"
        },
        {
                id: 'false',
                text: 'Unpublished',
                value: "false"
        }
]
