import React from "react";
import { StatusBadge } from "src/modules/Submissions/StatusBadge";
import { Table } from "src/modules/Submissions/Table";
import Submission from "src/types/Submission";
import { formatDate } from "src/utils/format";

const COLUMNS = [
  {
    Header: "Submitted Date",
    name: "Submitted Date",
    key: "submittedOn",
    sortable: true,
    accessor: (s: Submission) => formatDate(s.submittedOn),
  },
  {
    Header: "Submitted By",
    name: "Submitted By",
    key: "submittedByName",
    sortable: true,
    accessor: ({ submittedByName }: Submission) => (
      <div>
        {submittedByName ?? ""}
      </div>
    ),
  },
  {
    Header: "Total Lines",
    name: "Total Lines",
    accessor: "totalServiceLinesSubmitted",
    key: "totalServiceLinesSubmitted",
    sortable: true,
  },
  {
    Header: "Percent Known",
    name: "Percent Known",
    accessor: (submission: Submission) =>
      submission ? <>{submission.percentKnown}% Known</> : <></>,
    key: "percentKnown",
    sortable: true,
  },
  {
    Header: "Lead",
    name: "Lead",
    accessor: "totalServiceLinesLead",
    key: "totalServiceLinesLead",
    sortable: true,
  },
  {
    Header: "GRR",
    name: "GRR",
    accessor: "totalServiceLinesGalvanized",
    key: "totalServiceLinesGalvanized",
    sortable: true,
  },
  {
    Header: "Unknown",
    name: "Unknown",
    accessor: "totalServiceLinesUnknown",
    key: "totalServiceLinesUnknown",
    sortable: true,
  },
  {
    Header: "Non-lead",
    name: "Non-lead",
    accessor: "totalServiceLinesNonLead",
    key: "totalServiceLinesNonLead",
    sortable: true,
  },
  {
    Header: "Status",
    name: "Status",
    key: "status",
    sortable: true,
    accessor: (s: Submission) => <StatusBadge status={s.status} />,
  },
  {
    Header: "Overdue",
    name: "Overdue",
    key: "overdue",
    sortable: true,
    accessor: (s: Submission) => s.overdue ? 'Yes' : 'No',
  }
];

interface Props {
  systemId: string;
  isOnUtilityDetailsPage?: boolean
}

export const SubmissionTable: React.FC<Props> = ({ systemId, isOnUtilityDetailsPage }) => {
  return <Table columns={COLUMNS} defaultFilters={{ pwsId: systemId }} isOnUtilityDetailsPage={isOnUtilityDetailsPage} />;
};
