// TODO fix
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import styled from "styled-components";

interface Props {
  body?: string;
  confirmButtonText?: undefined | string;
  disabled?: boolean;
  error?: Error | string;
  title?: string;
  onClose: () => void;
  onConfirm: () => Promise<unknown>;
}

const ConfirmationModal = ({
  body = "This cannot be undone",
  confirmButtonText = "Confirm",
  disabled,
  error,
  onClose,
  onConfirm,
  title = "Confirm",
}: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const confirm: () => Promise<void> = async () => {
    setLoading(true);
    if (await onConfirm()) {
      onClose();
    }
    setLoading(false);
  };

  return (
    <>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {!error && (
            <>
              <P>{body}</P>
              <P>Are you sure?</P>
            </>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Modal.Actions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            disabled={disabled}
            loading={loading}
            onClick={confirm}
            primary
            type="submit"
          >
            {confirmButtonText}
          </Button>
        </Modal.Actions>
      </Modal.Actions>
    </>
  );
};

const P = styled.p`
  font-size: 16px;
`;

export default ConfirmationModal;
