import React, { CSSProperties, useContext } from "react";
import { Dropdown } from "semantic-ui-react";
import { ModalContext } from "src/modules/Modal";

interface Action {
  text: string;
  onClick: Function;
  identifier: string;
}

interface Props {
  className?: string;
  style?: CSSProperties;
  actions: Action[]
  identifiers: Record<string, string>;
}

const ActionMenu = ({
  className,
  style,
  actions,
  identifiers
}: Props): null | JSX.Element => {
  const { closeModal, openModal } = useContext(ModalContext);
  return (
    <Dropdown icon={'ellipsis vertical'} pointing={ "top right" } className={className} style={style}>
      <Dropdown.Menu>
        {actions.map(action => {
          return <Dropdown.Item key={ action.text } text={ action.text } onClick={() => action.onClick(identifiers[action.identifier], openModal, closeModal) }/>
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionMenu;
