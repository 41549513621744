export enum SAVED_VIEW_OWNER_TYPE {
  PrimacyAgency = "primacy_agency",
  User = "user",
  Platform = "platform",
};

export const SAVED_VIEW_CATEGORY_MAP = {
  [SAVED_VIEW_OWNER_TYPE.Platform]: "120water Views",
  [SAVED_VIEW_OWNER_TYPE.User]: "My Views",
  [SAVED_VIEW_OWNER_TYPE.PrimacyAgency]: "Team Views",
}
