import { Detail } from "@120wateraudit/waterworks";
import styled from "styled-components";

const StyledDetail = styled(Detail)`
  display: block;
  label {
    flex: 0.6;
    opacity: 0.5;
    font-size: 0.9rem;
  }

  span {
    flex: 1;
    font-weight: bold;
    font-size: 0.9rem;
  }
  text-align: left;
  margin-bottom: 0.5rem;
`;

export default StyledDetail;
