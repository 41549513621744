import React from "react";
import { Card, Badge } from "@120wateraudit/waterworks";
import styled from "styled-components";

import ServiceLine from "src/types/ServiceLine";
import Row from "src/components/Row";
import Column from "src/components/Column";
import StyledDetail from "src/components/StyledDetail";
import ServiceLineStatusBadge from "../ServiceLineStatusBadge";
import { formatDate } from "src/utils/format";
import { Ownership } from "src/types/enums/Ownership";

interface Props {
  serviceLine: ServiceLine;
}

export const BannerInfo: React.FC<Props> = ({ serviceLine }) => {
  let classificationColor = "";

  if (serviceLine.materialClassification === "Lead") {
    classificationColor = "#F7685B";
  } else if (serviceLine.materialClassification === "Non-Lead") {
    classificationColor = "#0033A0";
  } else {
    classificationColor = "#00B5E2";
  }
  return (
    <Card style={{ boxShadow: "none" }}>
      <BannerRow>
        <Column>
          <h4
            style={{ wordBreak: "break-all", minWidth: "275px" }}
          >{`Service Line External ID: ${serviceLine.externalAssetId}`}</h4>
          <Detail label="Ownership:">
            {Ownership[serviceLine.ownership] || "--"}
          </Detail>
          <Detail label="Consumer Notice Sent On:">
            {formatDate(serviceLine.consumerNoticeCompletionDate) || "--"}
          </Detail>
          <Detail label="Consumer Notice Completed">
            {serviceLine.consumerNoticeCompleted ? "Yes" : "No"}
          </Detail>
          <Detail label="Description:">
            {serviceLine.assetDescription || "--"}
          </Detail>
        </Column>
        <Column>
          <div style={{ display: "flex", gap: ".5rem" }}>
            <ServiceLineStatusBadge status={serviceLine.serviceLineStatus} />
            <ClassificationBadge backgroundColor={classificationColor}>
              {serviceLine.materialClassification || "--"}
            </ClassificationBadge>
          </div>
        </Column>
        <Column />
      </BannerRow>
    </Card>
  );
};

export default BannerInfo;

const Detail = styled(StyledDetail)`
  display: flex;
  label {
    color: black;
    opacity: unset;
    font-weight: bold;
    flex: none;
    margin-right: 5px;
  }
`;

const ClassificationBadge = styled(Badge)`
  min-width: 80px;
  text-align: center;
  justify-content: center;
`;

const BannerRow = styled(Row)`
  gap: 1rem;
  align-items: flex-start;
  padding-top: 1.5rem;

  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
`;
