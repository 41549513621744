import { StatusCount } from "src/services";
import { SubmissionStatus, SubmissionStatuses } from "src/types/Submission";

const aggregatedStatuses = ['aggregateAccepted', 'aggregateInReview', 'aggregateOutstanding', 'aggregateRejected'] as const;
type aggregatedStatusesType = typeof aggregatedStatuses[number];

type StatusTypesCount = {
    [key in SubmissionStatuses | aggregatedStatusesType | "statusesTotal"]: number;
}

export const aggregateStatusCounts = (statusesCounts: StatusCount[] | undefined): StatusTypesCount => {
    // Build a default object where evertyhing is set to 0
    const counts: StatusTypesCount = [...Object.keys(SubmissionStatus), ...aggregatedStatuses, "statusesTotal"].reduce((acc: Record<string, unknown>, curr: string) => {
        acc[curr] = 0;

        return acc;
    }, {}) as StatusTypesCount;

    statusesCounts?.forEach(statusesCount => {
        // Put each status in their respective buckets
        counts[statusesCount.status] = statusesCount.count;

        // Manually handle the aggregates
        if (statusesCount.status === "inReview" || statusesCount.status === "processingSubmission" || statusesCount.status === "submitted") {
            counts.aggregateInReview += statusesCount.count;
        }
        if (statusesCount.status === "overdue" || statusesCount.status === "notSubmitted") {
            counts.aggregateOutstanding += statusesCount.count;
        }
        if (statusesCount.status === "voided" || statusesCount.status === "rejected" || statusesCount.status === "failed") {
            counts.aggregateRejected += statusesCount.count;
        }
        if (statusesCount.status === "approved") {
            counts.aggregateAccepted += statusesCount.count;
        }
    })
    return counts;
}
