import React from "react";
import { Field } from "react-final-form";
import styled from "styled-components";

import { formatDate } from "src/utils/format";
import Dropdown from "src/components/Form/Dropdown";
import SubmissionPeriod from "src/types/SubmissionPeriod";

interface Props {
  submissionPeriods: SubmissionPeriod[];
  warningCount: number;
}

export const FormContent: React.FC<Props> = ({
  submissionPeriods,
  warningCount,
}) => {
  const submissionPeriodOptions: Array<{
    key: number;
    text: string;
    value: number;
  }> = submissionPeriods.map((sp, index) => {
    const { name, dueDate, startDate, id } = sp;
    return {
      key: index,
      text: `Name: ${name}, Due Date: ${formatDate(
        dueDate
      )}, Start Date: ${formatDate(startDate)}`,
      value: id,
    };
  });

  const utilityWarningText =
    warningCount === 1 ? "this utility" : "these utilities";

  return (
    <div>
      <Field<number>
        wrapperStyling={{ margin: "0px" }}
        component={StyledDropdown}
        name="submissionPeriodId"
        options={submissionPeriodOptions}
      />
      <h4 style={{ marginTop: "20px", textAlign: "center" }}>
        {`Are you sure you want to remove the above submission period from ${utilityWarningText}?`}
      </h4>
    </div>
  );
};

const StyledDropdown = styled(Dropdown)`
  select {
    width: 100%;
    max-width: 550px;
  }
`;
