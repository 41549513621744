import { AQUA_BLUE, Button } from "@120wateraudit/waterworks";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrayMutators from "final-form-arrays";
import React from "react";
import { Form as FinalForm } from "react-final-form";
import { Form, Loader } from "semantic-ui-react";

import Error from "src/components/Error";
import Row from "src/components/Row";
import { DrawerContents } from "src/modules/Modal/Drawer";
import { Contact } from "src/types/Contact";
import AddressForm from "../AddressForm";
import ContactInfo from "../ContactInfo";
import { validate } from "../validation";
import { useInitialValues, useUpdateContact } from "./hooks";

interface Props {
  contact: Contact;
  systemId: number;
  onClose: () => void;
}

const EditContactModal = ({
  contact,
  onClose,
  systemId,
}: Props): JSX.Element => {
  const initialValues = useInitialValues(contact);
  const { errors, onSubmit } = useUpdateContact(systemId, contact.id, onClose);

  return (
    <FinalForm
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, valid }) => (
        <Form
          onSubmit={() => {
            void handleSubmit();
          }}
        >
          <DrawerContents
            header={
              <Header onClose={onClose} submitting={submitting} valid={valid} />
            }
          >
            {errors && (
              <Error style={{ margin: "12px 24px" }} messages={errors} />
            )}
            <ContactInfo />
            <AddressForm />
          </DrawerContents>
        </Form>
      )}
      validate={(values) => validate(values, "edit")}
    />
  );
};

const Header = ({
  onClose,
  submitting,
  valid,
}: {
  submitting: boolean;
  valid: boolean;
  onClose: () => void;
}): JSX.Element => {
  return (
    <>
      <div>
        <h3>Edit Contact</h3>
      </div>
      <Row>
        <Button
          disabled={!valid || submitting}
          style={{ marginRight: "7px", backgroundColor: AQUA_BLUE }}
          variant="primary"
        >
          <span style={{ display: "flex" }}>
            {submitting ? "Saving…" : "Save"}{" "}
            <Loader
              active={submitting}
              inline
              size="tiny"
              style={{ marginLeft: "3px" }}
            />
          </span>
        </Button>
        <Button
          type="button"
          disabled={submitting}
          onClick={onClose}
          variant="error"
        >
          <FontAwesomeIcon icon={solid("close")} />
        </Button>
      </Row>
    </>
  );
};

export default EditContactModal;
