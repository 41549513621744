import React from "react";
import styled from "styled-components";

import LoadingIndicatorPage from "src/components/LoadingIndicatorPage";
import Footer from "./Footer";
import Navigation from "./Navigation";

const Container = styled.main`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas: "header" "main" "footer";
`;

const ChildrenContainer = styled.section`
  grid-area: main;
  background-color: #f6f7f7;
`;

interface Props {
  children?: React.ReactNode;
  isLoading?: boolean;
}

const Layout: React.FC<Props> = ({ children, isLoading = false }) => {
  return (
    <Container>
      <Navigation />
      <ChildrenContainer>
        {isLoading ? <LoadingIndicatorPage /> : children}
      </ChildrenContainer>
      <Footer />
    </Container>
  );
};

export default Layout;
