import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import styled from 'styled-components'

const LoginPage: React.FC = () => {
  const { error, loginWithRedirect } = useAuth0()
  return (
    <Wrapper>
      <div className="wrapper">
        <div className="splash"></div>
        <div className="content">
          <img
            alt="120Water"
            className="logo"
            src="https://cdn.120water.com/images/login/logo-login.svg"
          />
          <div id="username_form">
            <form className="form">
              <h4 className="form_title">An error occurred</h4>
              <div className="form_group">
                <p className="form_subtitle">
                  Something went wrong when authenticating your account. Please
                  try logging in again. If this issue persists, contact our{' '}
                  <a
                    href="https://120water.com/support"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Support Team.
                  </a>
                </p>
              </div>
              <div
                className="form_group_error form_group"
                id="username_error_message"
                style={{ display: ((error?.message) != null) ? 'block' : 'none' }}
              >
                {error?.message}
              </div>
              <div className="form_buttons_wrapper">
                <button
                  className="form_button"
                  id="btn_login"
                  onClick={() => { void loginWithRedirect() }}
                >
                  <span>Login</span>
                </button>
              </div>
            </form>
          </div>
          <div className="footer">
            <img
              alt=""
              className="footer_icon"
              src="https://cdn.120water.com/images/login/Footer.svg"
            />
            <div className="content_wrapper">
              <span>
                ©<span id="footer_year"></span>
                120Water™ •
                <a
                  href="https://120water.com/terms-of-use"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Terms and Conditions
                </a>
                •
                <a
                  href="https://120water.com/privacy-policy"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & {
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      :root {
        font-size: 1.2109vw;
      }

      .wrapper {
        grid-template-columns: 1fr !important;
        grid-template-areas: "Form" !important;
      }

      .splash {
        display: none !important;
      }
    }

    /* mobile */
    @media only screen and (max-width: 767px) {
      :root {
        font-size: 12px;
      }

      .wrapper {
        grid-template-columns: 1fr !important;
        grid-template-areas: "Form" !important;
      }

      .splash {
        display: none !important;
      }

      .logo {
        width: 16rem !important;
      }
    }

    /* mobile landscape */
    @media only screen and (max-width: 767px) and (orientation: landscape) {
      :root {
        font-size: 12px;
      }

      .wrapper {
        grid-template-columns: 1fr !important;
        grid-template-areas: "Form" !important;
        height: 500px !important;
      }

      .splash {
        display: none !important;
      }

      .logo {
        width: 16rem !important;
      }
    }

    .wrapper {
      display: grid;
      grid-template-areas: "Image Form";
      grid-template-columns: 0.6fr 0.4fr;
      grid-template-rows: 1fr;
      height: 100vh;
      width: 100vw;
    }

    .splash {
      background: url(https://cdn.120water.com/images/login/120-streets-bg.jpg)
        no-repeat center center fixed;
      background-size: cover;
      display: flex;
      width: 100%;
    }

    .content {
      -webkit-box-align: center;
      -webkit-box-pack: justify;
      align-items: center;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }

    .logo {
      background: #ffffff;
      margin-top: 2rem;
      width: 18.5rem;
    }

    .form {
      display: grid;
      gap: 1rem 0px;
      grid-template-columns: 1fr;
      width: 300px;
    }

    .form_logo {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .form_title {
      font-size: 2rem;
      line-height: 0.9;
      margin: 0;
      text-align: center;
    }

    .form_group {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .form_subtitle {
      color: rgba(0, 36, 45, 0.5);
      text-align: center;
    }

    .form_buttons_wrapper {
      width: 100%;
    }

    .form_button {
      background-color: rgba(0, 181, 225, 1);
      border: none;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: bold;
      font-weight: 0;
      height: 36px;
      line-height: 1em;
      opacity: 1;
      outline: none;
      padding: 0;
      text-align: center;
      width: 100%;
    }

    .form_button:hover {
      background-color: rgba(0, 181, 225, 0.8);
    }

    .form_button:disabled {
      background-color: rgb(192, 237, 248);
    }

    .form_button:disabled:hover {
      background-color: rgba(192, 237, 248, 0.8);
    }

    .form_group_error {
      color: rgba(239, 9, 0, 1);
      display: none;
      font-size: 1rem;
      text-align: center;
    }

    .form_group_link {
      color: rgba(0, 36, 45, 0.5);
      font-size: 1rem;
      line-height: 1.667rem;
      margin: 0;
    }

    .form_group_link > a,
    .form_group_link > a:active,
    .form_group_link > a:visited,
    .form_group_link > a:link {
      color: rgba(0, 36, 45, 0.5);
    }

    .form_group_link > a:hover {
      color: rgba(0, 181, 225, 1);
    }

    .form_logo_icon {
      height: 3rem;
    }

    .footer {
      align-items: center;
      color: rgba(0, 36, 45, 0.25);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem 0;
    }

    .footer_icon {
      height: 1.5rem;
      margin-bottom: 1rem;
      opacity: 0.25;
    }

    .content_wrapper {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      line-height: 1.333rem;
      text-align: center;
    }

    .content_wrapper a {
      color: inherit;
    }

    .content_wrapper a:hover {
      color: rgba(0, 181, 225, 1);
    }
  }
`

export default LoginPage
