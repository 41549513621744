import * as React from 'react'
import Download from 'src/assets/Download.svg'

interface Props {
    style?: React.CSSProperties;
}
export const DownloadIcon: React.FC<Props> = ({ style }) => (
    <>
        <img src={Download} alt="Download Icon" style={style} />
    </>
)
