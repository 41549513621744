import React from "react";

import { Divider } from "semantic-ui-react";
import { GeneralContainer, BoldSpan, QuestionTitle, Value } from "../../shared";

interface Props {
  additionalInfo: { [key: string]: any };
}

const SummaryPart1: React.FC<Props> = ({ additionalInfo }) => {
  const {
    inventorySummarypart1Question1initialInventoryOrUpdate,
    inventorySummarypart1Question2aServiceLineOwnership,
    inventorySummarypart1Question2aServiceLineOwnershipOtherExplanation,
    inventorySummarypart1Question2bServiceLineOwnership,
    inventorySummarypart1Question2bServiceLineOwnershipOtherExplanation,
    inventorySummarypart1Q3aInstallationExplanation,
    inventorySummarypart1Q3bBanningExplanation,
    inventorySummarypart1Q4LeadGoosenecks,
    inventorySummarypart1Q5ConfidenceLevel,
  } = additionalInfo;

  return (
    <GeneralContainer>
      <div>
        <QuestionTitle>
          Is this the <BoldSpan>Initial Inventory</BoldSpan> or an{" "}
          <BoldSpan>Inventory Update</BoldSpan>?
        </QuestionTitle>
        <Value>
          {inventorySummarypart1Question1initialInventoryOrUpdate ?? "--"}
        </Value>
      </div>
      <Divider />
      <div>
        <QuestionTitle>
          Who <BoldSpan>owns the service lines</BoldSpan> in your system? If
          other, please explain below.
        </QuestionTitle>
        <Value>
          {inventorySummarypart1Question2aServiceLineOwnership ?? "--"}
        </Value>
        <QuestionTitle>If other please explain</QuestionTitle>
        <Value>
          {inventorySummarypart1Question2aServiceLineOwnershipOtherExplanation ??
            "--"}
        </Value>
      </div>
      <Divider />
      <div>
        <QuestionTitle>
          Is there documentation that defines service line ownership in your
          system, such as a local ordinance? If yes, please describe below and
          explain where ownership is split (e.g., property line, curb stop).
        </QuestionTitle>
        <Value>
          {inventorySummarypart1Question2bServiceLineOwnership ?? "--"}
        </Value>
        <QuestionTitle>If Yes, please describe</QuestionTitle>
        <Value>
          {inventorySummarypart1Question2bServiceLineOwnershipOtherExplanation ??
            "--"}
        </Value>
      </div>
      <Divider />
      <div>
        <QuestionTitle>
          Describe when lead service lines were generally installed in your
          system.
        </QuestionTitle>
        <Value>{inventorySummarypart1Q3aInstallationExplanation ?? "--"}</Value>
      </div>
      <Divider />
      <div>
        <QuestionTitle>
          When were lead service lines banned in your system? Reference the
          state or local ordinance that banned the use of lead in your system.
        </QuestionTitle>
        <Value>{inventorySummarypart1Q3bBanningExplanation ?? "--"}</Value>
      </div>
      <Divider />
      <div>
        <QuestionTitle>
          Do you have lead goosenecks, pigtails or connectors in your system?
        </QuestionTitle>
        <Value>{inventorySummarypart1Q4LeadGoosenecks ?? "--"}</Value>
      </div>
      <Divider />
      <div>
        <QuestionTitle>
          What is your overall level of confidence in the inventory (i.e., Low,
          Medium, or High.) Please explain your rationale below.
        </QuestionTitle>
        <Value>{inventorySummarypart1Q5ConfidenceLevel ?? "--"}</Value>
      </div>
      <Divider />
    </GeneralContainer>
  );
};

export default SummaryPart1;
