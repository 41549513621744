import {
  ActiveFilters,
  Column,
  FilterType,
  Table as WWTable,
  useMultiselection,
} from "@120wateraudit/waterworks";
import React, { useCallback, useMemo, useContext } from "react";
import { intersection } from "lodash";

import LinkCell from "src/components/Cells/LinkCell";
import { useTableState } from "src/hooks/useTableState";
import { useGetAllSubmissionsQuery } from "src/services/state-submission";
import Sort from "src/types/Sort";
import Submission, { SubmissionStatus } from "src/types/Submission";
import { StatusBadge } from "./StatusBadge";
import { gotoSystem } from "src/utils/system";
import { ModalContext } from "src/modules/Modal";
import { useNavigate } from "react-router-dom";
import { isWrite } from "src/modules/User";
import ActionMenu from "src/components/ActionMenu";
import { trueFalseListOptions } from "src/utils/selectList";

const COLUMNS = [
  {
    Header: "PWS",
    key: "system.name",
    name: "PWS",
    sortable: true,
    accessor: ({ id, system: { name, pwsId } }: Submission) => (
      <div>
        <LinkCell text={name} to={`/submissions/${id}`} />
        <p>{pwsId}</p>
      </div>
    ),
  },
  {
    Header: "Submitted By",
    accessor: "submittedByName",
    key: "submittedBy",
    sortable: false,
  },
  {
    Header: "PWS Type",
    name: "PWS Type",
    key: "system.pwsType",
    sortable: true,
  },
  {
    Header: "Service Line Connections",
    name: "Service Line Connections",
    key: "system.serviceLineConnections",
    sortable: true,
  },
  {
    Header: "Total Lines",
    name: "Total Lines",
    accessor: "totalServiceLinesSubmitted",
    key: "totalServiceLinesSubmitted",
    sortable: true,
  },
  {
    Header: "Percent Known",
    name: "Percent Known",
    accessor: "percentKnown",
    key: "percentKnown",
    sortable: true,
  },
  {
    Header: "Lead",
    name: "Lead",
    accessor: "totalServiceLinesLead",
    key: "totalServiceLinesLead",
    sortable: true,
  },
  {
    Header: "GRR",
    name: "GRR",
    accessor: "totalServiceLinesGalvanized",
    key: "totalServiceLinesGalvanized",
    sortable: true,
  },
  {
    Header: "Unknown",
    name: "Unknown",
    accessor: "totalServiceLinesUnknown",
    key: "totalServiceLinesUnknown",
    sortable: true,
  },
  {
    Header: "Non-lead",
    name: "Non-lead",
    accessor: "totalServiceLinesNonLead",
    key: "totalServiceLinesNonLead",
    sortable: true,
  },
  {
    Header: "Status",
    name: "Status",
    key: "status",
    sortable: true,
    accessor: (s: Submission) => <StatusBadge status={s.status} />,
  },
  {
    Header: "Overdue",
    name: "Overdue",
    key: "overdue",
    sortable: true,
    accessor: (s: Submission) => s.overdue ? 'Yes' : 'No',
  }
];

interface TableProps {
  columns?: Array<Column<Submission>>;
  defaultFilters?: ActiveFilters;
  submissionPeriodId?: number;
  isOnUtilityDetailsPage?: boolean;
}

export const Table: React.FC<TableProps> = ({
  columns,
  defaultFilters,
  submissionPeriodId,
  isOnUtilityDetailsPage,
}) => {
  const navigate = useNavigate();

  const { params, setFilters, setPage, setPageSize, setSearchTerm, setSort } =
    useTableState(defaultFilters);

  const { data: submissionQueryData, isFetching } = useGetAllSubmissionsQuery({
    submissionPeriodId,
    searchParams: params,
  });

  const { onClearSelection, onSelect, onSelectAll, selected, selectedIds } =
    useMultiselection<Submission>();

  const { closeModal, openModal } = useContext(ModalContext);

  const openChangeSubmissionStatus = useCallback(
    () =>
      openModal("changeSubmissionStatusModal", {
        closeModal: () => {
          onClearSelection();
          closeModal();
        },
        selected,
      }),
    [openModal, closeModal, selected]
  );

  const openChangeSingleSubmissionStatus = useCallback(
    (submissionId: number) =>
      openModal("changeSubmissionStatusModal", {
        closeModal: () => {
          onClearSelection();
          closeModal();
        },
        submissionId,
      }),
    [openModal, closeModal]
  );

  const submissions = submissionQueryData?.data ?? [];
  const total = submissionQueryData?.count ?? 0;

  const areAllSelected = useMemo(() => {
    const idsOnPage = submissions.map((s) => s.id);
    return intersection(idsOnPage, selectedIds).length === idsOnPage.length;
  }, [submissions, selectedIds]);

  const selectAllData = useCallback(() => {
    onSelectAll(submissions, areAllSelected);
  }, [onSelectAll, submissions, areAllSelected]);

  const FILTERS = [
    {
      label: "Status",
      key: "status",
      type: FilterType.ListSelection,
      defaultValue: "All",
      multipleSelections: false,
      scrollingSelections: true,
      options: Object.entries(SubmissionStatus).map(([key, val]) => ({
        id: key,
        value: key,
        text: val,
      })),
    },
    {
      label: "Total Lines",
      key: "totalServiceLinesSubmitted",
      type: FilterType.Number,
    },
    {
      label: "Percent Known",
      key: "percentKnown",
      type: FilterType.Number,
    },
    {
      label: "Overdue",
      key: "overdue",
      type: FilterType.ListSelection,
      defaultValue: "All",
      multipleSelections: false,
      options: trueFalseListOptions,
    },
    {
      label: "Lead",
      key: "totalLead",
      type: FilterType.Number,
    },
    {
      label: "GRR",
      key: "totalGRR",
      type: FilterType.Number,
    },
    {
      label: "Unknown",
      key: "totalUnknown",
      type: FilterType.Number,
    },
    {
      label: "Non-Lead",
      key: "totalNonLead",
      type: FilterType.Number,
    },
    {
      label: 'Submitted By',
      key: 'submittedBy',
      type: FilterType.TextMatch,
    },
    {
      label: "Submitted Date",
      key: "submittedOn",
      type: FilterType.DatePicker,
    },
  ].sort((a, b) => a.label.localeCompare(b.label));

  const TABLE_ACTIONS = isWrite()
      ? [
    {
      label: "Change Submission Status",
      onClick: openChangeSubmissionStatus,
    },
        ]
      : [];

  const ROW_ACTIONS_COLUMN = [
    {
      Header: "",
      hiddenOnColumnPicker: true,
      key: "system.id",
      sortable: false,
      accessor: (s: Submission) => (
        <ActionMenu
          identifiers={{
            accountId: s.system.accountId.toString(),
            submissionId: s.id.toString(),
          }}
          actions={ getRowActions(s) }
        />
      ),
    },
  ];

  const getRowActions = (submission: Submission): any => {
    const actions = [];
    actions.push({
      text: "Login to PWS Portal",
          onClick: gotoSystem,
        identifier: "accountId",
    })
    actions.push({
              text: "Show Activity",
              onClick: () =>
                openModal(
                  "activityModal",
                  {
                    submission,
                    modalSize: "large",
                    onClose: async () => {
                      closeModal();
                    },
                  },
                  "window"
                ),
              identifier: "submissionId",
    })
    if (isWrite()) {
      actions.push({
              text: "Change Status",
              onClick: openChangeSingleSubmissionStatus,
              identifier: "submissionId",
      })
    }
    return actions
  }

  const handleRowClick = (submission: Submission): void => {
    navigate(`/submissions/${submission.id}`);
  };

  return (
    <WWTable
      activeFilters={params.filters}
      allowHiddenColumns={true}
      columns={columns ?? [...COLUMNS]}
      rowActionColumn={ROW_ACTIONS_COLUMN}
      data={submissions}
      filterable
      filters={[...FILTERS]}
      itemName="Submissions"
      loading={isFetching}
      onFilterChanged={setFilters}
      onPageChanged={setPage}
      onPageSizeChanged={setPageSize}
      orderable={true}
      onSearchChanged={setSearchTerm}
      onSortChanged={setSort}
      page={params.page}
      pageSize={params.pageSize}
      paginated
      searchTerm={params.searchTerm}
      searchable
      sort={params.sort as Sort}
      sortable
      onRowClicked={handleRowClick}
      rowsClickable={isOnUtilityDetailsPage}
      totalRecords={total}
      areAllSelected={areAllSelected}
      actions={TABLE_ACTIONS}
      selected={selected}
      selectionMode="multi"
      onClearSelection={onClearSelection}
      onSelect={onSelect}
      onSelectAll={selectAllData}
    />
  );
};

export default Table;
