import React, { CSSProperties } from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";

import { FieldInput } from "./FieldInput";

type Props = FieldRenderProps<string, HTMLElement> & {
  className?: string;
  help?: string;
  label?: string;
  style?: CSSProperties;
};

export const TextField = ({
  className,
  help,
  input,
  label,
  placeholder,
  meta: { error },
  style,
}: Props): JSX.Element => {
  return (
    <Wrapper>
      <FieldInput
        className={className}
        error={error}
        help={help}
        style={style}
        {...input}
        label={label}
        name={input.name}
        onChange={input.onChange}
        type="text"
        value={input.value}
        placeholder={placeholder}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 24px;
`;

export default TextField;
