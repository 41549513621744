import React from "react";
import { Divider } from "semantic-ui-react";

import {
  BasicContainer,
  ParagraphInputGrid,
  QuestionTitle,
  Value,
} from "../../shared";

interface Props {
  additionalInfo: { [key: string]: any };
}

const EpaMethodsPart1: React.FC<Props> = ({ additionalInfo }) => {
  const {
    inventoryMethodsPart1PreviousMaterialsEvaluation,
    inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes,
    inventoryMethodsPart1WaterSystemRecords,
    inventoryMethodsPart1DistributionSystemInspectionsAndRecords,
    inventoryMethodsPart1otherRecords,
  } = additionalInfo;

  return (
    <BasicContainer>
      <ParagraphInputGrid>
        <p>Type of Record</p>
        <p>
          Describe the Records Reviewed for Your Inventory and Indicate Your
          Level of Confidence (e.g., Low, Medium, or High)
        </p>
        <QuestionTitle>1. Previous Materials Evaluation</QuestionTitle>
        <Value>
          {inventoryMethodsPart1PreviousMaterialsEvaluation ?? "--"}
        </Value>
        <QuestionTitle>
          2. Construction Records and Plumbing Codes
        </QuestionTitle>
        <Value>
          {inventoryMethodsPart1ConstructionRecordsAndPlumbingCodes ?? "--"}
        </Value>
        <QuestionTitle>3. Water System Records</QuestionTitle>
        <Value>{inventoryMethodsPart1WaterSystemRecords ?? "--"}</Value>
        <QuestionTitle>
          4. Distribution System Inspections and Records
        </QuestionTitle>
        <Value>
          {inventoryMethodsPart1DistributionSystemInspectionsAndRecords ?? "--"}
        </Value>
        <QuestionTitle>5. Other Records</QuestionTitle>
        <Value>{inventoryMethodsPart1otherRecords ?? "--"}</Value>
      </ParagraphInputGrid>
      <Divider />
    </BasicContainer>
  );
};

export default EpaMethodsPart1;
