import React, { CSSProperties } from "react";
import { Message, SemanticCOLORS } from "semantic-ui-react";

interface Props {
  messages: string[];
  className?: string;
  header?: string;
  style?: CSSProperties;
  color?: SemanticCOLORS;
}

export const Error: React.FC<Props> = ({
  messages,
  className,
  header,
  style,
  color,
}) => {
  return (
    <Message className={className} negative style={style} color={color}>
      <Message.Header>{header}</Message.Header>
      {messages.map((message, index) => (
        <p key={`error_msg_${index}`}>{message}</p>
      ))}
    </Message>
  );
};

export default Error;
