import React, { PropsWithChildren, useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

const DetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px;
`;

export const MapDetail: React.FC<
  PropsWithChildren & {
    title: string | React.ReactNode;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    open?: boolean;
  }
> = ({ title, children, style, open: openProp }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(openProp ?? false);
  }, [openProp]);

  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <DetailHeader onClick={() => setOpen(!open)} role="button">
        <span style={{ fontWeight: "bold" }}>{title}</span>
        <Icon
          name="chevron down"
          style={{
            margin: 0,
            height: "unset",
          }}
        />
      </DetailHeader>
      {open && (
        <div
          style={{
            padding: "0 15px 10px",
            ...style,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};
