import React from "react";
import { DARK_BLUE } from "@120wateraudit/waterworks";

export const MaterialCircle: React.FC<{
  color: string;
}> = ({ color }) => {
  return (
    <div
      style={{
        border: `4px solid ${color ?? DARK_BLUE}`,
        width: 14,
        height: 14,
        borderRadius: "50%",
      }}
    ></div>
  );
};
