export const getAttachmentUrl = async (token: null | string, attachmentId: number, submissionId: number, assetId?: number): Promise<string> => {
  const API_BASE = process.env.REACT_APP_AWS_ENDPOINT ?? "http://localhost:8000";
  const auth = token ?? ''
  let parameters = `submissionId=${submissionId}`
  if (assetId) {
    parameters = parameters + `&assetId=${assetId}`
  }
  const response = await fetch(`${API_BASE}/platform/attachment/${attachmentId}/file/stateDashboard?${parameters}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${auth}`
    }
  })
  return await response.text();
}
