import React from "react";
import { Button, AQUA_BLUE } from "@120wateraudit/waterworks";
import { Form as FinalForm } from "react-final-form";
import { Form, Loader } from "semantic-ui-react";
import arrayMutators from "final-form-arrays";

import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Error from "src/components/Error";
import { DrawerContents } from "src/modules/Modal/Drawer";
import ContactInfo from "../ContactInfo";
import { useInitialValues, useCreateContact } from "./hooks";
import { validate } from "../validation";
import Row from "src/components/Row";
import AddressForm from "../AddressForm";

interface Props {
  onClose: () => void;
  systemId: number;
}

const AddContactModal = ({ onClose, systemId }: Props): JSX.Element => {
  const initialValues = useInitialValues();
  const { errors, onSubmit } = useCreateContact(systemId, onClose);

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit, submitting, valid }) => (
        <Form
          onSubmit={() => {
            void handleSubmit();
          }}
        >
          <DrawerContents
            header={
              <Header onClose={onClose} submitting={submitting} valid={valid} />
            }
          >
            {errors && (
              <Error style={{ margin: "12px 24px" }} messages={errors} />
            )}
            <ContactInfo />
            <AddressForm />
          </DrawerContents>
        </Form>
      )}
      validate={(values) => validate(values, "create")}
    />
  );
};

const Header = ({
  onClose,
  submitting,
  valid,
}: {
  submitting: boolean;
  valid: boolean;
  onClose: () => void;
}): JSX.Element => {
  return (
    <>
      <div>
        <h3>Create Contact</h3>
      </div>
      <Row>
        <Button
          variant="primary"
          disabled={!valid || submitting}
          style={{ marginRight: "7px", backgroundColor: AQUA_BLUE }}
        >
          <span style={{ display: "flex" }}>
            {submitting ? "Saving…" : "Save"}{" "}
            <Loader
              style={{ marginLeft: "3px" }}
              active={submitting}
              inline
              size="tiny"
            />
          </span>
        </Button>
        <Button
          type="button"
          variant="error"
          disabled={submitting}
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
      </Row>
    </>
  );
};

export default AddContactModal;
