import { createMedia } from "@artsy/fresnel";

export const { Media, MediaContextProvider } = createMedia({
  breakpoints: {
    lg: 1024,
    md: 768,
    sm: 0,
    xl: 1192,
  },
});
