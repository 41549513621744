/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { createApi } from "@reduxjs/toolkit/query/react";

import { User } from "src/modules/User/types";
import baseQuery from "./baseQuery";
import { Tag, TagLabel } from "./tags";
import { AccountManagementCounty } from "src/types/AccountManagementCounty";

export const accountManagementService = createApi({
  baseQuery: baseQuery({ baseUrl: "/platform/account-management/rest" }),
  reducerPath: "accntmgmt",
  tagTypes: [Tag.Users, Tag.Counties],
  endpoints: (builder) => ({
    getCurrentUser: builder.query<User, void>({
      providesTags: [{ id: TagLabel.Current, type: Tag.Users }],
      query: () => `/currentuser/@me`,
    }),
    getAvailableCounties: builder.query<AccountManagementCounty[], void>({
      providesTags: [{ id: TagLabel.Current, type: Tag.Counties }],
      query: () => '/currentuser/defaultaccount/available-counties'
    })
  }),
});

export const { useLazyGetCurrentUserQuery, useGetAvailableCountiesQuery } = accountManagementService;

export default accountManagementService;
