import React from "react";
import { Divider } from "semantic-ui-react";

import {
  BasicContainer,
  DoubleInputGrid,
  Value,
  QuestionTitle,
} from "./shared";

interface Props {
  additionalInfo: { [key: string]: any };
}

const Signature: React.FC<Props> = ({ additionalInfo }) => {
  const {
    certificationName,
    certificationTitle,
    certificationPhone,
    certificationEmail,
    alternativeCertificationName,
    alternativeCertificationTitle,
    certificationOrganization,
    alternativeCertificationPhone,
    altenativeCertificationEmail,
    certificationStatement,
  } = additionalInfo;

  return (
    <BasicContainer>
      <DoubleInputGrid>
        <div>
          <DoubleInputGrid>
            <QuestionTitle>
              System Certification (First Name + Last Name)
            </QuestionTitle>
            <Value>{certificationName ?? "--"}</Value>
            <QuestionTitle>Title</QuestionTitle>
            <Value>{certificationTitle ?? "--"}</Value>
            <QuestionTitle>Phone</QuestionTitle>
            <Value>{certificationPhone ?? "--"}</Value>
            <QuestionTitle>E-mail</QuestionTitle>
            <Value>{certificationEmail ?? "--"}</Value>
          </DoubleInputGrid>
        </div>
        <div>
          <DoubleInputGrid>
            <QuestionTitle>
              Alternative Certification (First Name + Last Name)
            </QuestionTitle>
            <Value>{alternativeCertificationName ?? "--"}</Value>
            <QuestionTitle>Title</QuestionTitle>
            <Value>{alternativeCertificationTitle ?? "--"}</Value>
            <QuestionTitle>Organization</QuestionTitle>
            <Value>{certificationOrganization ?? "--"}</Value>
            <QuestionTitle>Phone</QuestionTitle>
            <Value>{alternativeCertificationPhone ?? "--"}</Value>
            <QuestionTitle>E-mail</QuestionTitle>
            <Value>{altenativeCertificationEmail ?? "--"}</Value>
          </DoubleInputGrid>
        </div>
      </DoubleInputGrid>
      <Divider />
      <QuestionTitle>
        I have compiled a service line inventory for this water system based
        upon a review of the above referenced records and investigations in
        accordance with 40 CFR Part 141, Subpart 1
      </QuestionTitle>
      <Value>{certificationStatement ?? "--"}</Value>
    </BasicContainer>
  );
};

export default Signature;
