import { Card } from "@120wateraudit/waterworks";
import React, { FC } from "react";
import styled from "styled-components";

import Row from "src/components/Row";
import { useGetAttachmentsByAssetIdQuery } from "src/services/attachments-api";
import { Attachment } from "src/types/Attachment";
import AttachmentRow from "src/modules/Attachments/AttachmentRow";
import Loader from "src/components/Loader";

interface Props {
  className?: string;
  assetId: number;
  submissionId: number;
}

const Empty: FC = () => (
  <Row>
    <p style={{ fontSize: "14px" }}>No attachments found</p>
  </Row>
);

const Attachments = ({
  className,
  assetId,
  submissionId
}: Props): JSX.Element => {
  const { data: useGetAttachmentsQueryData, isLoading } = useGetAttachmentsByAssetIdQuery({
    filters: {},
    page: 1,
    assetId,
    submissionId,
    pageSize: 100,
  });

  const attachments = useGetAttachmentsQueryData?.data ?? [];

  if (isLoading) {
    return (
      <AttachmentCard>
        <Row style={{ justifyContent: "center" }}>
          <Loader size="3x" />
        </Row>
      </AttachmentCard>
    );
  }

  return (
    <AttachmentCard className={className}>
      <h2>Attachments</h2>
      <Column>
        {attachments.length <= 0 && <Empty />}
        {attachments.map((a: Attachment) => (
          <AttachmentRow attachment={a} key={`AttachmentRow-${a.id}`} submissionId={ submissionId } assetId={ assetId }/>
        ))}
      </Column>
    </AttachmentCard>
  );
};

const AttachmentCard = styled(Card)`
  margin-top: 1rem;
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
  & h2 {
    font-size: 1.33rem;
    margin: 0;
  }
`;

const Column = styled(Row)`
  flex-direction: column;
  align-items: flex-start;
`;

export default Attachments;
