import React, { ReactNode } from "react";
import { Card } from "@120wateraudit/waterworks";
import { Radio } from "semantic-ui-react";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "radio title"
    "radio description";
  gap: 8px;
  align-items: center;
  padding: 0;

  h3 {
    grid-area: title;
    margin: 0;
  }

  p {
    grid-area: description;
    margin: 0;
  }
`;

export const RadioCard: React.FC<{
  title: ReactNode;
  description?: string;
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}> = ({ title, description, selected, onClick, disabled, style }) => {
  return (
    <Card style={{ width: "100%", padding: 8, ...style }}>
      <StyledContainer>
        <Radio
          checked={selected}
          onClick={() => {
            if (!disabled) onClick();
          }}
          disabled
        />
        <h3 style={{ margin: 0 }}>{title}</h3>
        {description && <p>{description}</p>}
      </StyledContainer>
    </Card>
  );
};
