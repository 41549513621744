import React from "react";
import styled from "styled-components";

interface Props {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
}

const AddressCell: React.FC<Props> = ({
  addressLine1,
  addressLine2,
  city,
  state,
}: Props) => {
  return (
    <Container>
      <p>
        {addressLine1}
        {addressLine2 ? ` ${addressLine2}` : ""}
      </p>
      <p>
        {city}, {state}
      </p>
    </Container>
  );
};

const Container = styled.div`
  font-size: 14px;
  line-height: 1.5;
  min-width:150px;

  p {
    margin 0px 0px;
  }
`;

export default AddressCell;
