import React, { CSSProperties } from "react";
import { FieldRenderProps } from "react-final-form";
import { Dropdown as WWDropdown, IGNIS_RED } from "@120wateraudit/waterworks";
import styled from "styled-components";

type Props = FieldRenderProps<string, HTMLElement> & {
  className?: string;
  label?: string;
  style?: CSSProperties;
  wrapperStyling?: CSSProperties;
};

export const Dropdown: React.FC<Props> = ({
  className,
  input,
  label,
  meta: { error },
  style,
  options,
  wrapperStyling,
  ...rest
}: Props) => {
  return (
    <Wrapper style={wrapperStyling}>
      <FieldDropdown
        style={style}
        error={error}
        className={className}
        {...input}
        name={input.name}
        value={input.value}
        label={label}
        onChange={input.onChange}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        options={options}
        {...rest}
      />
    </Wrapper>
  );
};

const FieldDropdown = styled(WWDropdown)`
  &&& {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0px;

    select {
      padding-left: 0.7em;
      border-width: 2px;
      border-color: ${(props: { error: any }) =>
        props.error ? IGNIS_RED : "rgba(0, 0, 0, 0.25)"} !important;
    }

    label {
      margin-right: auto;
    }
  }
`;

const Wrapper = styled.div`
  margin: 24px;
`;

export default Dropdown;
