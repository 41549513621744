import { Card } from "@120wateraudit/waterworks";
import React, { FC, useState } from "react";
import styled from "styled-components";
import { Accordion, Icon } from "semantic-ui-react";

import Row from "src/components/Row";
import { useGetAttachmentsBySubmissionIdQuery } from "src/services/attachments-api";
import { Attachment } from "src/types/Attachment";
import Loader from "src/components/Loader";
import AttachmentRow from "./AttachmentRow";

interface Props {
  className?: string;
  submissionId: number;
}

const Empty: FC = () => (
  <Row>
    <p style={{ fontSize: "14px" }}>No attachments found</p>
  </Row>
);

const SubmissionAttachmentAccordion = ({
  className,
  submissionId,
}: Props): JSX.Element => {
  const { data: queryData, isLoading } = useGetAttachmentsBySubmissionIdQuery({
    filters: {},
    page: 1,
    pageSize: 100,
    submissionId
  });
  const attachments = queryData?.data ?? [];

  const [isAttachmentsOpen, setIsAttachmentsOpen] = useState<boolean>(false);

  if (isLoading) {
    return (
      <Row style={{ justifyContent: "center" }}>
        <Loader size="3x" />
      </Row>
    );
  }

  return (
    <Accordion>
      <Accordion.Title
        active={isAttachmentsOpen}
        onClick={() => {
          setIsAttachmentsOpen(!isAttachmentsOpen);
        }}
      >
        <Icon name="dropdown" />
        Submission Attachments
      </Accordion.Title>

      <Accordion.Content active={isAttachmentsOpen}>
        <StyledCard style={{ border: "none" }}>
          {attachments.length <= 0 && <Empty />}
          {attachments.map((a: Attachment) => (
            <StyledAttachmentRow
              className={className}
              attachment={a}
              key={`AttachmentRow-${a.id}`}
              submissionId={ submissionId }
            />
          ))}
        </StyledCard>
      </Accordion.Content>
    </Accordion>
  );
};

const StyledAttachmentRow = styled(AttachmentRow)`
  justify-content: flex-start;

  h5 {
    max-width: 100%;
  }
`;

const StyledCard = styled(Card)`
  box-shadow: none;
  border: 2px solid rgb(246, 247, 247);
  padding: 8px 8px;
`;

export default SubmissionAttachmentAccordion;
