import React, { useState } from "react";
import { Column, Table } from "@120wateraudit/waterworks";
import {
  useGenerateCSVExportMutation,
  useGetSIDWISExportsQuery,
} from "src/services";
import Export from "src/types/Export";
import { DownloadIcon } from "src/components/Icons/Download";
import Loader from "src/components/Loader";
import { ExportType } from "src/types/enums/ExportRequest";
import { formatDateTime } from "src/utils/format";
import ExportStatusBadge from "./ExportStatusBadge";
import { useGetAllExportsQuery, useLazyDownloadExportQuery } from "src/services/export-service-api";

interface TableExport extends Export {
  [key: string]: any;
}

interface CenteredCellProps {
  children: React.ReactNode;
  header?: boolean;
}

const CenteredCell: React.FC<CenteredCellProps> = ({ children, header }) => (
  <div style={{ textAlign: "center" }}>
    {header ? <b>{children}</b> : children}
  </div>
);

const ExportLog: React.FC = () => {
  const { data: response = [] } = useGetSIDWISExportsQuery({}, {
    pollingInterval: 15 * 1000,
    refetchOnMountOrArgChange: true
  });
  const [generateCSVExport] = useGenerateCSVExportMutation();
  const [downloadExport] = useLazyDownloadExportQuery();
  const [downloadState, setDownloadState] = useState(false);

  const {
    data: exportServiceData = [],
  } = useGetAllExportsQuery(
    {
      searchParams: {
        pageSize: 1000,
        page: 1,
        filters: {}
      }
    },
    {
      pollingInterval: 15 * 1000,
      refetchOnMountOrArgChange: true
    }
  )

  const handleDownload = async (
    id: number,
    name: string,
    setDownloadState: any,
    newExport?: boolean
  ): Promise<void> => {
    setDownloadState(true);
    try {
      if (newExport) {
        await downloadExport({ id, name })
      } else {
        await generateCSVExport({
          exportId: id,
          name,
        });
      }
    } catch (error) {
      console.error(`Error downloading export with id ${id}:`, error);
    } finally {
      setDownloadState(false);
    }
  };

  const COLUMNS: Array<Column<TableExport>> = [
    {
      Header: <CenteredCell header>Export ID</CenteredCell>,
      name: "Export ID",
      key: "id",
      accessor: (e: Export) => <CenteredCell>{e.id}</CenteredCell>,
    },
    {
      Header: <CenteredCell header>File Name</CenteredCell>,
      name: "File Name",
      key: "fileName",
      accessor: (e: Export) => <CenteredCell>{e.name}</CenteredCell>,
    },
    {
      Header: <CenteredCell header>Submission Period</CenteredCell>,
      name: "Submission Period",
      key: "submissionPeriod",
      accessor: (e: Export) => (
        <CenteredCell>{e.submissionPeriod.name}</CenteredCell>
      ),
    },
    {
      Header: <CenteredCell header>Type</CenteredCell>,
      name: "Type",
      key: "exportType",
      accessor: (e: Export) => {
        return (
          <CenteredCell>
            {
              e.exportType === ExportType.FullSDWIS && "SDWIS - All"
            }
            {
              e.exportType === ExportType.MostRecentSDWIS && "SDWIS - New"
            }
            {
              e.exportType === ExportType.PWSSubmissionPeriod && "Submissions"
            }
          </CenteredCell>
        );
      },
    },
    {
      Header: <CenteredCell header># of Utilities</CenteredCell>,
      name: "# of Utilities",
      key: "rowCount",
      accessor: (e: Export) => <CenteredCell>{e.exportType === ExportType.PWSSubmissionPeriod ? e.rowCount ?? 'N/A' : e.rowCount / 4}</CenteredCell>,
    },
    {
      Header: <CenteredCell header>Exporter</CenteredCell>,
      name: "Exporter",
      key: "exporter",
      accessor: (e: Export) => <CenteredCell>{e.createdBy}</CenteredCell>,
    },
    {
      Header: <CenteredCell header>Started At</CenteredCell>,
      name: "Started At",
      key: "createdOn",
      accessor: (e: Export) => (
        <CenteredCell>{formatDateTime(e.createdOn)}</CenteredCell>
      ),
    },
    {
      Header: <CenteredCell header>Status</CenteredCell>,
      name: "Status",
      key: "status",
      accessor: (e: Export) => (
        <CenteredCell>
          <ExportStatusBadge status={e.status} errors={e.errors} />
        </CenteredCell>
      ),
    },
    {
      Header: <CenteredCell header>Download</CenteredCell>,
      name: "Download",
      key: "download",
      accessor: (e: Export) => {
        if (!e.name || e.name === 'N/A') return

        return (
          <CenteredCell>
            <div
              onClick={(p) => {
                p.preventDefault();
                void handleDownload(e.id, e.name, setDownloadState, e.newExport);
              }}
              style={{ cursor: "pointer" }}
            >
              {downloadState ? <Loader /> : <DownloadIcon />}
            </div>
          </CenteredCell>
        );
      },
    },
  ];

  const mergedExports = [...response, ...exportServiceData]
                        .sort((a, b) => +new Date(b.createdOn) - +new Date(a.createdOn))
                        .map(item => {
                          if ('fileName' in item) {
                            return ({
                              id: item.id,
                              createdOn: item.createdOn,
                              createdBy: item.metaData?.createdByEmail,
                              name: item?.fileName ?? 'N/A',
                              exportType: item.type,
                              rowCount: item.rowCount,
                              status: item.status,
                              errors: [],
                              submissionPeriod: {
                                name: item.metaData?.submissionPeriodName
                              },
                              newExport: true
                             })
                          }
                          return item
                        });

  return (
    <div>
      <h2>Export Log</h2>
      <Table data={mergedExports as []} columns={COLUMNS} />;
    </div>
  );
};

export default ExportLog;
