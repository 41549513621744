import styled from "styled-components";

const Column = styled.div`
  display: flex;
  flex: 1;
  gap: "0.5rem";
  flex-direction: column;
`;

export default Column;
