import React from "react";
import EmptyStateCard from "src/components/EmptyStateCard";
import { Icon } from "semantic-ui-react";

const NoComments: React.FC = () => {
  return (
    <EmptyStateCard
      icon={<Icon name="comments" size="huge" />}
      style={{
        paddingTop: "0px",
        paddingBottom: "10px",
      }}
      title="No Comments at this time."
    />
  );
};

export default NoComments;
