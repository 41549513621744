import React from "react";
import { Divider } from "semantic-ui-react";
import styled from "styled-components";

import {
  BoldSpan,
  DoubleInputGrid,
  GeneralContainer,
  Value,
  QuestionTitle,
  FlexDiv,
} from "../../shared";

interface Props {
  additionalInfo: { [key: string]: any };
}

const EpaMethodsPart3: React.FC<Props> = ({ additionalInfo }) => {
  const {
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices,
    inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation,
    inventoryMethodsPart3InterpolationExplanation,
    inventoryMethodsPart3SequentialExplanation
  } = additionalInfo;

  const hasVisualInspectionAtPit =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "Visual Inspection at the Meter Pit"
    );
  const hasCurbBoxExternal =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "CCTV Inspection at Curb Box - External"
    );
  const hasCurbBoxInternal =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "CCTV Inspection at Curb Box - Internal"
    );

  const hasWaterSamplingSequential =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "Water Quality Sampling - Sequential"
    );

  const hasMechanicalExcavation =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "Mechanical Excavation"
    );
  const hasVacuumExcavation =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "Vacuum Excavation"
    );
  const hasInterpolation =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "Interpolation"
    );

  const hasNoFieldVerification =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "No field verification work done"
    );

  const hasVisual =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes("Visual");

  const hasVisualSwab =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "Visual - Swab"
    );

  const hasVisualInspectionAtInsideBuilding =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes(
      "Visual Inspection at/inside Building"
    );

  const hasOther =
    inventoryMethodsPart3Q1ServiceLineInvestigationChoices?.includes("Other");

  return (
    <GeneralContainer>
      <div>
        <p style={{ marginBottom: "0px" }}>
          {`Identify the service line investigation methods your system used to
        prepare the inventory (check all that apply). If a water system chooses
        an investigation method not specified by the state under 40 CFR
        §141.84(a)(3)(iv), state approval is required. `}
          <BoldSpan>
            Note that investigations are not required by the LCRR but can be
            used by systems to assess accuracy of historical records and gather
            information when service line material is unknown.
          </BoldSpan>
        </p>
        <DoubleInputGrid>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasVisualInspectionAtPit} />
            <label>Visual Inspection at the Meter Pit</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasCurbBoxExternal} />
            <label>CCTV Inspection at Curb Box - External</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasCurbBoxInternal} />
            <label>CCTV Inspection at Curb Box - Internal</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasWaterSamplingSequential} />
            <label>Water Quality Sampling - Sequential</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasMechanicalExcavation} />
            <label>Mechanical Excavation</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasVacuumExcavation} />
            <label>Vacuum Excavation</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasInterpolation} />
            <label>Interpolation</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasNoFieldVerification} />
            <label>No field verification work done</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasVisual} />
            <label>Visual</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasVisualSwab} />
            <label>Visual - Swab</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input
              type="checkbox"
              checked={hasVisualInspectionAtInsideBuilding}
            />
            <label>Visual Inspection at/inside Building</label>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <input type="checkbox" checked={hasOther} />
            <label>Other</label>
          </StyledFlexDiv>
        </DoubleInputGrid>
      </div>
      <Divider />
      <div>
        <QuestionTitle>
          If &quot;sequential sampling&quot;, was the approved guidance followed?
          Sequential sampling was used as a basis of material classification to determine the presence of lead as described below:
          Minimum of ten 1 liter sequential samples from a single ground floor source (interior, usually kitchen, sink faucet with cold water) to determine if a single service line is lead, additional samples may be needed based on pipe volume to reach the service line
          Before the first sample is drawn the lines must be stagnant for a minimum of 8 hours
          Results from testing that demonstrate a combined lead mass in the total samples of 5 µg or greater indicate a lead line
          Systems must provide an approximation of pipe volume to reach the service line, a map to show where the samples are coming from, and the lab results for every sample in an appendix to the water system narrative before collecting samples.
          Every sample drawn and used as a basis of material classification must be included in the appendix.
          This method is only applicable for service connections that can reliably perform the required stagnation period, for example single family residences or a vacant multi family residence.
        </QuestionTitle>
        <Value>{inventoryMethodsPart3SequentialExplanation ?? "--"}</Value>
      </div>
      <Divider />
      <div>
        <QuestionTitle>
          If “interpolation”, were the following used to properly interpolate?
          Selected area is a homogenous community with less than 1,500 service
          connections AND two or more similar factors including: construction
          year, location, or contractor/developer. ADEQ requires 20 percent of
          the total service lines (randomly selected) in the homogenous area to
          be visually or physically investigated with a 95% confidence rate to
          produce accurate results for the area.
        </QuestionTitle>
        <Value>{inventoryMethodsPart3InterpolationExplanation ?? "--"}</Value>
      </div>
      <Divider />
      <div>
        <QuestionTitle>If other please explain</QuestionTitle>
        <Value>
          {inventoryMethodsPart3Q1ServiceLineInvestigationOtherExplanation ??
            "--"}
        </Value>
      </div>
      <Divider />
    </GeneralContainer>
  );
};

const StyledFlexDiv = styled(FlexDiv)`
  pointer-events: none;
`;

export default EpaMethodsPart3;
