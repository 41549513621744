import styled from "styled-components";

const PageHeader = styled.h1`
  &&& {
    font-weight: 700;
    font-size: 30px;
  }
`;

export default PageHeader;
