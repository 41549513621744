import React from "react";
import styled from "styled-components";

import Loader from "src/components/Loader";

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 4rem;
`;

export const LoadingIndicatorPage: React.FC = () => {
  return (
    <LoadingContainer>
      <Loader size="4x" />
    </LoadingContainer>
  );
};

export default LoadingIndicatorPage;
