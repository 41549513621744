import { Button } from "@120wateraudit/waterworks";
import dayjs from "dayjs";
import { ValidationErrors } from "final-form";
import pluralize from "pluralize";
import React, { useMemo } from "react";
import { Form, useField } from "react-final-form";
import styled from "styled-components";

import Row from "src/components/Row";
import { useUpsertSubmissionPeriodMutation } from "src/services";
import SubmissionPeriod, {
  PERIOD_NAME_REGEX,
} from "src/types/SubmissionPeriod";
import System from "src/types/System";
import { toastError, toastSuccess } from "src/utils/toast";
import { ExistingSubmissionPeriod } from "./ExistingSubmissionPeriod";
import { NewSubmissionPeriod } from "./NewSubmissionPeriod";

interface Values {
  isNew: boolean;
  submissionPeriod?: SubmissionPeriod;
}

const validate = (values: Values): ValidationErrors => {
  const errors: ValidationErrors = {};
  if (!values.submissionPeriod) {
    errors.submissionPeriod = "A Submission Period is required";
    // Return early because there is nothing else to validate here.
    return errors;
  }

  const { submissionPeriod } = values;
  errors.submissionPeriod = {};
  if (!submissionPeriod.name?.trim()) {
    errors.submissionPeriod.name = "A Name is required";
  } else if (!PERIOD_NAME_REGEX.test(submissionPeriod.name)) {
    errors.submissionPeriod.name =
      "Names should have the format of Q{N} - YYYY or YYYY";
  }

  if (
    !submissionPeriod.startDate ||
    !dayjs(submissionPeriod.startDate).isValid()
  ) {
    errors.submissionPeriod.startDate = "Start Date must be a valid date";
  }

  if (!submissionPeriod.dueDate || !dayjs(submissionPeriod.dueDate).isValid()) {
    errors.submissionPeriod.dueDate = "Due Date must be a valid date";
  }

  return errors;
};

const transform = (
  submissionPeriod: SubmissionPeriod
): Omit<SubmissionPeriod, "id"> & { id?: number } => ({
  ...submissionPeriod,
  dueDate: dayjs(submissionPeriod.startDate).toISOString(),
  name: submissionPeriod.name.trim(),
  startDate: dayjs(submissionPeriod.startDate).toISOString(),
});

interface SubmissionModalProps {
  systemsMissingSubmissionPeriods: System[];
  warningCount: number;
  onClose: () => void;
}

const CancelNewSubmissionPeriodButton: React.FC = () => {
  const field = useField("isNew");
  const periodField = useField("submissionPeriod");
  const onClick = (): void => {
    field.input.onChange(false);
    periodField.input.onChange(undefined);
  };
  return <Button onClick={onClick}>Back</Button>;
};

const SubmissionPeriodModal = ({
  onClose,
  systemsMissingSubmissionPeriods,
  warningCount,
}: SubmissionModalProps): JSX.Element => {
  const initialValues: Values = useMemo(
    () => ({
      isNew: false,
    }),
    []
  );
  const [assignSubmissionPeriod] = useUpsertSubmissionPeriodMutation();
  const submit = async (values: Values): Promise<void> => {
    try {
      await assignSubmissionPeriod(
        // The form is invalid and cannot be submitted if this is null.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        transform(values.submissionPeriod!)
      ).unwrap();
      toastSuccess(
        `${pluralize(
          "Submission Period",
          warningCount
        )} were assigned to ${pluralize("Utilities", warningCount, true)}.`
      );
      onClose();
    } catch {
      // TODO better error message
      toastError("Submission Periods could not be assigned. Please try again.");
    }
  };

  return (
    <SubmissionModalContentContainer>
      <Form
        initialValues={initialValues}
        onSubmit={submit}
        render={({ values: { isNew }, valid, handleSubmit }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                void handleSubmit();
              }}
            >
              <SubmissionUpdateAndCreateContainer>
                <h1>Assign Submission Period to {warningCount} Utilities</h1>
                {!isNew && (
                  <ExistingSubmissionPeriod
                    systemsToAssign={systemsMissingSubmissionPeriods}
                  />
                )}
                {isNew && <NewSubmissionPeriod />}
              </SubmissionUpdateAndCreateContainer>
              <Row style={{ gap: "8px", justifyContent: "flex-end" }}>
                {!isNew && <Button onClick={onClose}>Cancel</Button>}
                {isNew && <CancelNewSubmissionPeriodButton />}
                <Button type="submit" disabled={!valid} variant="primary">
                  Assign
                </Button>
              </Row>
            </form>
          );
        }}
        validate={validate}
      />
    </SubmissionModalContentContainer>
  );
};

const SubmissionModalContentContainer = styled.div`
  margin: 2rem;
`;

const SubmissionUpdateAndCreateContainer = styled.div`
  text-align: center;
  margin: 1.5rem;
`;

export default SubmissionPeriodModal;
