import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "src/pages/Dashboard";
import InventoryPage from "src/pages/Submission";
import NotFound from "src/pages/NotFound";
import { SubmissionDetails } from "src/pages/SubmissionDetails";
import UtilitiesPage from "src/pages/Utilities";
import UtilityDetailsPage from "src/pages/UtilityDetails";
import { SubmissionServiceLineDetails } from "src/pages/SubmissionServiceLineDetails";
import Layout from "./Layout";
import AccountSettingsPage from "src/pages/Administration";

interface Props {
  isLoading?: boolean;
}

const AppRoutes: React.FC<Props> = ({ isLoading = false }) => {
  return (
    <Layout isLoading={isLoading}>
      <Routes>
        <Route path={"/"} element={<Navigate to="/insights" />} />
        <Route element={<Dashboard />} path="/insights" />
        <Route element={<InventoryPage />} path="/submissions" />
        <Route element={<SubmissionDetails />} path="/submissions/:id" />
        <Route
          element={<SubmissionServiceLineDetails />}
          path="/submissions/:submissionId/servicelines/:serviceLineId"
        />
        <Route element={<UtilitiesPage />} path="/utilities" />
        <Route element={<UtilityDetailsPage />} path="/utilities/:id" />
        <Route element={<NotFound />} path="*" />

        <Route
          element={<AccountSettingsPage />}
          path="/accountSettings/*"
        ></Route>
      </Routes>
    </Layout>
  );
};

export default AppRoutes;
