import React, { CSSProperties } from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";

import { FieldInput } from "./FieldInput";

type Props = FieldRenderProps<string, HTMLElement> & {
  className?: string;
  label?: string;
  style?: CSSProperties;
};

export const DatePicker: React.FC<Props> = ({
  className,
  input,
  label,
  meta: { error },
  style,
}) => {
  return (
    <Wrapper>
      <FieldInput
        className={className}
        error={error}
        style={style}
        {...input}
        label={label}
        name={input.name}
        onChange={input.onChange}
        type="date"
        value={input.value}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 24px;
`;
