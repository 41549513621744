import React, { FC } from "react";
import { Sidebar as SUIRSidebar, SidebarProps } from "semantic-ui-react";
import styled from "styled-components";

import ExternalLink from "src/components/ExternalLink";
import Row from "src/components/Row";

interface Props {
  children: React.ReactNode;
  visible: boolean;
  width: SidebarProps["size"];
  onClose: () => void;
}

const Drawer: React.FC<Props> = ({ children, onClose, visible, width }) => {
  return (
    <Sidebar
      animation="overlay"
      direction="right"
      onHidden={onClose}
      vertical
      visible={visible}
      width={width}
    >
      {children}
    </Sidebar>
  );
};

const Sidebar = styled(SUIRSidebar)`
  &&& {
    background-color: white;
    top: 3%;
    height: 95% !important;
    border-radius: 4px 0 0 4px !important;
    overflow: auto;
    scrollbar-gutter: stable;
  }
`;

interface ContentsProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  title?: string;
}

export const DrawerContents: FC<ContentsProps> = ({
  children,
  header,
  title,
}) => {
  const hasHeader = !!header || !!title;
  return (
    <Column>
      {hasHeader && <HeaderRow>{header ?? <h3>{title}</h3>}</HeaderRow>}
      {children}
    </Column>
  );
};

const Column = styled(Row)`
  flex-direction: column;
  align-items: unset;
  margin: 0 0 12px 0;
`;

const HeaderRow = styled(Row)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
  & h3 {
    margin: 0;
    font-size: 20px;
    line-height: 1.1;
  }
`;

interface HeaderProps {
  children: React.ReactNode;
  title: string;
  to?: string;
}

export const Header: FC<HeaderProps> = ({ children, title, to }) => {
  return (
    <>
      <div>
        <h3>{title}</h3>
        {to && <ExternalLink to={to}>View All Details</ExternalLink>}
      </div>
      <Row style={{ gap: "8px" }}>{children}</Row>
    </>
  );
};

export default Drawer;
