import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import styled from "styled-components";

const NoContact: React.FC = () => {
  return (
    <Container>
      <FontAwesomeIcon size="4x" icon={solid("address-card")} />
      <p>No contact was found. Please create one above if desired.</p>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  & p {
    margin-top: 15px;
    font-weight: 500;
  }
`;

export default NoContact;
