import React from "react";
import { Accordion, Icon } from "semantic-ui-react";
import { useToggle } from "src/hooks/useToggle";
import InventoryInformation from "./InventoryInformation";
import SignatureStep from "../EPA/SignatureStep";

interface Props {
  additionalInfo: { [key: string]: string };
}

export const GeorgiaAccordion: React.FC<Props> = ({ additionalInfo }) => {
  const [isInventoryInformationOpen, toggleInventoryInformationOpen] =
    useToggle();
  const [isSignatureOpen, toggleSignatureOpen] = useToggle();

  return (
    <Accordion>
      <Accordion.Title
        active={isInventoryInformationOpen}
        style={{ fontSize: "14px" }}
        onClick={() => toggleInventoryInformationOpen()}
      >
        <Icon name="dropdown" />
        Inventory Information
      </Accordion.Title>
      <Accordion.Content active={isInventoryInformationOpen}>
        <InventoryInformation additionalInfo={additionalInfo} />
      </Accordion.Content>
      <Accordion.Title
        active={isSignatureOpen}
        onClick={() => toggleSignatureOpen()}
      >
        <Icon name="dropdown" />
        Signature Info
      </Accordion.Title>
      <Accordion.Content active={isSignatureOpen}>
        <SignatureStep additionalInfo={additionalInfo} />
      </Accordion.Content>
    </Accordion>
  );
};

export default GeorgiaAccordion;
