import Sort from "src/types/Sort";
import SortDirection from "src/types/SortDirection";

export interface ApiResponse<T> {
  data: T;
}
export interface PaginatedResponse<T> {
  count: number;
  data: T[];
  pageNumber: number;
  pageSize: number;
  pages: number;
}

export type ValidFilterValue =
  | undefined
  | Array<string | number | boolean>
  | boolean
  | number
  | string;
export type Filters = Record<string, undefined | ValidFilterValue>;

export interface SearchParams {
  filters: Filters;
  include?: string;
  page: number;
  pageSize: number;
  searchTerm?: string;
  sort?: Sort | string;
}

export const convertSortToString = (sort: Sort): string => {
  return sort.direction === SortDirection.DESC ? `-${sort.key}` : sort.key;
};

interface BetweenFilter {
  operator?: string;
  value: ValidFilterValue;
  value2?: ValidFilterValue;
}

export const convertFilter = (
  key: string,
  value: ValidFilterValue
): { key: string; value: string } | undefined => {
  let filter;
  if (typeof value === "string") {
    try {
      const possibleJSON: BetweenFilter = JSON.parse(value);
      if (typeof possibleJSON === "object") {
        if (
          possibleJSON.operator === "between" &&
          (possibleJSON.value === 0 || possibleJSON.value) &&
          possibleJSON.value2
        ) {
          filter = {
            key: `filter[${key}][${possibleJSON.operator}]`,
            value: [possibleJSON.value, possibleJSON.value2].join(","),
          };
        } else if (possibleJSON.value) {
          filter = {
            key: `filter[${key}][${possibleJSON.operator ?? ""}]`,
            value: `${possibleJSON.value?.toString() ?? ""}`,
          };
        } else if (possibleJSON.value === 0) {
          filter = {
            key: `filter[${key}][${possibleJSON.operator ?? ""}]`,
            value: `${possibleJSON.value?.toString()}`,
          };
        }
      } else {
        filter = {
          key: `filter[${key}]`,
          value: `${value}`,
        };
      }
    } catch {
      // Not valid JSON, just give it to the filter
      filter = {
        key: `filter[${key}]`,
        value: `${value}`,
      };
    }
  } else if (value !== undefined && value !== null) {
    filter = {
      key: `filter[${key}]`,
      value: `${value.toString()}`,
    };
  }
  return filter;
};

export const addFilters = (
  params: URLSearchParams,
  activeFilters: Filters
): URLSearchParams => {
  const filterEntries = Object.entries(activeFilters);
  filterEntries.forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      const converted = convertFilter(key, value);
      if (converted) {
        params.set(converted.key, converted.value);
      }
    }
  });
  return params;
};

export const buildParameters = ({
  filters,
  include,
  page,
  pageSize,
  searchTerm,
  sort,
}: Partial<SearchParams>): URLSearchParams => {
  const params = new URLSearchParams();
  if (page) {
    params.set("pageNumber", (page - 1).toString());
  }
  if (pageSize) {
    params.set("pageSize", pageSize.toString());
  }
  if (searchTerm) {
    params.set("search", searchTerm);
  }
  if (sort && typeof sort === "object") {
    params.set("sort", convertSortToString(sort));
  } else if (sort && typeof sort === "string") {
    params.set("sort", sort);
  }
  if (include) {
    params.set("include", include);
  }
  if (filters) {
    addFilters(params, filters);
  }
  return params;
};

export default buildParameters;
