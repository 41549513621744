import React, { createContext, useContext, useReducer, useRef } from "react";
import styled from "styled-components";

export interface TooltipArgs {
  x: number;
  y: number;
  content: React.ReactNode | null;
}
interface TooltipAction {
  id: number;
  type: "position" | "content";
  x?: number;
  y?: number;
  content?: React.ReactNode | null;
}
const TooltipContext = createContext<{ [key: number]: TooltipArgs }>({});
const TooltipDispatchContext = createContext<React.Dispatch<TooltipAction>>(
  () => {}
);

export const Tooltip = styled.div<{
  x: number;
  y: number;
}>`
  position: absolute;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  width: max-content;
  max-width: 200px;
`;

export function useTooltip(): {
  setTooltipPosition: (x: number, y: number) => void;
  setTooltipContent: (content: React.ReactNode | null) => void;
} {
  const context = useContext(TooltipContext);
  const dispatch = useContext(TooltipDispatchContext);

  const id = useRef(
    ((ctx) => {
      let id = Math.floor(Math.random() * 1000);
      while (ctx[id]) {
        id = Math.floor(Math.random() * 1000);
      }
      return id;
    })(context)
  );

  const setTooltipPosition = (x: number, y: number): void => {
    dispatch({
      id: id.current,
      x,
      y,
      type: "position",
    });
  };

  const setTooltipContent = (content: React.ReactNode | null): void => {
    dispatch({
      id: id.current,
      type: "content",
      content,
    });
  };

  return { setTooltipPosition, setTooltipContent };
}

function tooltipReducer(
  state: { [key: number]: TooltipArgs },
  action: TooltipAction
): { [key: number]: TooltipArgs } {
  switch (action.type) {
    case "position":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          x: action.x ?? state[action.id].x,
          y: action.y ?? state[action.id].y,
        },
      };
    case "content":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          content: action.content,
        },
      };
    default:
      return state;
  }
}

export const TooltipProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [context, dispatch] = useReducer(tooltipReducer, {});
  return (
    <>
      {Object.entries(context)
        .filter(([_, value]) => value.content)
        .map(([key, value]) => {
          return (
            <Tooltip key={`tooltip-${key}`} x={value.x} y={value.y}>
              {value.content}
            </Tooltip>
          );
        })}
      <TooltipContext.Provider value={context}>
        <TooltipDispatchContext.Provider value={dispatch}>
          {children}
        </TooltipDispatchContext.Provider>
      </TooltipContext.Provider>
    </>
  );
};
