import React, { useContext, useState } from "react";
import { Dropdown } from "@120wateraudit/waterworks";
import styled from "styled-components";
import { ModalContext } from "src/modules/Modal";
import CommentSection from "../CommentSection";
import {
  useGetAllSubmissionPeriodCommentsQuery,
  useGetLatestSubmissionQuery,
} from "src/services";
import CommentForm from "../CommentForm";
import LoadingIndicatorPage from "src/components/LoadingIndicatorPage";
import NoComments from "../NoComments";
import { isWrite } from "src/modules/User";

interface SubmissionPeriodActivityProps {
  systemId: string;
}

const SystemTableActivityModal = ({
  systemId,
}: SubmissionPeriodActivityProps): JSX.Element => {
  const { data: queryResponse, isFetching: isFetchingData } =
    useGetLatestSubmissionQuery(systemId ?? "");

  const latestSubmission = queryResponse?.data;
  const {
    id: latestSubmissionId,
    submissionPeriod: latestSubmissionPeriod,
    primacyAgencyId,
  } = latestSubmission ?? {};
  const { id: latestSubmissionPeriodId } = latestSubmissionPeriod ?? {};

  const { closeModal } = useContext(ModalContext);
  const [activityFilter, setActivityFilter] = useState("all");

  const { data: response, isFetching } = useGetAllSubmissionPeriodCommentsQuery(
    {
      submissionPeriodId: latestSubmissionPeriodId,
      pwsId: parseInt(systemId, 10),
    },
    { skip: !latestSubmissionPeriodId }
  );

  const comments = response?.data ?? [];

  if (isFetching || isFetchingData) {
    return <LoadingIndicatorPage />;
  }

  return (
    <ModalContentContainer>
      {comments.length > 0 && (
        <Dropdown
          style={{ gap: "0px" }}
          onChange={(event) => setActivityFilter(event.target.value)}
          options={[
            { text: "Show All Activity", value: "all" },
            { text: "Show Comments Only", value: "comment" },
            { text: "Show Notifications Only", value: "notification" },
          ]}
          value={activityFilter}
        />
      )}
      {comments.length === 0 && <NoComments />}
      {comments.length > 0 && (
        <CommentSection
          activities={comments}
          activityFilter={activityFilter}
          onClose={closeModal}
        />
      )}
      {primacyAgencyId && latestSubmissionId && latestSubmissionPeriodId && isWrite() && (
        <CommentForm
          pwsId={parseInt(systemId, 10)}
          primacyAgencyId={primacyAgencyId}
          submissionPeriodId={latestSubmissionPeriodId}
          submissionId={latestSubmissionId}
        />
      )}
    </ModalContentContainer>
  );
};

const ModalContentContainer = styled.div`
  margin: 2rem;
`;

export default SystemTableActivityModal;
