/* eslint-disable @typescript-eslint/no-invalid-void-type */

import { createApi } from "@reduxjs/toolkit/query/react";

import buildParameters, { PaginatedResponse } from "./utils";
import { Attachment } from "src/types/Attachment";
import baseQuery from "./baseQuery";
import { Tag } from "./tags";
import { SearchParams } from "src/services";

interface GetParams extends SearchParams {
  includeChildren?: boolean;
  entityId1: number;
  entityName1:
  | "inventory"
  | "locations"
  | "pwsSamples"
  | "assets"
  | "submissions";
  entityId2: number;
  entityName2:
  | "inventory"
  | "locations"
  | "pwsSamples"
  | "assets"
  | "submissions";
}

interface GetAttachmentsByAssetIdParams extends SearchParams {
  assetId: number;
  submissionId: number;
}

interface GetAttachmentsBySubmissionIdParams extends SearchParams {
  submissionId: number;
}

interface GetAttachmentsByTypeParams extends SearchParams {
  includeChildren?: boolean;
  relatedEntityId: number;
  relatedEntityType:
    | "inventory"
    | "locations"
    | "pwsSamples"
    | "assets"
    | "submissions";
}

interface GetCommunicationAttachmentUrlParams {
  entity: string;
  pwsSubmissionPeriodId: number;
  accountId: number;
}

export const attachmentsApi = createApi({
  baseQuery: baseQuery({
    baseUrl: `/platform/attachment`,
  }),
  reducerPath: "attachmentsApi",
  tagTypes: [Tag.Attachments, Tag.SubmissionCommunications],
  endpoints: (builder) => ({
    getAttachmentsByAssetId: builder.query<
      PaginatedResponse<Attachment>,
      GetAttachmentsByAssetIdParams
    >({
      query: ({
        filters,
        page,
        pageSize,
        searchTerm,
        assetId,
        submissionId,
        sort,
      }) => {
        const params = buildParameters({
          filters,
          page,
          pageSize,
          searchTerm,
          sort,
        });
        return {
          method: "get",
          url: `/asset/${assetId}/submission/${submissionId}/?${params.toString()}`,
        };
      },
    }),
    getAttachmentsBySubmissionId: builder.query<
      PaginatedResponse<Attachment>,
      GetAttachmentsBySubmissionIdParams
    >({
      query: ({ filters, page, pageSize, searchTerm, submissionId, sort }) => {
        const params = buildParameters({
          filters,
          page,
          pageSize,
          searchTerm,
          sort,
        });
        return {
          method: "get",
          url: `/submission-attachments/${submissionId}/?${params.toString()}`,
        };
      },
    }),
    getAttachments: builder.query<PaginatedResponse<Attachment>, GetParams>({
      providesTags: [Tag.Attachments],
      query: ({
        filters,
        includeChildren,
        page,
        pageSize,
        entityId1,
        entityName1,
        entityId2,
        entityName2,
        searchTerm,
        sort,
      }) => {
        const params = buildParameters({
          filters,
          page,
          pageSize,
          searchTerm,
          sort,
        });
        params.set("includeChildren", includeChildren ? "true" : "false");
        params.set("isStateDashboard", "true");
        return {
          method: "get",
          url: `/${entityName1}/${entityId1}/${entityName2}/${entityId2}?${params.toString()}`,
        };
      },
    }),
    getAttachmentsByType: builder.query<
      PaginatedResponse<Attachment>,
      GetAttachmentsByTypeParams
    >({
      providesTags: [Tag.Attachments],
      query: ({
        filters,
        includeChildren,
        page,
        pageSize,
        relatedEntityId,
        relatedEntityType,
        searchTerm,
        sort,
      }) => {
        const params = buildParameters({
          filters,
          page,
          pageSize,
          searchTerm,
          sort,
        });
        params.set("includeChildren", includeChildren ? "true" : "false");
        params.set("isStateDashboard", "true");
        return {
          method: "get",
          url: `/${relatedEntityType}/${relatedEntityId}?${params.toString()}`,
        };
      },
    }),
    getAttachmentUrl: builder.query<string, number>({
      providesTags: [Tag.Attachments],
      query: (attachmentId) => {
        return {
          method: "GET",
          url: `/${attachmentId}/file/stateDashboard`,
          responseHandler: (response: { text: () => string }) =>
            response.text(),
        };
      },
    }),
    getCommunicationAttachments: builder.query<
      { url: string },
      GetCommunicationAttachmentUrlParams
    >({
      providesTags: [Tag.Attachments],
      query: ({ entity, pwsSubmissionPeriodId, accountId }) => {
        return {
          method: "GET",
          url: `submission-attachments/communications/${entity}/${pwsSubmissionPeriodId}/${accountId}`,
          responseHandler: async (response: {
            text: () => Promise<string>;
          }) => {
            const text: string = await response.text();
            return JSON.parse(text);
          },
        };
      },
    }),
  }),
});

export const {
  useGetAttachmentsByAssetIdQuery,
  useGetAttachmentsBySubmissionIdQuery,
  useGetAttachmentsQuery,
  useGetAttachmentsByTypeQuery,
  useGetAttachmentUrlQuery,
  useGetCommunicationAttachmentsQuery,
} = attachmentsApi;
