import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ActiveFilters,
  Sort,
  TableProps,
  ValidFilterValue,
} from "@120wateraudit/waterworks";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchParams } from "src/services";

type OnFilterChangeFunc = Required<
  TableProps<Record<string, unknown>>
>["onFilterChanged"];

type UseFiltersContext = [
  ActiveFilters,
  OnFilterChangeFunc,
  (filters: ActiveFilters) => void
];

export const useFilters = (
  defaultFilters?: ActiveFilters
): UseFiltersContext => {
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>(
    defaultFilters ?? {}
  );
  const onFilterChanged: OnFilterChangeFunc = ({ key, value }) => {
    if (value === null) {
      const newFilters = { ...activeFilters };
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete newFilters[key];
      setActiveFilters(newFilters);
    } else {
      setActiveFilters({
        ...activeFilters,
        [key]: value,
      });
    }
  };

  return [activeFilters, onFilterChanged, setActiveFilters];
};

export interface TableContext {
  params: SearchParams;
  setFilters: OnFilterChangeFunc;
  setActiveFilters: (filters: ActiveFilters) => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setSearchTerm: (search?: string) => void;
  setSort: (sort?: Sort) => void;
}

export const useTableState = (
  defaultFilters?: ActiveFilters,
  deeplink = true
): TableContext => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [sort, setSort] = useState<Sort>();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [activeFilters, setFilters] = useState(defaultFilters ?? {});

  const [activeFiltersShallow, setFiltersShallow, setActiveFiltersShallow] =
    useFilters(defaultFilters);

  const params = useMemo(
    () => ({
      filters: deeplink ? activeFilters : activeFiltersShallow,
      page,
      pageSize,
      searchTerm,
      sort,
    }),
    [
      activeFilters,
      page,
      pageSize,
      sort,
      searchTerm,
      deeplink,
      activeFiltersShallow,
    ]
  );

  useEffect(() => {
    if (!deeplink) {
      return;
    }
    const params = new URLSearchParams(search);
    const filters =
      params.get("filters") && JSON.parse(params.get("filters") ?? "undefined");
    const sort = params.get("sort") && JSON.parse(params.get("sort") ?? "{}");
    const page = params.get("page") && parseInt(params.get("page") ?? "1");
    const pageSize = parseInt(params.get("pageSize") ?? "20");
    const searchTerm = params.get("searchTerm");
    filters && setFilters(filters);
    sort && setSort(sort);
    page && setPage(page);
    params.get("pageSize") && setPageSize(pageSize);
    searchTerm && setSearchTerm(searchTerm);
  }, [setFilters, setPageSize, setPage, setSort, setSearchTerm]);

  const setSortDeeplink = useCallback(
    (sort?: Sort) => {
      setSort(sort);
      const params = new URLSearchParams(search);
      if (sort === undefined) {
        params.delete("sort");
      } else {
        params.set("sort", JSON.stringify(sort));
      }
      navigate({ search: params.toString() }, { replace: true });
    },
    [setSort, search]
  );

  const setFiltersDeeplink = useCallback<OnFilterChangeFunc>(
    ({ key, value }: { key: string; value?: ValidFilterValue | null }) => {
      setFilters((prev) => {
        let newFilters = { ...prev };
        if (value === null) {
          newFilters = {
            ...Object.fromEntries(
              Object.entries(newFilters).filter(([k]) => k !== key)
            ),
          }; // workaround for no-dynamic-delete
        } else {
          newFilters[key] = value;
        }
        return newFilters;
      });
    },
    [setFilters, activeFilters]
  );

  useEffect(() => {
    const params = new URLSearchParams(search);
    params.set("filters", JSON.stringify(activeFilters));
    navigate({ search: params.toString() }, { replace: true });
  }, [activeFilters]);

  const setSearchTermDeeplink = useCallback(
    (searchTerm?: string) => {
      setSearchTerm(searchTerm);
    },
    [setSearchTerm, search]
  );

  useEffect(() => {
    const params = new URLSearchParams(search);
    if (!searchTerm || searchTerm === "") {
      params.delete("searchTerm");
    } else {
      params.set("searchTerm", searchTerm ?? "");
    }
    navigate({ search: params.toString() }, { replace: true });
  }, [searchTerm]);

  const setPageSizeDeeplink = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
      const params = new URLSearchParams(search);
      params.set("pageSize", pageSize.toString());
      navigate({ search: params.toString() }, { replace: true });
    },
    [setPageSize, search]
  );

  const setPageDeeplink = useCallback(
    (page: number) => {
      setPage(page);
      const params = new URLSearchParams(search);
      params.set("page", page.toString());
      navigate({ search: params.toString() }, { replace: true });
    },
    [setPage, search]
  );

  if (!deeplink) {
    return {
      params,
      setFilters: setFiltersShallow,
      setActiveFilters: setActiveFiltersShallow,
      setPage,
      setPageSize,
      setSearchTerm,
      setSort,
    };
  }

  return {
    params,
    setFilters: setFiltersDeeplink,
    setActiveFilters: setFilters,
    setPage: setPageDeeplink,
    setPageSize: setPageSizeDeeplink,
    setSearchTerm: setSearchTermDeeplink,
    setSort: setSortDeeplink,
  };
};
