import { Badge, IGNIS_RED, AQUA_BLUE } from "@120wateraudit/waterworks";
import React from "react";

export const SERVICE_LINE_STATUS_VALUES: Record<string, string> = {
  Abandoned: "Abandoned",
  InService: "In Service",
  OutOfService: "Out Of Service",
  Stubbed: "Stubbed",
};

export const serviceLineStatusOptions = Object.keys(
  SERVICE_LINE_STATUS_VALUES
).map(key => ({
  id: key,
  text: SERVICE_LINE_STATUS_VALUES[key],
  value: key
}))

export const getServiceLineStatusValue = (status?: string): string => {
  return (status && SERVICE_LINE_STATUS_VALUES[status]) ?? "--";
};

interface StatusBadgeProps {
  status: string;
  style?: React.CSSProperties;
}

export const ServiceLineStatusBadge: React.FC<StatusBadgeProps> = ({
  status,
  style,
}) => {
  const statusColor = status === "InService" ? AQUA_BLUE : IGNIS_RED;
  const statusLabel = getServiceLineStatusValue(status);
  return (
    <Badge
      style={{
        ...style,
        justifyContent: "center",
        textAlign: "center",
        minWidth: "80px",
      }}
      backgroundColor={statusColor}
    >
      {statusLabel}
    </Badge>
  );
};

export default ServiceLineStatusBadge;
