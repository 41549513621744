import {
  AQUA_BLUE,
  Card,
  DARK_BLUE,
  LIGHTEST_GRAY,
  IGNIS_RED,
  Button,
} from "@120wateraudit/waterworks";
import React, { useContext, useCallback } from "react";
import { Message, Placeholder } from "semantic-ui-react";
import styled from "styled-components";

import Row from "src/components/Row";
import {
  useGetLatestSubmissionQuery,
  useSystemHasActiveSubmissionPeriodQuery
} from "src/services";
import Submission from "src/types/Submission";
import { formatDate } from "src/utils/format";
import { StatusBadge } from "./StatusBadge";
import { Link } from "react-router-dom";
import { ModalContext } from "src/modules/Modal";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  systemId?: string;
}

const LatestSubmissionCard: React.FC<Props> = ({
  className,
  style,
  systemId,
}) => {
  const { data: response, isFetching } = useGetLatestSubmissionQuery(
    systemId ?? ""
  );

  const { data: systemHasActiveSubmissionPeriodResponse } = useSystemHasActiveSubmissionPeriodQuery(
    systemId ?? ""
  );

  if (isFetching) {
    return (
      <Card>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="long" />
            <Placeholder.Line length="long" />
            <Placeholder.Line length="medium" />
          </Placeholder.Header>
        </Placeholder>
      </Card>
    );
  }

  const latestSubmission = response?.data;

  if (!systemHasActiveSubmissionPeriodResponse) {
    return (
      <Message
        content="This Utility is not currently required to submit their Lead Service Line Inventory."
        header="No Assigned Submission Period"
        info
      />
    );
  }

  if (!latestSubmission) {
    return null
  }

  const {
    totalServiceLinesGalvanized,
    totalServiceLinesLead,
    totalServiceLinesNonLead,
    totalServiceLinesUnknown,
  } = latestSubmission;

  return (
    <Card className={className} style={style}>
      <Content>
        <SubmissionDetails submission={latestSubmission} />
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: ".75rem",
          }}
        >
          <MaterialMetric
            backgroundColor={IGNIS_RED}
            materialName="Lead"
            metric={totalServiceLinesLead}
          />
          <MaterialMetric
            backgroundColor="#FFC720"
            materialName="Galvanized"
            metric={totalServiceLinesGalvanized}
          />
          <MaterialMetric
            backgroundColor={AQUA_BLUE}
            materialName="Non-lead"
            metric={totalServiceLinesNonLead}
          />
          <MaterialMetric
            backgroundColor={DARK_BLUE}
            materialName="Unknown"
            metric={totalServiceLinesUnknown}
          />
        </div>
      </Content>
    </Card>
  );
};

const SubmissionDetails: React.FC<{ submission: Submission }> = ({
  submission,
}) => {
  const { closeModal, openModal } = useContext(ModalContext);
  const { status, submissionPeriod, submittedByName, submittedOn } = submission;
  const { dueDate, name } = submissionPeriod;

  const showActivityComments = useCallback(
    () =>
      openModal("activityModal", {
        modalSize: "large",
        submission,
        onClose: async () => {
          closeModal();
        },
      }),
    [openModal, submission]
  );
  return (
    <SubmissionInfo>
      <Row style={{ gap: "16px" }}>
        <h1>
          <Link to={`/submissions/${submission.id}`}>{name}</Link>
        </h1>
        <Button onClick={showActivityComments} variant="primary">
          Show Activity
        </Button>
        <StatusBadge status={status} style={{
          borderRadius: ".4rem",
          height: "3rem",
          alignItems: "center",
        }} />
      </Row>
      <div>
        <>
          <h5 style={{ marginTop: 0, fontWeight: 500 }}>
            Submitted By {submittedByName}
          </h5>
          <h5 style={{ marginTop: 0, marginBottom: 0, fontWeight: 500 }}>
            Submitted On {formatDate(submittedOn)}
          </h5>
        </>
        <h5 style={{ marginTop: "14px", marginBottom: 0, fontWeight: 500 }}>
          Due On {formatDate(dueDate)}
        </h5>
      </div>
    </SubmissionInfo>
  );
};

interface MaterialProps {
  backgroundColor?: string;
  materialName: string;
  metric?: number | string;
}

const MaterialMetric: React.FC<MaterialProps> = ({
  backgroundColor,
  materialName,
  metric,
}) => {
  return (
    <MaterialMetricContent
      style={{
        backgroundColor,
      }}
    >
      <h5>{materialName}</h5>
      <h4 style={{ marginTop: 0 }}>
        {metric ?? "--"}
      </h4>
    </MaterialMetricContent>
  );
};

const Content = styled.div`
  display: flex;
  gap: 0.75rem;

  @media (max-width: 800px) {
    gap: 0.25rem;
  }
`;

const SubmissionInfo = styled.div`
  flex: 0.5;
`;

const MaterialMetricContent = styled.div`
  color: white;
  flex: 1;
  border: 1px solid ${LIGHTEST_GRAY};
  text-align: center;
  border-radius: 8px;
  padding: 0.5rem;
  min-width: 90px;

  @media (max-width: 800px) {
    min-width: 80px;
  }
`;

export default LatestSubmissionCard;
