import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import ReactGA from "react-ga4";
import { useCurrentUser } from "src/modules/User";
import LoginErrorPage from "src/pages/Auth0Error";
import { MediaContextProvider } from "./Media";
import Routes from "./Routes";
import "./App.css";

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID ?? "");
}

const App: React.FC = () => {
  const {
    error,
    isAuthenticated,
    isLoading: isAuthLoading,
    loginWithRedirect,
  } = useAuth0();
  const { isLoading: isUserLoading } = useCurrentUser();

  if (!isAuthenticated && !isAuthLoading && error != null) {
    // Something went wrong with authorizing with Auth0.
    // We need to drop the user on a page where they can see the error and try again.
    return <LoginErrorPage />;
  }

  if (!isAuthLoading && !isAuthenticated) {
    void loginWithRedirect();
  }

  // We show the loading screen if we're still fetching any part of the user or
  // if Auth0 isn't authenticated.
  const isLoading = isAuthLoading || isUserLoading || !isAuthenticated;
  return (
    <MediaContextProvider>
      <Routes isLoading={isLoading} />
    </MediaContextProvider>
  );
};

export default App;
