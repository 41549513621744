import { useState } from "react";
import { selectToken } from "src/modules/User/slice";
import { useAppSelector } from "src/store";
import { getAttachmentUrl } from "src/services/fetch-attachments-api";

interface DownloadState {
  errorMessage: string | undefined;
  loading: boolean;
  download: () => Promise<void>;
}

export const useFileDownload = (
  attachmentId: number,
  submissionId: number,
  assetId?: number
): DownloadState => {
  const token = useAppSelector(selectToken);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const download = async (): Promise<void> => {
    setLoadingDownload(true);

    try {
      const s3url = await getAttachmentUrl(
        token,
        attachmentId,
        submissionId,
        assetId
      );

      clickUrlDownload(s3url);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setLoadingDownload(false);
  };
  return { errorMessage, loading: loadingDownload, download };
};

export const clickUrlDownload = (s3url: string): void => {
  if (s3url) {
    const s3urlTag = document.createElement("a");
    s3urlTag.href = s3url;
    s3urlTag.click();
  }
};
