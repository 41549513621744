import {
  Badge,
  IGNIS_RED,
  DARK_BLUE,
  AQUA_BLUE,
} from "@120wateraudit/waterworks";
import React from "react";

const LEAD_MATERIALS = ["Lead", "Lead-Lined Galvanized Iron/Steel"];
const NON_LEAD_MATERIALS = [
  "Asbestos",
  "Cast Iron",
  "Concrete",
  "HDPE",
  "PB",
  "PE",
  "PVC",
  "Steel",
  "No Lead",
  "N/A",
  "N/A - Single Ownership",
  "Wrought Iron",
  "Ductile Iron",
  "Other",
  "Copper",
  "Brass",
  "Plastic",
];
const LEAD_STATUS_UNKNOWN_MATERIALS = [
  "Likely GI",
  "Likely Non-lead",
  "Likely Lead",
  "Unknown",
  "Unknown - Material Unknown",
  "Unknown - Likely Lead",
  "Unknown - Unlikely Lead",
];
const GALVANIZED_MATERIALS = [
  "Galvanized Iron",
  "Galvanized Iron/Steel - Requiring Replacement",
  "Galvanized Iron/Steel - Lead Contact Unknown",
];

const getColorForMaterial = (material: string): string => {
  if (NON_LEAD_MATERIALS.includes(material)) {
    return AQUA_BLUE;
  }

  if (LEAD_STATUS_UNKNOWN_MATERIALS.includes(material)) {
    return DARK_BLUE;
  }

  if (GALVANIZED_MATERIALS.includes(material)) {
    return "#FFC720";
  }

  if (LEAD_MATERIALS.includes(material)) {
    return IGNIS_RED;
  }

  return AQUA_BLUE;
};

interface MaterialBadgeProps {
  material: string;
  inverse?: boolean;
  minWidth?: string;
  padding?: string;
}

export const MaterialBadge: React.FC<MaterialBadgeProps> = ({
  material,
  inverse = false,
  minWidth,
  padding,
}) => {
  return (
    <Badge
      style={{
        justifyContent: inverse ? "left" : "center",
        padding: padding ?? "0px",
        fontWeight: inverse ? "bold" : "normal",
        textAlign: "center",
        minWidth: minWidth ?? "initial",
      }}
      color={inverse ? getColorForMaterial(material) : "white"}
      backgroundColor={inverse ? "white" : getColorForMaterial(material)}
    >
      {material}
    </Badge>
  );
};

export default MaterialBadge;
