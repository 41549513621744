import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";

import HistoricalRecordsReview from "src/modules/AdditionalInfo/Indiana/HistoricalRecordsReview";
import IdentifyingServiceLines from "src/modules/AdditionalInfo/Indiana/IdentifyingServiceLines";
import ServiceLineInvestigations from "src/modules/AdditionalInfo/Indiana/ServiceLineInvestigations";
import Signature from "src/modules/AdditionalInfo/Signature";

interface Props {
  additionalInfo: { [key: string]: any };
}

const IdemAccordion: React.FC<Props> = ({ additionalInfo }) => {
  const [isHistoricalOpen, setIsHistoricalOpen] = useState<boolean>(false);
  const [isServiceLinesOpen, setIsServiceLinesOpen] = useState<boolean>(false);
  const [isInvestigationsOpen, setIsInvestigationsOpen] =
    useState<boolean>(false);
  const [isSignatureOpen, setIsSignatureOpen] = useState<boolean>(false);

  return (
    <Accordion>
      <Accordion.Title
        active={isHistoricalOpen}
        onClick={() => setIsHistoricalOpen(!isHistoricalOpen)}
      >
        <Icon name="dropdown" />
        Historical Records Review
      </Accordion.Title>
      <Accordion.Content active={isHistoricalOpen}>
        <HistoricalRecordsReview additionalInfo={additionalInfo} />
      </Accordion.Content>

      <Accordion.Title
        active={isServiceLinesOpen}
        onClick={() => setIsServiceLinesOpen(!isServiceLinesOpen)}
      >
        <Icon name="dropdown" />
        Identifying Service Lines
      </Accordion.Title>
      <Accordion.Content active={isServiceLinesOpen}>
        <IdentifyingServiceLines additionalInfo={additionalInfo} />
      </Accordion.Content>

      <Accordion.Title
        active={isInvestigationsOpen}
        onClick={() => setIsInvestigationsOpen(!isInvestigationsOpen)}
      >
        <Icon name="dropdown" />
        Service Line Investigations
      </Accordion.Title>
      <Accordion.Content active={isInvestigationsOpen}>
        <ServiceLineInvestigations additionalInfo={additionalInfo} />
      </Accordion.Content>

      <Accordion.Title
        active={isSignatureOpen}
        onClick={() => setIsSignatureOpen(!isSignatureOpen)}
      >
        <Icon name="dropdown" />
        Signature Info
      </Accordion.Title>
      <Accordion.Content active={isSignatureOpen}>
        <Signature additionalInfo={additionalInfo} />
      </Accordion.Content>
    </Accordion>
  );
};

export default IdemAccordion;
