import { useEffect, useRef, useState } from "react";

export const useMinLoadTime: (ms: number, isLoading: boolean) => boolean = (
  ms = 0,
  isLoading
) => {
  const [hasElapsed, setHasElapsed] = useState<boolean>(true);
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (!isLoading) {
      return () => timeout.current && clearTimeout(timeout.current);
    }
    timeout.current && clearTimeout(timeout.current);
    setHasElapsed(false);
    timeout.current = setTimeout(() => {
      setHasElapsed(true);
    }, ms);

    return () => timeout.current && clearTimeout(timeout.current);
  }, [ms]);

  return hasElapsed;
};
