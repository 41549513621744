import React, { useCallback, useMemo } from "react";
import Submission from "src/types/Submission";
import styled from "styled-components";

import { Form as FinalForm } from "react-final-form";
import LoadingIndicatorPage from "src/components/LoadingIndicatorPage";
import { Button, Form } from "semantic-ui-react";
import { isApiError } from "src/utils/apiError";
import { toastSuccess, toastError } from "src/utils/toast";
import Error from "src/components/Error";
import { usePostPublishSubmissionsMutation } from "src/services/ptd";
import System from "src/types/System";

type SelectedSubmission = Submission & { systems: System };

interface Props {
  closeModal: () => void;
  selected?: SelectedSubmission[];
  submissionId: string;
  onClose?: () => void;
}

const ModalContentContainer = styled.div`
  display: flex;
  margin: 0;
  justify-content: center;
`;

const FormContentContainer = styled(Form)`
  margin: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    font-weight: bold;
    padding-bottom: 10px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin: 1.5rem 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  button {
    width: 400%;
    margin-right: 15px;
  }
`;

const PublishToPtdModal: React.FC<Props> = ({
  closeModal,
  selected,
  onClose,
}) => {
  const [publish, { error, isError, isLoading }] =
    usePostPublishSubmissionsMutation();

  const isSingleSubmission = selected?.length === 1;

  const onSubmit = useCallback(async () => {
    try {
      const res = await publish({
        submissions: selected?.map((s) => s.id) ?? [],
      }).unwrap();
      if (isSingleSubmission) {
        const systemName = selected[0].system.name;
        res.publishedItems.length === 1 &&
          toastSuccess(
            `${systemName}'s submission has been successfully published, but hang tight! This could take a few moments to reflect on the PTD.`
          );
        if (res.invalidItems.length === 1) {
          if (res.invalidItems[0].message?.includes("does not consent")) {
            toastError(
              `${systemName}'s submission was not published because they have not consented to publishing to the State PTD. The Utility must consent to allow submission data to be published to the PTD.`
            );
          } else if (res.invalidItems[0].message?.includes("is not approved")) {
            toastError(
              `${systemName}'s submission was not published because it is not approved. The submission must be approved to be published to the PTD.`
            );
          } else {
            toastError(
              res.invalidItems[0].message ??
                `${systemName}'s submission was not published. Please try again or contact support.`
            );
          }
        }
      } else {
        toastSuccess(
          `${res.publishedItems.length} submissions have been successfully published, but hang tight! This could take a few moments to reflect on the PTD.`
        );
        const notApproved = [];
        const noConsent = [];
        const other = [];
        for (const item of res.invalidItems) {
          const system =
            selected?.find((s) => s.id === item.submission.id)?.system.name ??
            item.submission.pwsId;
          if (item.message?.includes("does not consent")) {
            noConsent.push(system);
          } else if (item.message?.includes("is not approved")) {
            notApproved.push(system);
          } else {
            other.push(system);
          }
        }
        if (notApproved.length > 0) {
          toastError(
            `${
              notApproved.length
            } submission(s) were not published because the Submissions have not been Accepted. Please mark the submissions as Accepted and try again: ${notApproved.join(
              ", "
            )}`
          );
        }
        if (noConsent.length > 0) {
          toastError(
            `${
              noConsent.length
            } submission(s) were not published because the Utilities have not consented to publishing to the State PTD. The Utility must consent to allow submission data to be published to the PTD: ${noConsent.join(
              ", "
            )}`
          );
        }
        if (other.length > 0) {
          toastError(
            `${
              other.length
            } submission(s) were not published. Please try again or contact support: ${other.join(
              ", "
            )}`
          );
        }
      }

      onClose?.();
    } catch {
      toastError(
        "Uh oh! Something happened on our end, please wait a moment and try again."
      );
    }
    closeModal();
  }, [publish, selected, onClose, isSingleSubmission]);

  const errors = useMemo(() => {
    if (!error || !isError) {
      return undefined;
    }

    if (isApiError(error)) {
      return [error.data?.error];
    }

    return ["Please try again or contact support@120water.com"];
  }, [error, isError]);

  if (isLoading) {
    return <LoadingIndicatorPage />;
  }
  return (
    <ModalContentContainer>
      <FinalForm
        onSubmit={onSubmit}
        render={({ valid, handleSubmit, submitting }) => {
          return (
            <>
              <FormContentContainer
                onSubmit={() => {
                  void handleSubmit();
                }}
              >
                <p>
                  By publishing, you will be making this data available and
                  visible on the State PTD map
                </p>
                <ButtonsWrapper>
                  <Button
                    type="submit"
                    disabled={submitting || isLoading || !valid}
                    primary
                  >
                    Publish
                  </Button>
                  <Button onClick={closeModal}>Cancel</Button>
                </ButtonsWrapper>
              </FormContentContainer>

              {errors && (
                <Error style={{ margin: "12px 24px" }} messages={errors} />
              )}
            </>
          );
        }}
      />
    </ModalContentContainer>
  );
};

export default PublishToPtdModal;
