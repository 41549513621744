/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./baseQuery";
import { Tag, TagLabel } from "./tags";
import { MaterialType } from "src/pages/Dashboard/dashboardConstants";
import County from "src/types/County";
import { SubmissionStatuses } from "src/types/Submission";

export interface SubmissionPeriodMetricsParams {
  submissionPeriodId: number;
}

export interface SubmittedSubmissionsMetric {
  total: number;
  submitted: number;
}

export interface UnknownServiceLinesMetric {
  total: number;
  unknown: number;
}

export type UtilityMaterialMakeupMetric = {
  [key in MaterialType]: Array<{
    bin: string;
    value: number;
  }>;
};

export type MaterialTypeBreakdownMetric = {
  [key in MaterialType]: number;
};

export type CountiesMetric = Record<
  string,
  {
    fips: string;
    displayName: string;
    lead: number;
    grr: number;
    nonlead: number;
    unknown: number;
    total: number;
    pwsNames: string | null | undefined;
  }
>;

export interface PwsSummary {
  id: number;
  pwsId: string;
  pwsName: string;
  countiesServed?: string;
  submissionPeriodId?: number;
  submissionPeriodName?: string;
  systemType?: string;
  totalServiceLines?: number;
  status?: SubmissionStatuses;
}

export const reportingApi = createApi({
  baseQuery: baseQuery({ baseUrl: "/pws/state-submission/reporting" }),
  reducerPath: "reportingApi",
  tagTypes: [
    Tag.Comments,
    Tag.Contacts,
    Tag.ServiceLines,
    Tag.SubmissionPeriods,
    Tag.Submissions,
    Tag.Systems,
    Tag.PwsSubmissionPeriods,
    Tag.PwsSubmissionPeriodStatuses,
  ],
  endpoints: (builder) => ({
    getSubmittedSubmissionsMetric: builder.query<
      SubmittedSubmissionsMetric,
      SubmissionPeriodMetricsParams
    >({
      providesTags: [{ id: TagLabel.Metric, type: Tag.SubmissionPeriods }],
      query: (params) => {
        return {
          method: "GET",
          url: `/submission-period/${params.submissionPeriodId}/submitted-submissions`,
        };
      },
    }),
    getUnknownServiceLinesMetric: builder.query<
      UnknownServiceLinesMetric,
      SubmissionPeriodMetricsParams
    >({
      providesTags: [{ id: TagLabel.Metric, type: Tag.ServiceLines }],
      query: (params) => {
        return {
          method: "GET",
          url: `/submission-period/${params.submissionPeriodId}/unknown-service-lines`,
        };
      },
    }),
    getUtilityMaterialMakeupMetric: builder.query<
      UtilityMaterialMakeupMetric,
      SubmissionPeriodMetricsParams
    >({
      providesTags: [{ id: TagLabel.Metric, type: Tag.ServiceLines }],
      query: (params) => {
        return {
          method: "GET",
          url: `/submission-period/${params.submissionPeriodId}/utility-material-makeup`,
        };
      },
    }),
    getMaterialTypeBreakdownMetric: builder.query<
      MaterialTypeBreakdownMetric,
      SubmissionPeriodMetricsParams
    >({
      providesTags: [{ id: TagLabel.Metric, type: Tag.SubmissionPeriods }],
      query: (params) => {
        return {
          method: "GET",
          url: `/submission-period/${params.submissionPeriodId}/material-type-breakdown`,
        };
      },
    }),
    getCountiesMetric: builder.query<
      CountiesMetric,
      SubmissionPeriodMetricsParams
    >({
      providesTags: [{ id: TagLabel.Metric, type: Tag.ServiceLines }],
      query: (params) => {
        return {
          method: "GET",
          url: `/submission-period/${params.submissionPeriodId}/counties`,
        };
      },
    }),
    getUtilitesByCounty: builder.query<
      // TODO: might be able to use /systems instead
      County[],
      SubmissionPeriodMetricsParams
    >({
      providesTags: [{ id: TagLabel.Metric, type: Tag.ServiceLines }],
      query: (params) => {
        return {
          method: "GET",
          url: `/submission-period/${params.submissionPeriodId}/counties`,
        };
      },
    }),
    getCountySummaries: builder.query<CountiesMetric, null>({
      providesTags: [{ id: TagLabel.Metric, type: Tag.ServiceLines }],
      query: () => {
        return {
          method: "get",
          url: `/counties-summary`,
        };
      },
    }),
    getPwsSummariesByCounty: builder.query<PwsSummary[], { fips: string }>({
      providesTags: [{ id: TagLabel.Metric, type: Tag.ServiceLines }],
      query: (params) => {
        return {
          method: "get",
          url: `/pws-by-county-summary/${params.fips}`,
        };
      },
    }),
    getUnmappedPwsSummaries: builder.query<PwsSummary[], null>({
      providesTags: [{ id: TagLabel.Metric, type: Tag.ServiceLines }],
      query: () => {
        return {
          method: "get",
          url: `/unmapped-pws-summary`,
        };
      },
    }),
  }),
});

export const {
  useGetSubmittedSubmissionsMetricQuery,
  useGetUnknownServiceLinesMetricQuery,
  useGetUtilityMaterialMakeupMetricQuery,
  useGetMaterialTypeBreakdownMetricQuery,
  useGetCountiesMetricQuery,
  useGetUtilitesByCountyQuery,
  useGetCountySummariesQuery,
  useLazyGetPwsSummariesByCountyQuery,
  useGetUnmappedPwsSummariesQuery,
} = reportingApi;

export default reportingApi;
