import { useAuth0 } from "@auth0/auth0-react";
import decode from "jwt-decode";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useLazyGetCurrentUserQuery } from "src/services";
import { useAppDispatch, useAppSelector } from "src/store";
import { selectToken, setToken } from "./slice";
import { setClaims } from "./claimsSlice";
import { User } from "./types";

export { default as ProfileBadge } from "./ProfileBadge";
export * from "./types";
export * from "./util";

interface UserContextInterface {
  isLoading: boolean;
  user?: User;
}

export const isTokenExpired = (token?: null | string): boolean => {
  if (!token) {
    return true;
  }

  const current = Math.floor(Date.now() / 1000);
  const decoded: { exp?: number } = decode(token);
  return typeof decoded !== "string" && (decoded?.exp ?? 0) < current;
};

export const useToken = (): { isLoading: boolean; token: null | string } => {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const [isLoading, setLoading] = useState(false);
  const expired = isTokenExpired(token);
  useEffect(() => {
    (async () => {
      if (expired) {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const claims = await getIdTokenClaims();
        dispatch(setToken(token));
        dispatch(setClaims(claims ?? null));
        setLoading(false);
      }
    })();
  }, [getAccessTokenSilently, expired]);
  return { isLoading, token };
};

const useGetUserQuery = (): { isLoading: boolean; user?: User } => {
  const { isLoading: isTokenLoading, token } = useToken();
  const [getUser, { data, isLoading: isUserLoading }] =
    useLazyGetCurrentUserQuery();

  useEffect(() => {
    if (token) {
      void getUser();
    }
  }, [token, getUser]);

  return useMemo(
    () => ({
      isLoading: isTokenLoading || isUserLoading,
      user: data,
    }),
    [isTokenLoading, isUserLoading, data]
  );
};

const UserContext = React.createContext<UserContextInterface>({
  isLoading: false,
});

const UserProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const { isLoading, user } = useGetUserQuery();
  // TODO handle missing user (this is an edge case)
  return <UserContext.Provider value={{ isLoading, user }} {...props} />;
};

interface CurrentUser extends UserContextInterface {
  logout: () => void;
}

export const useCurrentUser = (): CurrentUser => {
  const { logout } = useAuth0();
  const context = useContext(UserContext);

  const decoratedLogout = useCallback((): void => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  return { ...context, logout: decoratedLogout };
};

export const isWrite = (): boolean => {
  const context = useContext(UserContext);
  if (context.user) {
    return context.user.permissions.includes('stateportal:write')
  }
  return true
}

export const isAdmin = (): boolean => {
  const context = useContext(UserContext);
  if (context.user) {
    return context.user.permissions.includes('stateportal:admin')
  }
  return true
}

export default UserProvider;
