import { SubmissionPeriod } from "./SubmissionPeriod";
import System from "./System";

export interface Submission extends Record<string, unknown> {
  s: any;
  id: number;
  activeSubmissionId: number;
  primacyAgencyId: number;
  pwsId: number;
  status: SubmissionStatuses;
  statusComment?: string;
  submittedBy?: string;
  submittedByName?: string;
  submittedOn?: string;
  percentKnown: number;
  totalServiceLinesLead: number;
  totalServiceLinesGalvanized: number;
  totalServiceLinesNonLead: number;
  totalServiceLinesUnknown: number;
  totalServiceLinesSubmitted: number;
  additionalInfo?: { [key: string]: any };
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
  deletedBy?: string;
  deletedOn?: string;
  submissionPeriod: SubmissionPeriod;
  statusHistory: StatusHistory[];
  system: System;
  overdue: boolean;
  ptdPublished: boolean;
  ptdPublishStatus: boolean;
}

interface StatusHistory {
  id: number;
  comment?: any;
  status: string;
  createdBy: string;
  createdOn: string;
}

export type SubmissionStatuses = keyof typeof SubmissionStatus;

export enum SubmissionStatus {
  "submitted" = "Submitted",
  "inReview" = "In Review",
  "rejected" = "Rejected",
  "approved" = "Accepted",
  "overdue" = "Overdue",
  "voided" = "Voided",
  "processingSubmission" = "Processing Submission",
  "failed" = "Failed",
  "notSubmitted" = "Not Submitted",
}

export default Submission;
