/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { createApi } from "@reduxjs/toolkit/query/react";

import { Contact } from "src/types/Contact";
import Sort from "src/types/Sort";
import { Submission, SubmissionStatuses } from "src/types/Submission";
import SubmissionPeriod from "src/types/SubmissionPeriod";
import ServiceLine from "src/types/ServiceLine";
import System from "src/types/System";
import { Activity } from "src/types/Activity";
import baseQuery from "./baseQuery";
import { Tag, TagLabel } from "./tags";
import buildParameters, { PaginatedResponse, ApiResponse } from "./utils";
import Territory from "src/types/Territory";
import Export from "src/types/Export";
import { saveAs } from "file-saver";
import { SavedView } from "src/types/SavedView";
import { SubmissionCommunication } from "src/types/SubmissionCommunication";

export type ValidFilterValue =
  | undefined
  | Array<boolean | number | string>
  | boolean
  | number
  | string;
export type Filters = Record<string, undefined | ValidFilterValue>;

export interface SearchParams {
  filters: Filters;
  include?: string;
  page: number;
  pageSize: number;
  searchTerm?: string;
  sort?: Sort | string;
}

export interface ResponseList<T> {
  data: T[];
}

interface IndividualSystem {
  data: System;
}

interface ContactParams {
  contactId: number;
  systemId: number;
}

interface SubmissionsCountBySubmissionPeriodPwsAndValidStatusParams {
  submissionPeriodId: number;
  pwsId: number;
}

interface GroupedStatusCountsParams {
  submissionPeriodId: number;
}

interface CreateContact {
  contact?: Omit<Partial<Contact>, "id">;
  systemId: number;
}

interface UpdateContact {
  contact?: Omit<Partial<Contact>, "id">;
  contactId: number;
  systemId: number;
}

interface UpdateSubmissionStatus {
  submissionId: string;
  submissionStatus: string;
}

interface UpdateSubmissionPeriodSystems {
  submissionPeriodId: number;
  systemIds: number[];
}

interface ServiceLineParams {
  submissionId: string;
  paramsData: SearchParams;
}

interface GetAllSubmissionsParams {
  submissionPeriodId?: number;
  searchParams: SearchParams;
}

interface ServiceLineSingleParams {
  submissionId: string;
  serviceLineId: string;
}

interface SubmissionStatusUpdate {
  id: number;
  status: string;
}

interface SubmissionStatusUpdateResponse {
  data: {
    updatedSubmissions: Submission[];
    submissionsNotUpdated: string[];
  };
}

interface BulkSubmissionPeriodUpdateResponse {
  data: {
    updatedSystems: System[];
    systemsNotUpdated: string[];
  };
}

interface SubmissionPeriodCommentParams {
  submissionPeriodId?: number;
  pwsId: number;
}
interface CommentFormData {
  comment: string;
  commentType: string;
  originType: string;
  originId: number;
  submissionId: number;
}

interface CreateComment {
  submissionPeriodId: number;
  pwsId: number;
  comment: CommentFormData;
}

interface GetPwsSubmissionPeriodsParams {
  submissionPeriodId: number;
  searchParams: SearchParams;
}

export interface PrimacyAgency {
  id: number;
  primacyCode: string;
  name: string;
  accountId: number;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  extent: FullExtent;
  centroid: Centroid;
}

export interface FullExtent {
  type: string;
  coordinates: number[][][];
}

export interface Centroid {
  type: string;
  coordinates: number[];
}

export interface StatusCount {
  status: SubmissionStatuses | "statusesTotal";
  count: number;
  statusesTotal?: number;
}

export interface PwsSubmissionPeriod extends Record<string, unknown> {
  pwsId: number;
  submissionPeriodId: number;
  active: boolean;
  status: SubmissionStatuses;
  activeSubmissionId: number;
  submissionAttemptCount: number;
  system: System;
  submissionPeriod: SubmissionPeriod;
  activeSubmission: Submission;
  submissionCommunication: SubmissionCommunication;
}

export const stateSubmissionService = createApi({
  baseQuery: baseQuery({ baseUrl: "/pws/state-submission" }),
  reducerPath: "statesubmission",
  tagTypes: [
    Tag.Comments,
    Tag.Counties,
    Tag.Contacts,
    Tag.ServiceLines,
    Tag.SubmissionPeriods,
    Tag.Submissions,
    Tag.Systems,
    Tag.PwsSubmissionPeriods,
    Tag.PwsSubmissionPeriodStatuses,
    Tag.SavedViews,
    Tag.SubmissionCommunications,
    Tag.Territories,
    Tag.Exports
  ],
  endpoints: (builder) => ({
    createContact: builder.mutation<Contact, CreateContact>({
      invalidatesTags: [{ id: TagLabel.List, type: Tag.Contacts }],
      query: ({ contact, systemId }) => {
        return {
          body: contact,
          method: "POST",
          url: `/systems/${systemId}/contacts`,
        };
      },
    }),
    deleteContact: builder.mutation<void, ContactParams>({
      invalidatesTags: [{ id: TagLabel.List, type: Tag.Contacts }],
      query: ({ contactId, systemId }) => ({
        method: "delete",
        url: `/systems/${systemId}/contacts/${contactId}`,
      }),
    }),
    editContact: builder.mutation<Contact, UpdateContact>({
      invalidatesTags: [{ id: TagLabel.List, type: Tag.Contacts }],
      query: ({ contact, contactId, systemId }) => ({
        body: contact,
        method: "put",
        url: `/systems/${systemId}/contacts/${contactId}`,
      }),
    }),
    getAllSubmissionPeriods: builder.query<
      PaginatedResponse<SubmissionPeriod>,
      SearchParams
    >({
      providesTags: [{ id: TagLabel.List, type: Tag.SubmissionPeriods }],
      query: (paramsObject) => {
        const params = buildParameters(paramsObject).toString();
        return {
          method: "GET",
          url: `/submission-periods?${params}`,
        };
      },
    }),
    getAllActiveSubmissionPeriods: builder.query<
      ApiResponse<SubmissionPeriod[]>,
      void
    >({
      providesTags: [{ id: TagLabel.List, type: Tag.SubmissionPeriods }],
      query: () => {
        return {
          method: "GET",
          url: `/active-submission-periods`,
        };
      },
      // Add the locked field for all entries
      transformResponse: (response: ApiResponse<SubmissionPeriod[]>) => {
        const currentDate = new Date();

        const transformedData = response.data.map((period) => {
          const lockedDate = period.lockedDate
            ? new Date(period.lockedDate)
            : null;
          const locked = lockedDate ? lockedDate <= currentDate : false;

          return {
            ...period,
            locked,
          };
        });

        return {
          ...response,
          data: transformedData,
        };
      },
    }),
    getTotalSubmissionsCountBySubmissionPeriodPwsAndValidStatus: builder.query<
      ApiResponse<number>,
      SubmissionsCountBySubmissionPeriodPwsAndValidStatusParams
    >({
      query: ({ submissionPeriodId, pwsId }) => {
        return {
          method: "GET",
          url: `/submissions/submission-period/${submissionPeriodId}/pws-id/${pwsId}/submission-count`,
        };
      },
    }),
    getGroupedStatusCounts: builder.query<
      ApiResponse<StatusCount[]>,
      GroupedStatusCountsParams
    >({
      providesTags: [
        { id: TagLabel.List, type: Tag.PwsSubmissionPeriodStatuses },
      ],
      query: ({ submissionPeriodId }) => {
        return {
          method: "GET",
          url: `/pws-submission-period/${submissionPeriodId}/grouped-submission-count`,
        };
      },
    }),
    getAllSubmissions: builder.query<
      PaginatedResponse<Submission>,
      GetAllSubmissionsParams
    >({
      providesTags: [{ id: TagLabel.List, type: Tag.Submissions }],
      query: ({ submissionPeriodId, searchParams }) => {
        const params = buildParameters(searchParams).toString();
        return {
          method: "GET",
          url: `/submissions/submission-period/${
            submissionPeriodId ?? 0
          }?${params}`,
        };
      },
    }),
    getAllSubmissionPeriodComments: builder.query<
      ApiResponse<Activity[]>,
      SubmissionPeriodCommentParams
    >({
      providesTags: [{ id: TagLabel.List, type: Tag.Comments }],
      query: ({ submissionPeriodId, pwsId }) => {
        return {
          method: "GET",
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          url: `/submission-periods/${submissionPeriodId}/comments/${pwsId}`,
        };
      },
    }),
    createComment: builder.mutation<Activity, CreateComment>({
      invalidatesTags: [{ id: TagLabel.List, type: Tag.Comments }],
      query: ({ submissionPeriodId, pwsId, comment }) => {
        return {
          body: comment,
          method: "POST",
          url: `/submission-periods/${submissionPeriodId}/comments/${pwsId}`,
        };
      },
    }),
    getAllSubmissionServiceLines: builder.query<
      PaginatedResponse<ServiceLine>,
      ServiceLineParams
    >({
      providesTags: [{ id: TagLabel.List, type: Tag.ServiceLines }],
      query: ({ submissionId, paramsData }) => {
        const params = buildParameters(paramsData).toString();
        return {
          method: "GET",
          url: `/submissions/${submissionId}/servicelines?${params}`,
        };
      },
    }),
    getSubmissionServiceLine: builder.query<
      ServiceLine,
      ServiceLineSingleParams
    >({
      providesTags: [{ id: TagLabel.List, type: Tag.ServiceLines }],
      query: ({ submissionId, serviceLineId }) => {
        return {
          method: "GET",
          url: `/submissions/${submissionId}/servicelines/${serviceLineId}`,
        };
      },
    }),
    getSubmission: builder.query<ApiResponse<Submission>, string>({
      providesTags: [{ id: TagLabel.List, type: Tag.Submissions }],
      query: (submissionId) => {
        return {
          method: "GET",
          url: `/submissions/${submissionId}`,
        };
      },
    }),
    getLatestSubmission: builder.query<ApiResponse<Submission>, string>({
      providesTags: [{ id: TagLabel.List, type: Tag.Submissions }],
      query: (systemId) => {
        return {
          method: "GET",
          url: `/submissions/systems/${systemId}/latest`,
        };
      },
    }),
    getAllSystemContacts: builder.query<
      PaginatedResponse<Contact>,
      string | number
    >({
      providesTags: [{ id: TagLabel.List, type: Tag.Contacts }],
      query: (systemId) => `/systems/${systemId}/contacts`,
    }),
    getAllSystems: builder.query<ResponseList<System>, Partial<SearchParams>>({
      providesTags: [{ id: TagLabel.List, type: Tag.Systems }],
      query: (paramsObject) => {
        const params = buildParameters(paramsObject).toString();

        return {
          method: "get",
          url: `/systems/all?${params}`,
        };
      },
    }),
    getContact: builder.query<Contact, ContactParams>({
      providesTags: (result) =>
        result
          ? [{ id: result.id, type: Tag.Contacts }]
          : [{ type: Tag.Contacts }],
      query: ({ contactId, systemId }) =>
        `/systems/${systemId}/contacts/${contactId}`,
    }),
    getPagedSystems: builder.query<PaginatedResponse<System>, SearchParams>({
      providesTags: [{ id: TagLabel.List, type: Tag.Systems }],
      query: (paramsObject) => {
        const params = buildParameters(paramsObject).toString();

        return {
          method: "get",
          url: `/systems?${params}`,
        };
      },
    }),
    getSingleSystem: builder.query<IndividualSystem, string>({
      providesTags: (result) =>
        result
          ? [{ id: result.data.id, type: Tag.Systems }]
          : [{ type: Tag.Systems }],
      query: (id) => `/systems/${id}`,
    }),
    upsertSubmissionPeriod: builder.mutation<
      SubmissionPeriod,
      Omit<SubmissionPeriod, "id"> & { id?: number }
    >({
      invalidatesTags: [
        { id: TagLabel.List, type: Tag.SubmissionPeriods },
        { id: TagLabel.List, type: Tag.Systems },
      ],
      query: (submissionPeriod) => ({
        body: submissionPeriod,
        method: submissionPeriod.id ? "PUT" : "POST",
        url: submissionPeriod.id
          ? `/submission-periods/${submissionPeriod.id}`
          : "/submission-periods",
      }),
    }),
    addSystemsToSubmissionPeriod: builder.mutation<
      BulkSubmissionPeriodUpdateResponse,
      UpdateSubmissionPeriodSystems
    >({
      invalidatesTags: [
        { id: TagLabel.List, type: Tag.SubmissionPeriods },
        { id: TagLabel.List, type: Tag.Systems },
      ],
      query: ({ submissionPeriodId, systemIds }) => ({
        body: { data: systemIds },
        method: "put",
        url: `/submission-periods/${submissionPeriodId}/systems`,
      }),
    }),
    removeSystemsFromSubmissionPeriod: builder.mutation<
      BulkSubmissionPeriodUpdateResponse,
      UpdateSubmissionPeriodSystems
    >({
      invalidatesTags: [
        { id: TagLabel.List, type: Tag.SubmissionPeriods },
        { id: TagLabel.List, type: Tag.Systems },
      ],
      query: ({ submissionPeriodId, systemIds }) => ({
        body: { data: systemIds },
        method: "delete",
        url: `/submission-periods/${submissionPeriodId}/systems`,
      }),
    }),
    updateSubmission: builder.mutation<Submission, UpdateSubmissionStatus>({
      invalidatesTags: [
        { id: TagLabel.List, type: Tag.Submissions },
        { id: TagLabel.List, type: Tag.PwsSubmissionPeriods },
        { id: TagLabel.List, type: Tag.PwsSubmissionPeriodStatuses },
      ],
      query: ({ submissionId, submissionStatus }) => ({
        body: { status: submissionStatus },
        method: "put",
        url: `/submissions/${submissionId}`,
      }),
    }),
    updateSubmissionStatuses: builder.mutation<
      SubmissionStatusUpdateResponse,
      SubmissionStatusUpdate[]
    >({
      invalidatesTags: [
        { id: TagLabel.List, type: Tag.Submissions },
        { id: TagLabel.List, type: Tag.PwsSubmissionPeriods },
        { id: TagLabel.List, type: Tag.PwsSubmissionPeriodStatuses },
      ],
      query: (submissionStatusArray) => ({
        body: { data: submissionStatusArray },
        method: "put",
        url: `/submissions/bulkupdatestatus`,
      }),
    }),
    getServiceLineMaterialOptions: builder.query<ResponseList<string>, {}>({
      query: () => "/submissions/servicelines/material_selection_options",
    }),
    getCurrentPrimacyAgency: builder.query<PrimacyAgency, undefined>({
      query: () => ({
        method: "get",
        url: "/primacy-agency",
      }),
    }),
    getPwsSubmissionPeriods: builder.query<
      PaginatedResponse<PwsSubmissionPeriod>,
      GetPwsSubmissionPeriodsParams
    >({
      providesTags: [{ id: TagLabel.List, type: Tag.PwsSubmissionPeriods }],
      query: ({ submissionPeriodId, searchParams }) => {
        const params = buildParameters(searchParams).toString();

        return {
          method: "get",
          url: `/pws-submission-period/${submissionPeriodId}?${params}`,
        };
      },
    }),
    systemHasActiveSubmissionPeriod: builder.query<
      ApiResponse<boolean>,
      string
    >({
      query: (systemId) => {
        return {
          method: "get",
          url: `/pws-submission-period/utility/${systemId}/has-active-submission-period`,
        };
      },
    }),
    getAllTerritories: builder.query<Territory[], {}>({
      providesTags: [{ id: TagLabel.List, type: Tag.Territories }],
      query: () => "/territories",
    }),
    addUpdateTerritory: builder.mutation<string, {}>({
      invalidatesTags: [{ id: TagLabel.List, type: Tag.Territories }],
      query: (data: { name: string; updateId: number }) => {
        const { name, updateId } = data;
        const territoryId = updateId ? `/${updateId}` : "";
        const method = updateId ? "put" : "post";
        const url = `/territories${territoryId}`;
        return {
          method,
          url,
          body: { name },
        };
      },
    }),

    addBulkSystemsTerritories: builder.mutation<string, {}>({
      query: (data: { systemIds: number[]; territoryId: number }) => {
        const { systemIds, territoryId } = data;
        return {
          method: "put",
          url: `/territories/${territoryId}/systems`,
          body: systemIds,
        };
      },
    }),
    deleteTerritory: builder.mutation<string, {}>({
      invalidatesTags: [{ id: TagLabel.List, type: Tag.Territories }],
      query: (data: { territoryId: number }) => {
        const { territoryId } = data;
        return {
          method: "delete",
          url: `/territories/${territoryId}`
        };
      },
    }),
    getSystemsCountByTerritoryId: builder.query<number, number>({
      query: (territoryId) => {
        return {
          method: "get",
          url: `/territories/${territoryId}/systems/count`,
        };
      },
    }),
    removeBulkSystemsTerritories: builder.mutation<string, {}>({
      query: (data: { systemIds: number[] }) => {
        const { systemIds } = data;
        return {
          method: "delete",
          url: `/territories/systems`,
          body: systemIds,
        };
      },
    }),
    generateSIDWISExport: builder.mutation<Export, {}>({
      invalidatesTags: [Tag.Exports],
      query: (data: { submissionPeriodId: number; exportType: string }) => {
        return {
          method: "post",
          url: `/export`,
          body: data,
        };
      },
    }),
    generateCSVExport: builder.mutation<
      void,
      { exportId: number; name: string }
    >({
      queryFn: async (data, _queryApi, _extraOptions, fetchWithBQ) => {
        const { exportId, name } = data;
        const url = `/export/${exportId}`;

        try {
          const response = await fetchWithBQ({
            url,
            method: "GET",
            responseHandler: async (response: { blob: () => any }) => {
              const blob = await response.blob();
              saveAs(blob, name);
              return { data: undefined };
            },
          });

          if (response.error) {
            return { error: response.error };
          }

          return { data: undefined };
        } catch (error: any) {
          return { error: { status: "FETCH_ERROR", error: error.message } };
        }
      },
    }),
    getSIDWISCount: builder.query<number, { submissionPeriodId: number }>({
      query: ({ submissionPeriodId }) =>
        `/submissions/submission-period/${submissionPeriodId}/sdwis/count`,
    }),
    getSIDWISExports: builder.query<Export[], {}>({
      providesTags: [Tag.Exports],
      query: () => `/export`,
    }),
    getSavedViews: builder.query<SavedView[], {}>({
      providesTags: [{ id: TagLabel.List, type: Tag.SavedViews }],
      query: ({ tableName }: { tableName: string }) =>
        `/saved-views/${tableName}`,
    }),
    createSavedView: builder.mutation<SavedView, {}>({
      invalidatesTags: [{ id: TagLabel.List, type: Tag.SavedViews }],
      query: (
        data: Pick<
          SavedView,
          "name" | "ownerType" | "ownerId" | "view" | "tableName"
        >
      ) => {
        return {
          method: "post",
          url: "/saved-views",
          body: {
            ...data,
            view: JSON.stringify(data.view),
          },
        };
      },
    }),
    editSavedView: builder.mutation<SavedView, {}>({
      invalidatesTags: [{ id: TagLabel.List, type: Tag.SavedViews }],
      query: (data: { id: number; view: Record<string, unknown> }) => {
        return {
          method: "put",
          url: `/saved-views/${data.id}`,
          body: { view: JSON.stringify(data.view) },
        };
      },
    }),
    deleteSavedView: builder.mutation<SavedView, {}>({
      invalidatesTags: [{ id: TagLabel.List, type: Tag.SavedViews }],
      query: (data: { id: number }) => {
        return {
          method: "delete",
          url: `/saved-views/${data.id}`,
        };
      },
    }),
    updateSubmissionCommunication: builder.mutation<
      SubmissionStatusUpdateResponse,
      { submissionCommunicationId: number; value: string }
    >({
      invalidatesTags: [
        { id: TagLabel.List, type: Tag.SubmissionCommunications },
      ],
      query: ({ submissionCommunicationId, value }) => ({
        body: {
          submissionCommunicationId,
          status: value,
        },
        method: "put",
        url: `/communications/${submissionCommunicationId}`,
      }),
    }),
    createSubmissionCommunication: builder.mutation<
      SubmissionStatusUpdateResponse,
      { value: string; pwsId: number; submissionPeriodId: number }
    >({
      invalidatesTags: [
        { id: TagLabel.List, type: Tag.SubmissionCommunications },
      ],
      query: ({ value, pwsId, submissionPeriodId }) => ({
        body: value,
        method: "post",
        url: `/communications/${pwsId}/${submissionPeriodId}`,
      }),
    }),
  }),
});

export const {
  useRemoveSystemsFromSubmissionPeriodMutation,
  useAddSystemsToSubmissionPeriodMutation,
  useCreateContactMutation,
  useCreateCommentMutation,
  useDeleteContactMutation,
  useEditContactMutation,
  useGetSubmissionQuery,
  useGetAllSubmissionsQuery,
  useGetAllSubmissionPeriodsQuery,
  useGetAllActiveSubmissionPeriodsQuery,
  useGetTotalSubmissionsCountBySubmissionPeriodPwsAndValidStatusQuery,
  useGetGroupedStatusCountsQuery,
  useGetAllSubmissionPeriodCommentsQuery,
  useGetAllSubmissionServiceLinesQuery,
  useGetSubmissionServiceLineQuery,
  useGetAllSystemContactsQuery,
  useGetAllSystemsQuery,
  useGetContactQuery,
  useGetLatestSubmissionQuery,
  useSystemHasActiveSubmissionPeriodQuery,
  useGetPagedSystemsQuery,
  useGetSingleSystemQuery,
  useUpsertSubmissionPeriodMutation,
  useUpdateSubmissionMutation,
  useUpdateSubmissionStatusesMutation,
  useGetServiceLineMaterialOptionsQuery,
  useGetCurrentPrimacyAgencyQuery,
  useGetPwsSubmissionPeriodsQuery,
  useGetAllTerritoriesQuery,
  useAddUpdateTerritoryMutation,
  useAddBulkSystemsTerritoriesMutation,
  useRemoveBulkSystemsTerritoriesMutation,
  useDeleteTerritoryMutation,
  useGetSystemsCountByTerritoryIdQuery,
  useGenerateSIDWISExportMutation,
  useGenerateCSVExportMutation,
  useGetSIDWISCountQuery,
  useGetSIDWISExportsQuery,
  useGetSavedViewsQuery,
  useCreateSavedViewMutation,
  useEditSavedViewMutation,
  useDeleteSavedViewMutation,
  useUpdateSubmissionCommunicationMutation,
  useCreateSubmissionCommunicationMutation,
} = stateSubmissionService;

export default stateSubmissionService;
