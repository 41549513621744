import { Card } from "@120wateraudit/waterworks";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  children?: React.ReactNode;
}

const NotFoundCard: React.FC<Props> = ({ children }) => {
  const childrenWithDefault = children ?? <DefaultNotFound />;
  return (
    <Card>
      <>
        <h1>Not Found</h1>
        {childrenWithDefault}
      </>
    </Card>
  );
};

const DefaultNotFound: React.FC = () => {
  return (
    <>
      <p>
        We could not find the page you were looking for. Perhaps it has moved or
        never even existed in the first place.
      </p>
      <p>
        We recommend returning to{" "}
        <Link to="/">your Dashboard and begining your search anew.</Link>
      </p>
    </>
  );
};

export default NotFoundCard;
