import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IdToken } from "@auth0/auth0-react";

type ClaimsState = IdToken | null;

const INITIAL_STATE = null as ClaimsState;

const claimsSlice = createSlice({
  initialState: INITIAL_STATE,
  name: "claims",
  reducers: {
    clearClaims(state) {
      return undefined;
    },
    setClaims(state, action: PayloadAction<ClaimsState>) {
      return action.payload;
    },
  },
});

export const { clearClaims, setClaims } = claimsSlice.actions;

export const selectClaims = (state: RootState): ClaimsState => state.claims;

export default claimsSlice.reducer;
