import React from "react";
import { Divider } from "semantic-ui-react";
import styled from "styled-components";

import { BasicContainer, Value, QuestionTitle, FlexDiv } from "../shared";

interface Props {
  additionalInfo: { [key: string]: any };
}

const Signature: React.FC<Props> = ({ additionalInfo }) => {
  const { name, phone, email, signature, verified } = additionalInfo;

  return (
    <BasicContainer>
      <QuadrupleInputGrid>
        <div>
          <QuestionTitle>Signature</QuestionTitle>
          <Value>{signature ?? "--"}</Value>
        </div>
        <div>
          <QuestionTitle>Name</QuestionTitle>
          <Value>{name ?? "--"}</Value>
        </div>
        <div>
          <QuestionTitle>Phone</QuestionTitle>
          <Value>{phone ?? "--"}</Value>
        </div>
        <div>
          <QuestionTitle>Email</QuestionTitle>
          <Value>{email ?? "--"}</Value>
        </div>
      </QuadrupleInputGrid>
      <Divider />
      <StyledFlexDiv style={{ gap: "8px", lineHeight: "1.5" }}>
        <input type="checkbox" checked={verified} />
        <label>
          I verify this submission to be accurate and true to the best of my
          knowledge.
        </label>
      </StyledFlexDiv>
    </BasicContainer>
  );
};

export const QuadrupleInputGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  & > h3 {
    grid-column-start: span 2;
  }
  & > div.field {
    margin: 0 !important;
  }
`;

const StyledFlexDiv = styled(FlexDiv)`
  pointer-events: none;
`;

export default Signature;
