import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  DARK_BLUE,
  GRAY,
  LIGHT_GRAY,
  WHITE,
} from "@120wateraudit/waterworks";
import { Dropdown, Icon } from "semantic-ui-react";
import Page from "src/components/Page";
import PageHeader from "src/components/PageHeader";
import Row from "src/components/Row";
import WavePattern from "src/assets/images/wave_dot_pattern.png";
import { SubmissionPeriodMetricCard } from "./SubmissionPeriodMetricCard";
import { MaterialTypesContainer } from "./MaterialTypesChart";
import styled from "styled-components";
import { BarGraph } from "../../components/BarGraph";
import { MapSection } from "./MapSection";
import {
  useGetAllSubmissionPeriodsQuery,
  useGetCountySummariesQuery,
  useGetCurrentPrimacyAgencyQuery,
  useGetMaterialTypeBreakdownMetricQuery,
  useGetSubmittedSubmissionsMetricQuery,
  useGetUtilityMaterialMakeupMetricQuery,
} from "src/services";
import { COLOR_MAP, LABEL_MAP, MaterialType } from "./dashboardConstants";
import LoadingIndicatorPage from "src/components/LoadingIndicatorPage";
import { formatPercent, formatShortDate } from "src/utils/format";
import Column from "src/components/Column";
import { PaginatedResponse } from "src/services/utils";
import SubmissionPeriod from "src/types/SubmissionPeriod";
import SortDirection from "src/types/SortDirection";
import { useTooltip } from "src/hooks/useTooltip";

const DeadlineCard = styled(Card)`
  background-repeat: no-repeat;
  background-color: ${DARK_BLUE};
  background-position: bottom right;
  background-size: 30%;
  color: ${WHITE};
  background-image: url(${WavePattern});
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  height: 100%;
`;

const SubHeader = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-top: 42px;
  &&&:first-child {
    margin-top: 42px;
  }
`;

const SubmissionStatsGrid = styled(Row)`
  display: grid;
  grid-template-areas:
    "sub-card-1 sub-card-2 compliance-deadlines"
    "material-makeup material-makeup compliance-deadlines";
  grid-template-columns: 1fr 1fr auto;
  grid-template-rows: auto 1fr;
  gap: 10px;
  max-height: 400px;
  width: 100%;
`;

const ComplianceDeadlinesCard = styled(Card)`
  grid-area: compliance-deadlines;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 16px;
  height: 100%;
  width: max-content;
  overflow-y: auto;
  * {
    margin: 0;
  }
`;

const MaterialMakeupCard = styled(Card)`
  grid-area: material-makeup;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const MaterialMakeupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const InactiveSubmissionPeriodsContainer = styled(Column)`
  gap: 5px;
  scrollbar-gutter: stable;
  flex-grow: 1;
`;

const StyledSubmissionProgressContainer = styled.div`
  color: ${LIGHT_GRAY};
  width: fit-content;
  * {
    width: fit-content;
  }

  ul {
    margin-right: 10px;
    padding-inline-start: 30px;
  }
`;

const SubmissionProgressHover: React.FC<{}> = () => {
  return (
    <StyledSubmissionProgressContainer>
      <h5 style={{ margin: "unset" }}><strong>X</strong> out of <strong>Y</strong> submitted</h5>
      <ul>
        <li><strong>X</strong> = number of submissions that are in Submitted, Accepted, In Review, or Processing Status</li>
        <li><strong>Y</strong> = number of utilities that are assigned to the submission period</li>
      </ul>
    </StyledSubmissionProgressContainer>
  );
};

const UnknownServiceLinesHover: React.FC<{}> = () => {
  return (
    <p style={{ all: "unset", color: LIGHT_GRAY }}>
      Percentage of service lines with a material classification of unknown
    </p>
  );
};

const DashboardContent: React.FC<{
  selectedSubmissionPeriod: SubmissionPeriod;
  submissionPeriodsData: PaginatedResponse<SubmissionPeriod>;
}> = ({ selectedSubmissionPeriod, submissionPeriodsData }) => {
  const [selectedMaterialType, setSelectedMaterialType] =
    useState<MaterialType>(MaterialType.UNKNOWN);

  const [isLoading, setIsLoading] = useState(true);
  const { setTooltipContent, setTooltipPosition } = useTooltip();

  const { data: primacyAgencyData, isLoading: isPrimacyAgencyLoading } =
    useGetCurrentPrimacyAgencyQuery(undefined);

  const { data: materialBreakdownData, isLoading: isMaterialBreakdownLoading } =
    useGetMaterialTypeBreakdownMetricQuery({
      submissionPeriodId: selectedSubmissionPeriod.id,
    });

  const {
    data: submittedSubmissionsData,
    isLoading: isSubmittedSubmissionsLoading,
  } = useGetSubmittedSubmissionsMetricQuery({
    submissionPeriodId: selectedSubmissionPeriod.id,
  });

  const {
    data: utilityMaterialMakeupData,
    isLoading: isUtilityMaterialMakeupLoading,
  } = useGetUtilityMaterialMakeupMetricQuery(
    {
      submissionPeriodId: selectedSubmissionPeriod.id,
    },
    {
      selectFromResult: ({ data, isLoading }) => ({
        data: Object.entries(data ?? {}).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value.map((obj, i) => ({
              ...obj,
              color: COLOR_MAP.get(key as MaterialType),
              tooltip: (
                <span key={`${obj.bin}-${i}`} style={{ color: LIGHT_GRAY }}>
                  <strong>{obj.value}</strong> utilities with{" "}
                  {LABEL_MAP.get(key as MaterialType)?.toLowerCase()} values in
                  this range
                </span>
              ),
            })),
          }),
          {
            [MaterialType.UNKNOWN]: [],
            [MaterialType.LEAD]: [],
            [MaterialType.GRR]: [],
            [MaterialType.NONLEAD]: [],
          }
        ),
        isLoading,
      }),
    }
  );

  const { data: countyMakeupData, isLoading: isCountyMakeupLoading } =
    useGetCountySummariesQuery(null);

  const activeSubmissionPeriods = useMemo(() => {
    return (
      submissionPeriodsData?.data?.filter(
        (submissionPeriod) => submissionPeriod.active
      ) ?? []
    );
  }, [submissionPeriodsData?.data]);

  const inactiveSubmissionPeriods = useMemo(() => {
    const spd =
      submissionPeriodsData?.data?.filter(
        (submissionPeriod) => !submissionPeriod.active
      ) ?? [];
    spd.sort(
      (a: SubmissionPeriod, b: SubmissionPeriod) =>
        Date.parse(b.startDate) - Date.parse(a.modifiedOn)
    );
    return spd;
  }, [submissionPeriodsData?.data]);

  const totalServiceLines = useMemo(() => {
    if (!materialBreakdownData) {
      return 0;
    }

    return Object.values(materialBreakdownData).reduce(
      (total, breakdown) => total + breakdown,
      0
    );
  }, [materialBreakdownData]);

  const displayedMaterialBreakdownSubmissionPeriods = useMemo(() => {
    const periods = activeSubmissionPeriods;
    if (
      !selectedSubmissionPeriod ||
      periods
        .map((submissionPeriod) => submissionPeriod.id)
        .includes(selectedSubmissionPeriod.id)
    ) {
      return periods;
    }
    return [selectedSubmissionPeriod, ...periods];
  }, [selectedSubmissionPeriod, activeSubmissionPeriods]);

  useEffect(() => {
    setIsLoading(
      isPrimacyAgencyLoading ||
        isMaterialBreakdownLoading ||
        isSubmittedSubmissionsLoading ||
        isUtilityMaterialMakeupLoading ||
        isCountyMakeupLoading
    );
  }, [
    isPrimacyAgencyLoading,
    isMaterialBreakdownLoading,
    isSubmittedSubmissionsLoading,
    isUtilityMaterialMakeupLoading,
    isCountyMakeupLoading,
  ]);

  if (isLoading) {
    <LoadingIndicatorPage />;
  }

  return (
    <>
      <SubmissionStatsGrid>
        <div
          onMouseEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
            const rect = e.currentTarget.getBoundingClientRect();
            setTooltipPosition(
              rect.x + rect.width / 1.5,
              rect.y + rect.height - 20
            );
            setTooltipContent(<SubmissionProgressHover />);
          }}
          onMouseLeave={() => setTooltipContent(null)}
        >
          <SubmissionPeriodMetricCard
            progress={
              ((submittedSubmissionsData?.submitted ?? 0) /
                (submittedSubmissionsData?.total ?? 1)) *
              100
            }
            radius={20}
            barWidth={8}
            title={
              <a
                href="/submissions"
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "inherit",
                }}
              >
                Submission Progress <Icon name="chevron right" size="small" />
              </a>
            }
            style={{ gridArea: "sub-card-1" }}
          >
            <p style={{ all: "unset" }}>
              <strong>{submittedSubmissionsData?.submitted ?? 0}</strong> out of{" "}
              {submittedSubmissionsData?.total ?? 1} submitted
            </p>
          </SubmissionPeriodMetricCard>
        </div>
        <div
          onMouseEnter={(e) => {
            const rect = e.currentTarget.getBoundingClientRect();
            setTooltipPosition(
              rect.x + rect.width / 1.5,
              rect.y + rect.height - 20
            );
            setTooltipContent(<UnknownServiceLinesHover />);
          }}
          onMouseLeave={() => setTooltipContent(null)}
        >
          <SubmissionPeriodMetricCard
            progress={
              ((materialBreakdownData?.unknown ?? 0) /
                (totalServiceLines || 1)) *
              100
            }
            radius={20}
            barWidth={8}
            title="Unknown Service Lines"
            style={{ gridArea: "sub-card-2" }}
          >
            <p style={{ all: "unset" }}>
              <strong>
                {formatPercent(
                  (materialBreakdownData?.unknown ?? 0) /
                    (totalServiceLines || 1)
                )}
              </strong>{" "}
              unknown lines
            </p>
          </SubmissionPeriodMetricCard>
        </div>
        <MaterialMakeupCard>
          <MaterialMakeupHeader>
            <h3
              style={{
                margin: 0,
              }}
            >
              Utility Material Makeup
            </h3>
            <Dropdown
              placeholder="Select a material"
              selection
              options={[
                { text: "Lead", value: MaterialType.LEAD },
                { text: "Galvanized", value: MaterialType.GRR },
                { text: "Non-Lead", value: MaterialType.NONLEAD },
                { text: "Unknown", value: MaterialType.UNKNOWN },
              ]}
              onChange={(_, { value }) =>
                setSelectedMaterialType(value as MaterialType)
              }
              defaultValue={MaterialType.UNKNOWN}
            />
          </MaterialMakeupHeader>
          <BarGraph
            yLabel="# of utilities"
            xLabel="% of material submitted"
            data={
              utilityMaterialMakeupData
                ? utilityMaterialMakeupData[selectedMaterialType]
                : []
            }
            style={{
              height: "100%",
              color: LIGHT_GRAY,
            }}
          />
        </MaterialMakeupCard>
        <ComplianceDeadlinesCard>
          <h3>Compliance Deadlines</h3>
          <Column style={{ gap: "7px", flexGrow: 0 }}>
            <h4>Active</h4>
            {activeSubmissionPeriods.length > 0 &&
              activeSubmissionPeriods.map((sp) => (
                <DeadlineCard key={`active-sp-${sp.id}`}>
                  <h5>{sp.name}</h5>
                  <span>
                    {formatShortDate(sp.startDate)} -{" "}
                    {formatShortDate(sp.dueDate)}
                  </span>
                </DeadlineCard>
              ))}
          </Column>
          <InactiveSubmissionPeriodsContainer>
            <h4>Inactive</h4>
            {inactiveSubmissionPeriods.length > 0
            ? (
              inactiveSubmissionPeriods.map((sp: SubmissionPeriod) => (
                <Card
                  key={`inactive-sp-${sp.id}`}
                  style={{
                    backgroundColor: GRAY,
                    color: WHITE,
                  }}
                >
                  <h5>{sp.name}</h5>
                  <span>
                    {formatShortDate(sp.startDate)} -{" "}
                    {formatShortDate(sp.dueDate)}
                  </span>
                </Card>
              ))
            )
            : (
              <p>There are no inactive periods within this date range</p>
            )}
          </InactiveSubmissionPeriodsContainer>
        </ComplianceDeadlinesCard>
      </SubmissionStatsGrid>

      <SubHeader>Material Breakdown</SubHeader>
      <Row>
        <Card
          style={{
            width: "100%",
          }}
        >
          <h3>Material Types</h3>
          <MaterialTypesContainer
            submissionPeriods={displayedMaterialBreakdownSubmissionPeriods}
          />
        </Card>
      </Row>
      <Row>
        {primacyAgencyData && (
          <MapSection
            state={primacyAgencyData?.primacyCode}
            countyMakeUpData={countyMakeupData}
          />
        )}
      </Row>
    </>
  );
};

export const Dashboard: React.FC = () => {
  const [selectedSubmissionPeriodId, setSelectedSubmissionPeriodId] = useState<
    number | null
  >(null);

  const [isLoading, setIsLoading] = useState(true);

  const {
    data: submissionPeriodsData,
    isLoading: isSubmissionPeriodsDataLoading,
  } = useGetAllSubmissionPeriodsQuery({
    filters: {
      sandbox: false,
    },
    page: 1,
    pageSize: 100,
    sort: {
      key: "startDate",
      direction: SortDirection.ASC,
    },
  });

  useEffect(() => {
    if (!submissionPeriodsData || selectedSubmissionPeriodId !== null) {
      return;
    }
    const selected = submissionPeriodsData.data
      .filter((submissionPeriod) => !submissionPeriod.sandbox)
      .find((submissionPeriod) => submissionPeriod.active);
    if (selected) {
      setSelectedSubmissionPeriodId(selected.id);
      return;
    }
    setSelectedSubmissionPeriodId(submissionPeriodsData.data[0]?.id ?? null);
  }, [submissionPeriodsData]);

  const selectedSubmissionPeriod = useMemo(() => {
    if (!selectedSubmissionPeriodId || !submissionPeriodsData) {
      return null;
    }
    return (
      submissionPeriodsData.data.find(
        (submissionPeriod) => submissionPeriod.id === selectedSubmissionPeriodId
      ) ?? null
    );
  }, [selectedSubmissionPeriodId, submissionPeriodsData]);

  useEffect(() => {
    setIsLoading(isSubmissionPeriodsDataLoading);
  }, [isSubmissionPeriodsDataLoading]);

  if (
    isLoading ||
    !selectedSubmissionPeriod ||
    !selectedSubmissionPeriodId ||
    !submissionPeriodsData
  ) {
    return <LoadingIndicatorPage />;
  }

  return (
    <Page>
      <PageHeader>Insights</PageHeader>
      <PageContent>
        <div
          style={{
            display: "flex",
            gap: "7px",
            alignItems: "end",
          }}
        >
          <SubHeader>Submission Period Statistics</SubHeader>
          <div
            style={{
              flexGrow: 1,
            }}
          ></div>
          <Dropdown
            placeholder="Submission period"
            selection
            options={
              submissionPeriodsData?.data
                .filter((period) => !period.sandbox)
                .map((period) => ({
                  text: period.name,
                  value: period.id,
                })) ?? []
            }
            value={selectedSubmissionPeriodId}
            onChange={(_, { value }) =>
              setSelectedSubmissionPeriodId(value as number)
            }
            style={{
              marginBottom: 14,
            }}
          />
        </div>
        <DashboardContent
          selectedSubmissionPeriod={selectedSubmissionPeriod}
          submissionPeriodsData={submissionPeriodsData}
        />
      </PageContent>
    </Page>
  );
};

export default Dashboard;
