import React from "react";
import { Card } from "@120wateraudit/waterworks";
import styled from "styled-components";

import ServiceLine from "src/types/ServiceLine";
import FittingsAccordion from "./Sections/FittingsAccordion";
import MaterialAccordion from "./Sections/MaterialAccordion";
import ProfileAccordion from "./Sections/ProfileAccordion";
import TopInfo from "./Sections/BannerInfo";
import LocationDetails from "./Sections/LocationDetails";
import Row from "src/components/Row";
import { YesNoUnknown } from "src/types/enums/YesNoUnknown";
import Attachments from "./Sections/Attachments";
import CustomFieldsAccordion from "./Sections/CustomFieldsAccordion";

interface Props {
  serviceLine: ServiceLine;
}

export const ServiceLineDetail: React.FC<Props> = ({ serviceLine }) => {
  return (
    <StyledRow>
      <div>
        <LocationDetails serviceLine={serviceLine} />
        <Attachments
          assetId={serviceLine.assetId}
          submissionId={serviceLine.submissionId}
        />
      </div>
      <Card style={{ flex: "auto" }}>
        <TopInfo serviceLine={serviceLine} />
        <ProfileAccordion serviceLine={serviceLine} />
        <MaterialAccordion
          classificationBasis={serviceLine.publicClassificationBasis}
          depth={serviceLine.publicLineDepth}
          diameter={serviceLine.publicLineDiameter}
          diameterOverTwoInches={serviceLine.publicDiameterOverTwoInches}
          installDate={serviceLine.publicInstallationDate}
          material={serviceLine.publicMaterial}
          leadSolderLeadLined={
            serviceLine.publicLeadSolder || YesNoUnknown.Unknown
          }
          notes={serviceLine.publicNotes}
          removalDate={serviceLine.publicRemovalDate}
          verificationDate={serviceLine.publicVerifiedDate}
          verificationMethod={serviceLine.publicVerificationMethod}
          verifiedBy={serviceLine.publicVerifiedBy}
          previouslyLead={
            serviceLine.publicPreviouslyLead || YesNoUnknown.Unknown
          }
          classificationBasisNotes={serviceLine.publicClassificationBasisNotes}
          installedAfterLeadBan={serviceLine.publicInstalledAfterLeadBan}
          materialOtherNotes={serviceLine.publicMaterialOther}
          verified={serviceLine.publicVerified}
          verificationOtherNotes={serviceLine.publicVerificationMethodOther}
          type="public"
        />
        <MaterialAccordion
          classificationBasis={serviceLine.privateClassificationBasis}
          depth={serviceLine.privateLineDepth}
          diameter={serviceLine.privateLineDiameter}
          diameterOverTwoInches={serviceLine.privateDiameterOverTwoInches}
          installDate={serviceLine.privateInstallationDate}
          material={serviceLine.privateMaterial}
          leadSolderLeadLined={
            serviceLine.privateLeadSolder || YesNoUnknown.Unknown
          }
          notes={serviceLine.privateNotes}
          removalDate={serviceLine.privateRemovalDate}
          verificationDate={serviceLine.privateVerifiedDate}
          verificationMethod={serviceLine.privateVerificationMethod}
          verifiedBy={serviceLine.privateVerifiedBy}
          classificationBasisNotes={serviceLine.privateClassificationBasisNotes}
          installedAfterLeadBan={serviceLine.privateInstalledAfterLeadBan}
          materialOtherNotes={serviceLine.privateMaterialOther}
          verificationOtherNotes={serviceLine.privateVerificationMethodOther}
          verified={serviceLine.privateVerified}
          type="private"
        />
        <FittingsAccordion serviceLine={serviceLine} />
        {
          serviceLine.customFields?.length > 0 && <CustomFieldsAccordion customFields={serviceLine.customFields}/>
        }
      </Card>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  gap: 10px;
  align-items: flex-start;
  flex-wrap: no-wrap;
  justify-content: center;
  @media (max-width: 1160px) {
    flex-wrap: wrap;
    > :first-child {
         width: 100%
    }
  }
`;

export default ServiceLineDetail;
