import { Button, Card } from "@120wateraudit/waterworks";
import React, { Fragment, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { LoadingIndicatorPage } from "src/components/LoadingIndicatorPage";
import MaterialsCard from "src/components/MaterialsCard";
import NotFoundCard from "src/components/NotFoundCard";
import Page from "src/components/Page";
import Row from "src/components/Row";
import { Media } from "src/modules/App/Media";
import BasicContactCard from "src/modules/Contacts/Card";
import { ModalContext } from "src/modules/Modal";
import LatestSubmissionCard from "src/modules/Submissions/Card";
import { SubmissionTable } from "src/modules/Systems/SubmissionTable";
import {
  useGetAllSystemContactsQuery,
  useGetAllTerritoriesQuery,
  useGetSingleSystemQuery,
} from "src/services";
import { useGetAssetStatsQuery } from "src/services/pws-api";
import { formatOneLineAddress } from "src/utils/format";
import SystemDetailsCard from "src/modules/Systems/SystemDetailsCard";
import PageHeader from "src/components/PageHeader";
import { isWrite } from "src/modules/User";
import { TerritoryChangeDropdown } from "src/modules/Systems/TerritoryChangeDropdown";

const UtilityDetailsPage: React.FC = () => {
  const { id: systemId } = useParams<{ id: string }>();
  const { openModal } = useContext(ModalContext);
  const openAddContactDrawer = (): void => {
    openModal("addContact", { systemId }, "drawer");
  };

  const {
    data: systemDetailResponse,
    isError,
    isFetching,
    refetch: refetchSystemDetails,
  } = useGetSingleSystemQuery(systemId ?? "");
  const { data: systemContacts } = useGetAllSystemContactsQuery(systemId ?? "");
  const { data } = useGetAssetStatsQuery(
    systemDetailResponse?.data.accountId ?? 0,
    { skip: systemDetailResponse?.data.accountId === undefined }
  );
  const {
    galvanizedAssets = 0,
    leadAssets = 0,
    nonLeadAssets = 0,
    unknownAssets = 0,
    totalAssets = 0,
  } = data ?? {};

  const { data: territories = [] } = useGetAllTerritoriesQuery({});

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  if (!systemDetailResponse?.data || isError) {
    // TODO add back button
    return (
      <Page>
        <NotFoundCard>
          <p>
            We could not find the Utility you were looking for. It may have been
            moved or marked as inactive.
          </p>
          <p>
            We recommend returning to{" "}
            <Link to="/utilities">
              the list of Utilities for your account and searching for it by
              name.
            </Link>
          </p>
        </NotFoundCard>
      </Page>
    );
  }

  const {
    data: { name, territory, ...address },
  } = systemDetailResponse;
  const { data: contacts = [] } = systemContacts ?? {};

  return (
    <Page
      actions={
        isWrite()
        ? (
          <Button onClick={openAddContactDrawer} variant="primary">
            Create Contact
          </Button>
        )
        : (
          <Fragment />
        )
      }
      hasBackButton
      title={
        <div style={{ display: "flex" }}>
          <div>
            <PageHeader>{name ?? "--"}</PageHeader>
            <h2>{formatOneLineAddress(address)}</h2>
          </div>
          {systemId && (
            <TerritoryChangeDropdown
              territories={territories}
              currentTerritory={territory}
              systemId={systemId}
              refetchSystemDetails={refetchSystemDetails}
            />
          )}
        </div>
      }
    >
      <Media greaterThanOrEqual="md" style={{ display: "flex" }}>
        <Header>
          <SystemDetailsCard system={systemDetailResponse.data} />
          <MaterialsCard
            title={"Current in PWS Portal"}
            style={{ flex: 2, height: "100%" }}
            totalLines={totalAssets}
            galvanized={galvanizedAssets}
            lead={leadAssets}
            nonLead={nonLeadAssets}
            unknown={unknownAssets}
          />
        </Header>
      </Media>
      <Content>
        {contacts.length > 0 && <ContactCard contacts={contacts} />}
        <div
          style={{
            flex: 3,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <LatestSubmissionCard systemId={systemId} />
          <Card>
            <SubmissionTable
              systemId={systemId ?? ""}
              isOnUtilityDetailsPage={true}
            />
          </Card>
        </div>
      </Content>
    </Page>
  );
};

const Content = styled(Row)`
  gap: 1rem;
  align-items: start;
  flex-wrap: wrap-reverse;
`;

const ContactCard = styled(BasicContactCard)`
  flex: 1.2;
  width: 30%;

  &&& {
    .carousel.carousel-slider {
      overflow: visible;
    }
  }
`;

const Header = styled(Row)`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  flex: 3;
`;

export default UtilityDetailsPage;
