import * as React from "react";
import styled from "styled-components";
import { Card } from "@120wateraudit/waterworks";

interface Props {
  descriptionSection?: React.ReactNode;
  icon: JSX.Element;
  style?: any;
  title: string;
}

export const EmptyStateCard = ({
  descriptionSection = null,
  icon,
  style = {},
  title,
}: Props): JSX.Element => (
  <Card style={style}>
    <CenteringContainer>
      <div>
        <CenteringContainer>{icon}</CenteringContainer>
        <h5
          style={{
            textAlign: "center",
          }}
        >
          {title}
        </h5>
        {descriptionSection}
      </div>
      <br />
    </CenteringContainer>
  </Card>
);

const CenteringContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default EmptyStateCard;
