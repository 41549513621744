import React from "react";
import { Card } from "@120wateraudit/waterworks";
import styled from "styled-components";

import ServiceLine from "src/types/ServiceLine";
import StyledDetail from "src/components/StyledDetail";

interface Props {
  serviceLine: ServiceLine;
}

export const LocationDetails: React.FC<Props> = ({ serviceLine }) => {
  return (
    <LocationCard>
      <div style={{ flex: 1 }}>
        <h5>Location Details</h5>
        <Detail label="Location Name">
          {serviceLine.locationName || "--"}
        </Detail>
        <Detail label="Asset ID">{serviceLine.assetId || "--"}</Detail>
        <Detail label="External Location ID">
          {serviceLine.externalLocationId || "--"}
        </Detail>
        <Detail label="Address" style={{ wordBreak: "break-all" }}>
          {serviceLine.addressLine1}
          {serviceLine.addressLine2 ? ` ${serviceLine.addressLine2}` : ""},{" "}
          {serviceLine.city}, {serviceLine.state} {serviceLine.zip}
        </Detail>
        <Detail label="Is Disadvantaged Neighborhood">
          {serviceLine.disadvantagedNeighborhood?.toString() ?? "--"}
        </Detail>
        <Detail label="Sensitive Population">
          {serviceLine.sensitivePopulation || "--"}
        </Detail>
        <Detail label="Notes">{serviceLine.locationNotes || "--"}</Detail>
      </div>
    </LocationCard>
  );
};

export default LocationDetails;

const LocationCard = styled(Card)`
  display: flex;
  align-items: start;
  flex: 0.5;
`;

const Detail = styled(StyledDetail)`
  @media (max-width: 1160px) {
    display: flex;
    label {
      flex: none;
      margin-right: 5px;
    }
  }

  @media (max-width: 500px) {
    display: block;
  }
`;
