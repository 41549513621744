import { Button } from "@120wateraudit/waterworks";
import dayjs from "dayjs";
import debounce from "lodash/debounce";
import React, { useCallback, useState } from "react";
import { useField } from "react-final-form";
import {
  Message,
  Search as SUIRSearch,
  SearchResultProps,
} from "semantic-ui-react";
import styled from "styled-components";

import { useGetAllSubmissionPeriodsQuery } from "src/services";
import System from "src/types/System";
import { formatDate } from "src/utils/format";

interface Props {
  systemsToAssign: System[];
}

export const ExistingSubmissionPeriod: React.FC<Props> = ({
  systemsToAssign,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearch = debounce((_, data) => setSearchTerm(data.value), 300);
  const { data: queryData, isFetching } = useGetAllSubmissionPeriodsQuery({
    filters: { active: true, locked: false },
    page: 1,
    pageSize: 7,
    searchTerm,
  });
  const submissionPeriods = queryData?.data ?? [];
  const { input } = useField("submissionPeriod");

  return (
    <div>
      <Search
        fluid
        loading={isFetching}
        onResultSelect={(_, data) =>
          input.onChange({
            ...data.result,
            systems: [
              ...data.result.systems,
              ...systemsToAssign.map((s) => ({ id: s.id })),
            ],
          })
        }
        onSearchChange={debouncedSearch}
        resultRenderer={SubmissionPeriodInfo}
        results={submissionPeriods}
      />
      {input.value && (
        <SelectedResult
          dueDate={input.value.dueDate}
          name={input.value.name}
          startDate={input.value.startDate}
        />
      )}
      <p style={{ margin: "12px" }}>or</p>
      <CreateNewSubmissionPeriodButton systemsToAssign={systemsToAssign} />
    </div>
  );
};

const Search = styled(SUIRSearch)`
  & .input {
    width: 80%;
    margin: auto;
  }
` as unknown as typeof SUIRSearch;

const SubmissionPeriodInfoContainer = styled.div`
  & > p {
    margin-top: 0 !important;
    margin-bottom: 0.2em !important;
  }
`;

const SubmissionPeriodInfo = ({
  dueDate,
  name,
  startDate,
  style,
}: SearchResultProps): JSX.Element => (
  <SubmissionPeriodInfoContainer style={style}>
    <p>
      <strong>Name:</strong> {name}
    </p>
    <p>
      <strong>Due Date:</strong> {formatDate(dueDate)}
    </p>
    <p>
      <strong>Start Date:</strong> {formatDate(startDate)}
    </p>
  </SubmissionPeriodInfoContainer>
);

const SelectedResult: React.FC<{
  dueDate?: string;
  name: string;
  startDate?: string;
}> = (props) => {
  return (
    <Message>
      <Message.Header>Selected Submission Period</Message.Header>
      <SubmissionPeriodInfo
        style={{ marginTop: "8px" }}
        title={props.name}
        {...props}
      />
    </Message>
  );
};

const CreateNewSubmissionPeriodButton: React.FC<Props> = ({
  systemsToAssign,
}) => {
  const {
    input: { onChange },
  } = useField("isNew");
  const submissionPeriodField = useField("submissionPeriod");
  const onClick = useCallback(() => {
    const dueDate = dayjs().add(4, "month");
    const newSubmissionPeriod = {
      dueDate: dueDate.format("YYYY-MM-DD"),
      name: dueDate.format("[Q]Q - YYYY"),
      period: "quarterly",
      startDate: dayjs().format("YYYY-MM-DD"),
      systems: [...systemsToAssign.map((s) => ({ id: s.id }))],
    };
    submissionPeriodField.input.onChange(newSubmissionPeriod);
    onChange(true);
  }, [onChange, submissionPeriodField.input.onChange]);

  return (
    <Button onClick={onClick} variant="secondary">
      + Create Submission Period
    </Button>
  );
};
