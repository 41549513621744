import { Card, Detail } from "@120wateraudit/waterworks";
import React from "react";
import styled from "styled-components";

import Submission from "src/types/Submission";
import { useGetTotalSubmissionsCountBySubmissionPeriodPwsAndValidStatusQuery } from "src/services";
import { Link } from "react-router-dom";

interface Props {
  submission: Submission;
}

export const SubmissionDetailsCard: React.FC<Props> = ({ submission }) => {
  const { system } = submission;

  const { populationServed, pwsId, pwsOwner, pwsType, serviceLineConnections, counties, territory } =
    system;
  const { data: totalSubmissionsCountPerSubmissionPeriod } =
    useGetTotalSubmissionsCountBySubmissionPeriodPwsAndValidStatusQuery({
      submissionPeriodId: submission.submissionPeriod.id,
      pwsId: submission.pwsId,
    });

  return (
    <Content>
      <Card>
        <StyledDetail label="PWS ID:">{pwsId ?? "--"}</StyledDetail>
        <StyledDetail label="Counties Served:">{counties?.length > 0 ? counties.map((c) => c.shortName).sort().join(', ') : "--"}</StyledDetail>
        <StyledDetail label="Territory:">{territory ? territory.name : "--"}</StyledDetail>
        <Link to={`/utilities/${submission.pwsId}`}>
          <StyledDetail label="Submission Attempts:">
            {totalSubmissionsCountPerSubmissionPeriod?.data}
          </StyledDetail>
        </Link>
      </Card>
      <Card style={{ height: "100%" }}>
        <div style={{ marginBottom: "0.5rem" }}>
          <h5>SDWIS Information</h5>
        </div>
        <StyledDetail label="PWS Type:">{pwsType ?? "--"}</StyledDetail>
        <StyledDetail label="Owner:">{pwsOwner ?? "--"}</StyledDetail>
        <StyledDetail label="Population:">
          {populationServed ?? "--"}
        </StyledDetail>
        <StyledDetail label="Service Line Connections:">
          {serviceLineConnections ?? "--"}
        </StyledDetail>
      </Card>
    </Content>
  );
};

const StyledDetail = styled(Detail)`
  display: flex;
  label {
    flex: 1.5;
  }
  span {
    flex: 1;
  }
  text-align: left;
  margin-bottom: 0.5rem;
`;

const Content = styled.div`
  display: flex;
  flex: 0.5;
  gap: 0.5rem;
  flex-direction: column;
  @media (max-width: 380px) {
    flex: 1;
  }
`;

export default SubmissionDetailsCard;
