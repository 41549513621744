import { useMemo } from "react";

export interface Values extends Record<string, unknown> {
  status:
    | "submitted"
    | "inReview"
    | "rejected"
    | "approved"
    | "overdue"
    | "processingSubmission"
    | "voided";
}

export const useInitialValues = (): Values => {
  return useMemo(() => {
    const values: Values = {
      status: "submitted",
    };

    return values;
  }, []);
};

export interface ApiError {
  data: {
    error: string;
  };
}
