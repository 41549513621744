import React, { useCallback, useMemo } from "react";
import { invert } from "lodash";
import { useNavigate } from "react-router-dom";
import { Accordion } from "semantic-ui-react";
import styled from "styled-components";
import { Flags, useConfig } from "src/hooks/useConfig";

interface Props {
  menuItems: string[];
  routeParam?: string;
}

const adminChoices: { [key: string]: string } = {
  manageTerritories: "Manage Territories",
  statePtdDisplay: "State PTD Display",
  statePtdPublishing: "State PTD Publishing",
  exportLog: "Export Log",
};

const getParamChoice = (routeParam?: string): string => {
  if (routeParam) {
    return adminChoices[routeParam];
  } else {
    return "Manage Territories";
  }
};

const AdminMenu: React.FC<Props> = ({ menuItems, routeParam }: Props) => {
  const [statePtdEnabled, loaded] = useConfig(Flags.StatePtdSettings);
  const tabGroups = useMemo(() => {
    const acctMgmtChoices = [
      adminChoices.manageTerritories,
      adminChoices.exportLog,
    ];
    if (!statePtdEnabled || !loaded) {
      return [
        {
          choices: acctMgmtChoices,
          name: "Account Management",
        },
      ];
    }
    return [
      {
        choices: [
          ...acctMgmtChoices,
          adminChoices.statePtdDisplay,
          adminChoices.statePtdPublishing,
        ],
        name: "Account Management",
      },
    ];
  }, [statePtdEnabled, loaded]);

  const navigate = useNavigate();

  const routePropChoice = useMemo(
    () => getParamChoice(routeParam),
    [routeParam]
  );

  const chooseRoute = useCallback(
    (routeString: string): string => {
      return invert(adminChoices)[routeString];
    },
    [adminChoices]
  );
  const setActiveItem = useCallback(
    (itemName: string): void => {
      const routeText = chooseRoute(itemName);
      navigate(`/accountSettings/${routeText}`);
    },
    [chooseRoute, navigate]
  );

  const config = useMemo(
    () =>
      loaded
        ? tabGroups.map((tabGroup, index) => {
            const filteredChoices = tabGroup.choices.filter((item) =>
              menuItems.includes(item)
            );

            return {
              content: {
                content: filteredChoices.map((item, idx) => {
                  return (
                    <MenuItemWrapper key={`${index}-${idx}`}>
                      <MenuItem
                        isActive={routePropChoice === item}
                        key={`${index}-${idx}`}
                        setActiveItem={setActiveItem}
                        title={item}
                      />
                    </MenuItemWrapper>
                  );
                }),
              },
              id: `group-${index}`,
              title: tabGroup.name,
            };
          })
        : [],
    [menuItems, routePropChoice, tabGroups, setActiveItem, loaded]
  );

  const index = useMemo(
    () =>
      tabGroups.findIndex(
        (tg) => tg.choices.findIndex((c) => c === routePropChoice) >= 0
      ),
    [routePropChoice, tabGroups]
  );

  if (!loaded) {
    return null;
  }
  return <Accordion defaultActiveIndex={index} panels={config} styled />;
};

interface MenuItemProps {
  isActive: boolean;
  title: string;
  setActiveItem: (itemName: string) => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
  isActive,
  setActiveItem,
  title,
}): JSX.Element => {
  return (
    <Item isActive={isActive} onClick={() => setActiveItem(title)}>
      {title}
    </Item>
  );
};

interface ItemProps {
  isActive: boolean;
  onClick: () => void;
}

const Item = styled.div<ItemProps>`
  color: ${(props) => (props.isActive ? "#00b5e1" : "black")};
  padding: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  margin-left: ${(props) => props.isActive && ".5rem"};
  background-color: white;
  cursor: pointer;
`;

const MenuItemWrapper = styled.div`
  background-color: #00b5e1;
`;

export default AdminMenu;
